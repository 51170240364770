import React from 'react';
import Typography, { TypographyTypes } from './new_designs/Typography';
import FlkHeader from './FlkHeader';

import cx from 'classnames';
import styles from './RotateScreen.module.scss';

type RotateScreenProps = {
    isDesktop?: boolean;
};

const RotateScreen = ({ isDesktop }: RotateScreenProps) => {
    return (
        <div className={cx(styles.rotateScreen, { [styles.mobile]: !isDesktop })}>
            <FlkHeader />
            <div className={styles.container}>
                <div>
                    <Typography type={TypographyTypes.H1} text="Please rotate your device" />
                    <Typography text="For the most optimal viewing experience" />
                </div>
                <img className={styles.image} src="/images/rotate_device.svg" alt="" />
            </div>
        </div>
    );
};

export default RotateScreen;
