import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { getLease, getAgencyDetails } from 'selectors/lease';
import { getConfirmModalCustomStyle } from 'utils';
import { getUser } from 'selectors/main';

const ConfirmIdentitySaLongTermRenewal = ({ hideModal, confirmButtonHandler, isOpen }) => {
    const agency = useSelector(getAgencyDetails);
    const user = useSelector(getUser);
    const lease = useSelector(getLease);

    const [confirmed, setConfirmed] = useState(false);
    const [pdfSignature, setPdfSignature] = useState(false);

    const agree = () => {
        if (confirmed) {
            confirmButtonHandler({ confirmedIdentity: true });
        }
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={getConfirmModalCustomStyle()}
                className="confirm"
                contentLabel="agent-modal"
                onRequestClose={hideModal}
                appElement={document.getElementById('root')}
            >
                <div className="confirm-container">
                    <div className="confirm-title">Confirmation</div>
                    <div className="confirm-details">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="confirmation"
                                checked={confirmed}
                                onChange={() => setConfirmed(!confirmed)}
                            />
                            <label htmlFor="confirmation">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">
                                    I confirm I am {user.fullName} as identified by documents provided to{' '}
                                    {agency.agencyName}. This signature is my own, and I also confirm I agree to sign my
                                    Notice to Tenant of Lease Extension in this electronic format.
                                </span>
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="pdfSignature"
                                checked={pdfSignature}
                                onChange={() => setPdfSignature(!pdfSignature)}
                            />
                            <label htmlFor="pdfSignature">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">
                                    I confirm that my signature can be inserted onto the PDF
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="confirm-footer">
                        <button disabled={!(pdfSignature && confirmed)} onClick={agree}>
                            I Agree
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmIdentitySaLongTermRenewal;
