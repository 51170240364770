import React from 'react';
import { Provider, ErrorBoundary, useRollbarPerson } from '@rollbar/react';
import { useSelector } from 'react-redux';
import { getUser } from '../../selectors/main';

const rollbarConfig = {
    enabled: true,
    accessToken: ROLLBAR_TOKEN,
    environment: ENVIRONMENT,
    captureUncaught: true,
    captureUnhandledRejections: true,
};
const disabledRollbarConfig = {
    enabled: false,
};
function RollbarUserIdentifier() {
    const user = useSelector(getUser);
    useRollbarPerson({
        id: user?.id,
    });
    return null;
}
export function RollbarProvider({ children }) {
    const config = ROLLBAR_TOKEN ? rollbarConfig : disabledRollbarConfig;
    return (
        <Provider config={config}>
            <ErrorBoundary>
                <RollbarUserIdentifier />
                {children}
            </ErrorBoundary>
        </Provider>
    );
}
