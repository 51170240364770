import React from 'react';
import cx from 'classnames';
import '../../sass/tag.scss';

const TagType = {
    SENDER: 0,
    CLIENT1: 1,
    CLIENT2: 2,
    CLIENT3: 3,
    CLIENT4: 4,
    CLIENT5: 5,
    CLIENT6: 6,
    CLIENT7: 7,
    WITNESS: 10,
    ITEM: 'item',
};

const Tag = ({ className, text, tagType, solidBorder = false }) => {
    if (solidBorder) {
        return (
            <div
                className={cx('tag', className, {
                    'tag--green-solid': tagType === TagType.SENDER,
                    'tag--orange-solid': tagType === TagType.CLIENT1,
                    'tag--yellow-solid': tagType === TagType.CLIENT2,
                    'tag--blue-solid': tagType === TagType.CLIENT3,
                    'tag--purple-solid': tagType === TagType.CLIENT4,
                    'tag--pink-solid': tagType === TagType.CLIENT5,
                    'tag--teal-solid': tagType === TagType.CLIENT6,
                    'tag--aquamarine-solid': tagType === TagType.CLIENT7,
                    'tag--midGray-solid': tagType === TagType.WITNESS
                })}
            >
                {text}
            </div>
        );
    }

    return (
        <div
            className={cx('tag', className, {
                'tag--green': tagType === TagType.SENDER,
                'tag--orange': tagType === TagType.CLIENT1,
                'tag--yellow': tagType === TagType.CLIENT2,
                'tag--blue': tagType === TagType.CLIENT3,
                'tag--purple': tagType === TagType.CLIENT4,
                'tag--pink': tagType === TagType.CLIENT5,
                'tag--teal': tagType === TagType.CLIENT6,
                'tag--aquamarine': tagType === TagType.CLIENT7,
                'tag--midGray': tagType === TagType.WITNESS,
                'tag--lightestBlue': tagType === TagType.ITEM,
            })}
        >
            {text}
        </div>
    );
};

export { Tag, TagType };
