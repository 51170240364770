import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { confirmStepPromise } from '../../actions/main';

import Button from '../../common/components/Button';
import { WITNESS_SIGNING_STEP } from '../../common/constants';
import Sign from '../../components/modal/Sign';
import Preloader from '../../components/Preloader';
import { getStepViaName } from '../../utils';

const WitnessSigningFooter = ({ step, steps, confirmButtonHandler }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.main.user);
    const tenantSignature = useSelector((state) => state.steps.tenantSignature);
    const firstName = useSelector((state) => state.witness.firstName);
    const lastName = useSelector((state) => state.witness.lastName);
    const confirmation = useSelector((state) => state.witness.confirmation);
    const sameAsUser = useSelector((state) => state.witness.sameAsUser);
    const isConfirmingStep = useSelector((state) => state.steps.isConfirmingStep);

    const [isOpenSignModal, setIsOpenSignModal] = useState(false);
    const [error, setError] = useState(false);

    const currentStep = getStepViaName(WITNESS_SIGNING_STEP, steps);

    const sendWitnessAndOpenSignModal = () => {
        const data = {
            step: currentStep.step,
            stepName: WITNESS_SIGNING_STEP,
            payload: {
                subStepField: 'witnessName',
                subStepNo: currentStep.step + 0.1,
                witnessFirstName: firstName,
                witnessLastName: lastName,
                witnessConfirmation: confirmation,
            },
        };

        setError(false);

        dispatch(confirmStepPromise(data))
            .then(() => {
                setIsOpenSignModal(true);
            })
            .catch(() => {
                setError('Something went wrong, please try again. If this keeps happening, please contact your agent');
            });
    };

    const buttonHandler = (payload) => {
        const data = {
            subStepField: 'witnessSignature',
            subStepNo: currentStep.step + 0.2,
            signature: payload.signature,
        };
        confirmButtonHandler(data);
    };

    const closeSignModal = () => {
        setIsOpenSignModal(false);
    };
    let preloader = '';
    if (isConfirmingStep) {
        preloader = <Preloader />;
    }

    if (!firstName || !lastName || !confirmation || sameAsUser) {
        return null;
    }

    return (
        <div className="footer-buttons">
            {error && <p>{error}</p>}
            <Button
                className="button-witness-signature red"
                onClick={sendWitnessAndOpenSignModal}
                disabled={isConfirmingStep}
            >
                I’m ready to sign {preloader}
            </Button>
            <Sign
                title="Witness signature"
                isOpen={isOpenSignModal}
                isPdf={false}
                tenantSignaturePreview={tenantSignature}
                tenantName={user.fullName}
                hideModal={closeSignModal}
                confirmButtonHandler={buttonHandler}
                isSignatureModal={true}
            />
        </div>
    );
};

export default WitnessSigningFooter;
