import * as Toast from '../reducers/toast';

export function addNewToast(toast: Toast.Toast) {
    return {
        type: Toast.ADD_NEW_TOAST,
        toast,
    };
}

export function removeToast(id: string) {
    return {
        type: Toast.REMOVE_TOAST,
        id,
    };
}
