import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import main from '../reducers/main';
import mapData from '../reducers/map';
import address from '../reducers/address';
import steps from '../reducers/steps';
import salesSteps from '../reducers/salesSteps';
import pmSteps from '../reducers/pmSteps';
import changeRequest from '../reducers/changeRequest';
import connections from '../reducers/connections';
import witness from '../reducers/witness';
import pmQuestions from '../reducers/pmQuestions';
import salesQuestions from '../reducers/salesQuestions';
import resTenQuestions from '../reducers/resTenQuestions';
import commercialLeaseQuestions from '../reducers/commercialLeaseQuestions';
import commonQuestions from '../reducers/commonQuestions';
import pmOwnerEnteredPaymentMethod from '../reducers/pmOwnerEnteredPaymentMethod';
import customDocumentReducer from '../reducers/customDocument';
import disclosure from '../reducers/disclosure';
import documents from '../reducers/documents';
import storeMaintenanceMessage from './maintenance';
import commonSteps from './commonSteps.ts';
import toast from './toast.ts';

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        main,
        mapData,
        address,
        steps,
        salesSteps,
        pmSteps,
        changeRequest,
        connections,
        witness,
        pmQuestions,
        salesQuestions,
        resTenQuestions,
        commercialLeaseQuestions,
        commonQuestions,
        pmOwnerEnteredPaymentMethod,
        customDocumentReducer,
        disclosure,
        documents,
        storeMaintenanceMessage,
        commonSteps,
        toast,
    });
