import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { QLD_STATE, VIC_STATE } from 'config';
import { getConfirmModalCustomStyle, isLeaseLocationVIC, isLeaseLocationQLD } from 'utils';
import { getLease } from 'selectors/lease';
import { getUser } from 'selectors/main';

export default function ConfirmIdentity({ isOpen, hideModal, confirmButtonHandler }) {
    const user = useSelector(getUser);
    const lease = useSelector(getLease);

    const [confirmed, setConfirmed] = useState(false);
    const [addSignatureToPdf, setAddSignatureToPdf] = useState(false);

    useEffect(
        () => {
            const { location } = lease;
            const hasPdfConfirmation = [QLD_STATE, VIC_STATE].includes(location);
            if (hasPdfConfirmation) {
                setConfirmed(v => v && addSignatureToPdf);
            }
        },
        [lease]
    );

    function toggleConfirmed() {
        setConfirmed(v => !v);
    }

    function toggleAddSignatureToPdf() {
        setAddSignatureToPdf(v => !v);
    }

    function onAgreeClicked() {
        if ([QLD_STATE, VIC_STATE].includes(lease.location)) {
            if (confirmed && addSignatureToPdf) {
                confirmButtonHandler({ confirmedIdentity: true });
            }
        } else {
            if (confirmed) {
                confirmButtonHandler({ confirmedIdentity: true });
            }
        }
    }

    let hasPdfConfirmation = [QLD_STATE, VIC_STATE].includes(lease.location);

    let message = `I confirm I am ${
        user.fullName
    } the intended recipient of this document. This signature is my own, and I also confirm I agree to sign my ${
        lease.leaseType
    } Agreement in this electronic format.`;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={getConfirmModalCustomStyle()}
                className="confirm"
                contentLabel="agent-modal"
                onRequestClose={hideModal}
                appElement={document.getElementById('root')}
            >
                <div className="confirm-container">
                    <div className="confirm-title">Confirmation</div>
                    <div className="confirm-details">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                value={message}
                                id="confirmation"
                                checked={confirmed}
                                onChange={toggleConfirmed}
                            />
                            <label htmlFor="confirmation">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">{message}</span>
                            </label>
                        </div>
                    </div>
                    {hasPdfConfirmation && (
                        <div className="confirm-details">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    value="PDF"
                                    id="pdf-confirmation"
                                    checked={addSignatureToPdf}
                                    onChange={toggleAddSignatureToPdf}
                                />
                                <label htmlFor="pdf-confirmation">
                                    <span className="checkmark" />
                                    {isLeaseLocationVIC(lease.location) ? (
                                        <span className="label-text-checkbox">
                                            I confirm that my signature can be inserted onto the PDF
                                        </span>
                                    ) : (
                                        <span className="label-text-checkbox">
                                            I confirm that my signature can be inserted onto the PDF in the below
                                            required areas:
                                        </span>
                                    )}
                                    {isLeaseLocationQLD(lease.location) && (
                                        <ul>
                                            <li>Form 6</li>
                                            <li>Item Schedule</li>
                                            <li>Pool Safety Form (if applicable)</li>
                                            <li>Outgoing Instructions Letter (if applicable)</li>
                                        </ul>
                                    )}
                                </label>
                            </div>
                        </div>
                    )}
                    <div className="confirm-footer">
                        <button disabled={!confirmed} onClick={onAgreeClicked}>
                            I Agree
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
