module.exports = () => {
    if (WALKME_URL) {
        let walkme = document.createElement('script');
        walkme.type = 'text/javascript';
        walkme.async = true;
        walkme.src = `${WALKME_URL}`;
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(walkme, s);
        window._walkmeConfig = { smartLoad: true };
    }
};
