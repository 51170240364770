import React from 'react';
import cx from 'classnames';

import styles from './DeviceHeightScrollContainer.module.scss';

type DeviceHeightScrollContainerProps = {
    children: React.ReactNode;
    className?: string;
};

const DeviceHeightScrollContainer: React.FC<DeviceHeightScrollContainerProps> = ({ children, className }) => {
    return <div className={cx(styles.container, className)}>{children}</div>;
};

export { DeviceHeightScrollContainer };
