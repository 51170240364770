import { cloneDeep, find } from 'lodash';
import * as Main from 'reducers/main';
import { isLeaseLocationQLD, validateQuestionComplete, findQuestion } from 'utils';

export const PM_DISCLOSURE_QUESTIONS_REQUEST = 'PM_DISCLOSURE_QUESTIONS_REQUEST';
export const PM_DISCLOSURE_QUESTIONS_SUCCESS = 'PM_DISCLOSURE_QUESTIONS_SUCCESS';
export const PM_DISCLOSURE_QUESTIONS_FAILURE = 'PM_DISCLOSURE_QUESTIONS_FAILURE';

export const PM_MATERIAL_FACT_QUESTIONS_REQUEST = 'PM_MATERIAL_FACT_QUESTIONS_REQUEST';
export const PM_MATERIAL_FACT_QUESTIONS_SUCCESS = 'PM_MATERIAL_FACT_QUESTIONS_SUCCESS';
export const PM_MATERIAL_FACT_QUESTIONS_FAILURE = 'PM_MATERIAL_FACT_QUESTIONS_FAILURE';

export const PM_DISCLOSURE_ANSWER_QUESTION = 'PM_DISCLOSURE_ANSWER_QUESTION';

export const PM_MATERIAL_FACT_ANSWER_QUESTION = 'PM_MATERIAL_FACT_ANSWER_QUESTION';
export const PM_MATERIAL_FACT_SET_DETAILS = 'PM_MATERIAL_FACT_SET_DETAILS';
export const PM_MATERIAL_FACT_UPDATE_DETAILS = 'PM_MATERIAL_FACT_UPDATE_DETAILS';

export const PM_INSURANCE_SET_DETAILS = 'PM_INSURANCE_SET_DETAILS';
export const PM_INSURANCE_UPDATE_DETAILS = 'PM_INSURANCE_UPDATE_DETAILS';

export const PM_DYNAMIC_STANDARD_TERMS_ANSWER_QUESTION = 'PM_DYNAMIC_STANDARD_TERMS_ANSWER_QUESTION';

export const PM_OUTGOING_ANSWER_QUESTION = 'PM_OUTGOING_ANSWER_QUESTION';
export const PM_OUTGOING_SET_DETAILS = 'PM_OUTGOING_SET_DETAILS';
export const PM_OUTGOING_UPDATE_DETAILS = 'PM_OUTGOING_UPDATE_DETAILS';

export const PM_OUTGOING_SET_IMAGE = 'PM_OUTGOING_SET_IMAGE';
export const PM_OUTGOING_REMOVE_IMAGE = 'PM_OUTGOING_REMOVE_IMAGE';
export const PM_OUTGOING_SET_SEND_LETTER = 'PM_OUTGOING_SET_SEND_LETTER';

export const PM_WATER_CONSUMPTION_ANSWER_QUESTION = 'PM_WATER_CONSUMPTION_ANSWER_QUESTION';
export const PM_WATER_CONSUMPTION_SET_DETAILS = 'PM_WATER_CONSUMPTION_SET_DETAILS';
export const PM_WATER_CONSUMPTION_CONFIRM_DETAILS = 'PM_WATER_CONSUMPTION_CONFIRM_DETAILS';

export const PM_POOL_SAFETY_ANSWER_QUESTION = 'PM_POOL_SAFETY_ANSWER_QUESTION';
export const PM_POOL_SAFETY_UPDATE_DETAILS = 'PM_POOL_SAFETY_UPDATE_DETAILS';
export const PM_POOL_SAFETY_SET_DETAILS = 'PM_POOL_SAFETY_SET_DETAILS';

export const PM_PRIOR_APPOINTMENT_ANSWER_QUESTION = 'PM_PRIOR_APPOINTMENT_ANSWER_QUESTION';

export const PM_INSURANCE_ANSWER_QUESTION = 'PM_INSURANCE_ANSWER_QUESTION';

export const SET_PM_OWNER_ID_PREVIEW = 'SET_PM_OWNER_ID_PREVIEW';

const initialState = {
    pmDisclosureAnswers: { changed: false },
    pmDisclosureAnswered: false,
    pmMaterialFactAnswers: {},
    pmMaterialFactAnswered: false,
    pmDynamicStandardTermsAnswers: {
        questions: [
            {
                questionId: '21.1',
                questionText:
                    'Does the Property contain the water efficiency measures prescribed by the Residential Tenancies Act (NSW) 2010'
            },
            {
                questionId: '22.3',
                questionText: 'Pursuant to clause 14.2, the smoke alarms will be maintained:'
            },
            {
                questionId: '23.2',
                questionText:
                    'If the Landlord has not arranged landlord insurance, the Landlord authorises the Agent to arrange landlord insurance in respect of the Property from its preferred insurer',
                detailsRequiredOnNo: true
            },
            {
                questionId: '24.2',
                questionText:
                    'In circumstances where there is no valid compliance certificate, the Landlord authorises the agent to obtain one:'
            }
        ]
    },
    pmDynamicStandardTermsAnswered: false,
    pmDisclosureSubAnswer: false,
    pmOutgoingsAnswers: { questions: [], sendLetter: true },
    pmOutgoingsAnswered: false,
    poolSafetyAndSmokeAlarmsAnswers: { questions: [] },
    poolSafetyAndSmokeAlarmsAnswered: false,
    waterConsumptionAndTenantPayments: {
        answer: '',
        waterCost: '',
        confirmed: false
    },
    priorAppointmentAnswer: '',
    pmInsuranceAnswer: {},
    ownerImagePreview: ''
};

function createOutgoingsQuestions(newState, action) {
    const outGoings = action.result.data.lease.pmOutgoings;
    newState.pmOutgoingsAnswers = { questions: [], sendLetter: true };
    if (!action.result.data.user.pmOutGoingsAnswers) {
        for (let key in outGoings) {
            let outGoingObj = outGoings[key];
            if (outGoingObj.hasOwnProperty('enabled')) {
                if (outGoingObj.enabled) {
                    newState.pmOutgoingsAnswers.questions.push({
                        questionText: outGoingObj.outGoingDescription,
                        questionId: outGoingObj.outGoingId,
                        detailsRequired: true,
                        canUploadImage: true,
                        noConfirmationRequired: true
                    });
                }
            }

            if (key === 'otherOutgoings') {
                if (outGoingObj.length > 0) {
                    outGoingObj.forEach(otherOutgoing => {
                        newState.pmOutgoingsAnswers.questions.push({
                            questionText: otherOutgoing.outGoingDescription,
                            questionId: otherOutgoing.outGoingId,
                            detailsRequired: true,
                            canUploadImage: true,
                            noConfirmationRequired: true
                        });
                    });
                }
            }
        }
    } else {
        action.result.data.user.pmOutGoingsAnswers.questions.forEach(outgoing => {
            newState.pmOutgoingsAnswers.questions.push({
                ...outgoing,
                preview: outgoing.image,
                detailsRequired: true,
                canUploadImage: true,
                noConfirmationRequired: true
            });
            newState.pmOutgoingsAnswers.sendLetter = action.result.data.lease.sendLetterAuthorisingHandlingOfBills;
        });
    }

    if (newState.pmOutgoingsAnswers.questions.length === 0) {
        newState.pmOutgoingsAnswered = true;
    } else {
        newState.pmOutgoingsAnswered = false;
    }
}

function createPoolSafetyAndSmokeAlarmsQuestions(newState, action) {
    const poolSafetyAndSmokeAlarms = action.result.data.lease.poolSafetyAndSmokeAlarms;
    newState.poolSafetyAndSmokeAlarmsAnswers = { questions: [] };
    if (
        !action.result.data.user.poolSafetyAndSmokeAlarmsAnswers &&
        poolSafetyAndSmokeAlarms &&
        isLeaseLocationQLD(action.result.data.lease.location)
    ) {
        if (poolSafetyAndSmokeAlarms.hasPool) {
            if (poolSafetyAndSmokeAlarms.poolType === 'Regulated Pool (shared)') {
                newState.poolSafetyAndSmokeAlarmsAnswers.questions.push({
                    questionText:
                        '<strong>The Property has a shared pool:</strong><br />If there is a shared pool at the premises, the Client must obtain a valid pool safety certificate for the pool or provide a notice in the approved form that the a valid pool safety certificate will not be provided prior to the commencement of the lease.',
                    questionId: 'I0',
                    detailsRequired: true,
                    noConfirmationRequired: true
                });
            } else if (poolSafetyAndSmokeAlarms.poolType === 'Regulated Pool (not shared)') {
                newState.poolSafetyAndSmokeAlarmsAnswers.questions.push({
                    questionText:
                        '<strong>The Property has a pool that is not shared with any other dwelling:</strong><br />Prior to the commencement of any proposed tenancy, the Client must provide the Agent with a valid pool safety certificate or authorise the Agent to obtain one on their behalf.',
                    questionId: 'I0',
                    detailsRequired: true,
                    noConfirmationRequired: true
                });
            } else if (
                poolSafetyAndSmokeAlarms.poolType ===
                'No Pool but plan to erect a portable pool with depth of 300mm or greater'
            ) {
                newState.poolSafetyAndSmokeAlarmsAnswers.questions.push({
                    questionText:
                        '<strong>The Property does not have a pool but the Client authorises any proposed tenant to erect a portable pool with a depth of 300mm or greater:</strong><br />The Client permits the tenant to erect a portable pool with a depth of 300mm or greater provided that the tenant complies with all relevant legislation and indemnifies the Agent and the Client in this respect. The Agent must include a clause to this effect in the lease agreement.',
                    questionId: 'I0',
                    detailsRequired: true,
                    noConfirmationRequired: true
                });
            }
        }
    } else if (action.result.data.user.poolSafetyAndSmokeAlarmsAnswers) {
        action.result.data.user.poolSafetyAndSmokeAlarmsAnswers.questions.forEach(poolSafetyAnswer => {
            newState.poolSafetyAndSmokeAlarmsAnswers.questions.push({
                ...poolSafetyAnswer,
                noConfirmationRequired: true
            });
        });
    }

    if (!action.result.data.user.isPrimaryLandlord || newState.poolSafetyAndSmokeAlarmsAnswers.questions.length === 0) {
        newState.poolSafetyAndSmokeAlarmsAnswered = true;
    } else {
        newState.poolSafetyAndSmokeAlarmsAnswered = false;
    }
}

export default function pmQuestions(state = initialState, action) {
    let newState = cloneDeep(state);

    switch (action.type) {
        case PM_DISCLOSURE_QUESTIONS_SUCCESS: {
            newState.pmDisclosureAnswers = action.result.data.questions;
            // Filter out 19.2.c.i this will be displayed based on answer from 19.2.c
            const questionC = find(newState.pmDisclosureAnswers.questions, function(question) {
                return question.questionId === '19.2.c.i';
            });
            questionC.visible = false;
            questionC.answer = '';
            // Filter out 19.2.d.i this will be displayed based on answer from 19.2.d
            const questionD = find(newState.pmDisclosureAnswers.questions, function(question) {
                return question.questionId === '19.2.d.i';
            });
            questionD.visible = false;
            questionD.answer = '';
            return newState;
        }

        case PM_MATERIAL_FACT_QUESTIONS_SUCCESS: {
            newState.pmMaterialFactAnswers = action.result.data.questions;
            return newState;
        }

        case PM_DISCLOSURE_ANSWER_QUESTION: {
            const { answer, questionId } = action;
            const questionToAnswer = find(newState.pmDisclosureAnswers.questions, function(question) {
                return question.questionId === questionId;
            });
            questionToAnswer.answer = answer;

            if (questionToAnswer.questionId === '19.2.c') {
                const questionToMakeVisible = find(newState.pmDisclosureAnswers.questions, function(hiddenQuestion) {
                    return hiddenQuestion.questionId === '19.2.c.i';
                });
                if (answer === true) {
                    questionToMakeVisible.visible = true;
                    newState.pmDisclosureSubAnswer = true;
                } else {
                    questionToMakeVisible.visible = false;
                    questionToMakeVisible.answer = '';
                    newState.pmDisclosureSubAnswer = false;
                }
            }
            if (questionToAnswer.questionId === '19.2.d') {
                const questionToMakeVisible = find(newState.pmDisclosureAnswers.questions, function(hiddenQuestion) {
                    return hiddenQuestion.questionId === '19.2.d.i';
                });
                if (answer === true) {
                    questionToMakeVisible.visible = true;
                    newState.pmDisclosureSubAnswer = true;
                } else {
                    questionToMakeVisible.visible = false;
                    questionToMakeVisible.answer = '';
                    newState.pmDisclosureSubAnswer = false;
                }
            }
            newState.pmDisclosureAnswered = newState.pmDisclosureAnswers.questions.every(function hasBeenAnswered(
                currentValue
            ) {
                return validateQuestionComplete(currentValue);
            });

            newState.pmDisclosureAnswers.changed = true;

            return newState;
        }

        case PM_MATERIAL_FACT_ANSWER_QUESTION: {
            const { answer, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmMaterialFactAnswers);
            questionToAnswer.answer = answer;
            questionToAnswer.detailsConfirmed = false;
            questionToAnswer.details = '';
            newState.pmMaterialFactAnswered = newState.pmMaterialFactAnswers.questions.every(function hasBeenAnswered(
                currentValue
            ) {
                return validateQuestionComplete(currentValue);
            });

            return newState;
        }

        case PM_MATERIAL_FACT_SET_DETAILS: {
            const { details, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmMaterialFactAnswers);
            questionToAnswer.details = details;
            questionToAnswer.detailsConfirmed = true;
            newState.pmMaterialFactAnswered = newState.pmMaterialFactAnswers.questions.every(function hasBeenAnswered(
                currentValue
            ) {
                return validateQuestionComplete(currentValue);
            });

            return newState;
        }

        case PM_MATERIAL_FACT_UPDATE_DETAILS: {
            const { details, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmMaterialFactAnswers);
            questionToAnswer.details = details;
            questionToAnswer.detailsConfirmed = false;
            newState.pmMaterialFactAnswered = newState.pmMaterialFactAnswers.questions.every(function hasBeenAnswered(
                currentValue
            ) {
                return validateQuestionComplete(currentValue);
            });

            return newState;
        }

        case PM_INSURANCE_SET_DETAILS: {
            const { details, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmDynamicStandardTermsAnswers);
            questionToAnswer.details = details;
            newState.insuranceDetailsConfirmed = true;
            newState.pmDynamicStandardTermsAnswered = newState.pmDynamicStandardTermsAnswers.questions.every(
                function hasBeenAnswered(currentValue) {
                    return validateQuestionComplete(currentValue);
                }
            );

            return newState;
        }

        case PM_INSURANCE_UPDATE_DETAILS: {
            const { details, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmDynamicStandardTermsAnswers);
            questionToAnswer.details = details;
            newState.insuranceDetailsConfirmed = false;
            newState.pmDynamicStandardTermsAnswered = newState.pmDynamicStandardTermsAnswers.questions.every(
                function hasBeenAnswered(currentValue) {
                    return validateQuestionComplete(currentValue);
                }
            );

            return newState;
        }
        case PM_POOL_SAFETY_ANSWER_QUESTION: {
            const { answer } = action;
            newState.poolSafetyAndSmokeAlarmsAnswers.questions[0].answer = answer;
            newState.poolSafetyAndSmokeAlarmsAnswers.questions[0].detailsConfirmed = true;
            newState.poolSafetyAndSmokeAlarmsAnswers.questions[0].details = '';
            newState.poolSafetyAndSmokeAlarmsAnswered = true;

            return newState;
        }
        case PM_POOL_SAFETY_UPDATE_DETAILS: {
            const { details } = action;
            newState.poolSafetyAndSmokeAlarmsAnswers.questions[0].details = details;
            newState.poolSafetyAndSmokeAlarmsAnswers.questions[0].detailsConfirmed = false;
            newState.poolSafetyAndSmokeAlarmsAnswered = true;

            return newState;
        }
        case PM_POOL_SAFETY_SET_DETAILS: {
            const { details } = action;
            newState.poolSafetyAndSmokeAlarmsAnswers.questions[0].detailsConfirmed = true;
            newState.poolSafetyAndSmokeAlarmsAnswers.questions[0].details = details;
            newState.poolSafetyAndSmokeAlarmsAnswered = true;

            return newState;
        }
        case PM_OUTGOING_ANSWER_QUESTION: {
            const { answer, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmOutgoingsAnswers);
            questionToAnswer.answer = answer;
            questionToAnswer.detailsConfirmed = false;
            questionToAnswer.details = '';
            newState.pmOutgoingsAnswered = newState.pmOutgoingsAnswers.questions.every(function hasBeenAnswered(
                currentValue
            ) {
                return validateQuestionComplete(currentValue, false);
            });

            return newState;
        }

        case PM_OUTGOING_SET_SEND_LETTER: {
            newState.pmOutgoingsAnswers.sendLetter = action.payload;
            return newState;
        }

        case PM_OUTGOING_SET_DETAILS: {
            const { details, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmOutgoingsAnswers);
            questionToAnswer.details = details;
            questionToAnswer.detailsConfirmed = true;
            newState.pmOutgoingsAnswered = newState.pmOutgoingsAnswers.questions.every(function hasBeenAnswered(
                currentValue
            ) {
                return validateQuestionComplete(currentValue);
            });

            return newState;
        }
        case PM_OUTGOING_SET_IMAGE: {
            const { image, preview, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmOutgoingsAnswers);
            questionToAnswer.image = image;
            questionToAnswer.preview = preview;
            questionToAnswer.imageConfirmed = true;

            return newState;
        }
        case PM_OUTGOING_REMOVE_IMAGE: {
            const { questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmOutgoingsAnswers);
            questionToAnswer.image = '';
            questionToAnswer.imageConfirmed = false;

            return newState;
        }

        case PM_OUTGOING_UPDATE_DETAILS: {
            const { details, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.pmOutgoingsAnswers);
            questionToAnswer.details = details;
            questionToAnswer.detailsConfirmed = false;
            newState.pmOutgoingsAnswered = newState.pmOutgoingsAnswers.questions.every(function hasBeenAnswered(
                currentValue
            ) {
                return validateQuestionComplete(currentValue, false);
            });

            return newState;
        }

        case PM_DYNAMIC_STANDARD_TERMS_ANSWER_QUESTION: {
            const { answer, questionId } = action;

            const questionToAnswer = find(newState.pmDynamicStandardTermsAnswers.questions, function(question) {
                return question.questionId === questionId;
            });
            questionToAnswer.answer = answer;
            newState.pmDynamicStandardTermsAnswered = newState.pmDynamicStandardTermsAnswers.questions.every(
                function hasBeenAnswered(currentValue) {
                    return validateQuestionComplete(currentValue);
                }
            );
            return newState;
        }
        case PM_WATER_CONSUMPTION_ANSWER_QUESTION: {
            newState.waterConsumptionAndTenantPayments.answer = action.payload.answer;
            newState.waterConsumptionAndTenantPayments.confirmed = action.payload.answer !== 'does not comply';
            return newState;
        }
        case PM_PRIOR_APPOINTMENT_ANSWER_QUESTION: {
            newState.priorAppointmentAnswer = action.payload.answer;
            return newState;
        }
        case PM_INSURANCE_ANSWER_QUESTION: {
            newState.pmInsuranceAnswer = action.payload;
            return newState;
        }
        case PM_WATER_CONSUMPTION_SET_DETAILS: {
            newState.waterConsumptionAndTenantPayments.waterCost = action.payload.waterCost;
            newState.waterConsumptionAndTenantPayments.confirmed = false;
            return newState;
        }
        case PM_WATER_CONSUMPTION_CONFIRM_DETAILS: {
            newState.waterConsumptionAndTenantPayments.confirmed = true;
            return newState;
        }
        case SET_PM_OWNER_ID_PREVIEW: {
            newState.ownerImagePreview = action.preview;
            return newState;
        }

        case Main.TENANT_LOGIN_SUCCESS:
        case Main.TENANT_REFRESH_SUCCESS: {
            const questionToAnswer = find(newState.pmDynamicStandardTermsAnswers.questions, function(question) {
                return question.questionId === '22.3';
            });
            if (action.result.data.lease.pmDetailsRepairs) {
                questionToAnswer.answer = action.result.data.lease.pmDetailsRepairs.smokeAlarmsMaintainedBy;
            }
            // Create questions for outgoings
            createOutgoingsQuestions(newState, action);
            createPoolSafetyAndSmokeAlarmsQuestions(newState, action);
            newState.pmMaterialFactAnswered = false;
            newState.pmDisclosureAnswered = false;
            newState.pmDynamicStandardTermsAnswered = false;
            newState.waterConsumptionConfirmed = false;
            if (action.result.data.user.waterConsumptionAndTenantPayments) {
                newState.waterConsumptionAndTenantPayments.answer =
                    action.result.data.user.waterConsumptionAndTenantPayments.answer;
                newState.waterConsumptionAndTenantPayments.waterCost =
                    action.result.data.user.waterConsumptionAndTenantPayments.waterCost;
                newState.waterConsumptionAndTenantPayments.confirmed = true;
            }
            if (action.result.data.user.insuranceAnswers) {
                newState.pmInsuranceAnswer = action.result.data.user.insuranceAnswers;
            }
            if (action.result.data.user.priorAppointmentAnswer) {
                newState.priorAppointmentAnswer = action.result.data.user.priorAppointmentAnswer;
            }
            if (action.result.data.user.ownerImage) {
                newState.ownerImagePreview = action.result.data.user.ownerImage.base64Image;
            }
            return newState;
        }
    }
    return newState;
}
