import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './../reducers';
import promiseMiddleware from './../middleware/promiseMiddleware';
import createLogger from 'redux-logger';
// logger
const logger = createLogger({ duration: true });
import ReduxThunk from 'redux-thunk';

import hashHistory from './../history';
import { routerMiddleware } from 'connected-react-router';

/**
 * Create and configure redux store instance. Adding middleware
 * @returns {Store<S>}
 */
export default function configureStore(preloadedState) {
    const composeEnhancers = composeWithDevTools({});
    const store = createStore(
        reducers(hashHistory), // root reducer with router state
        preloadedState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(hashHistory), // for dispatching history actions
                promiseMiddleware,
                ReduxThunk
            )
        )
    );
    return store;
}
