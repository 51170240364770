import { SET_CURRENT_STEP, UPDATE_STEP_ERROR } from '../reducers/commonSteps';
import { StepType, DEFINE_ME } from '../types';

export function setCurrentStep(step: StepType, steps: StepType[], changeRequestList: DEFINE_ME[], user: DEFINE_ME) {
    return {
        type: SET_CURRENT_STEP,
        step,
        steps,
        changeRequestList,
        user,
    };
}

export function setStepError(error: DEFINE_ME) {
    return {
        type: UPDATE_STEP_ERROR,
        error,
    };
}
