import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import Sign from 'components/modal/Sign';
import Preloader from 'components/Preloader';
import ConfirmIdentity from 'components/modal/commercialLease/ConfirmIdentity';

import { checkIfChangeRequestsAreResolved, getStepViaName } from 'utils';
import { TENANT_SIGNATURE_STEP, TENANT_CONFIRMATION_STEP } from 'common/constants';
import { getShowSuccessAlert, getUser } from '../../selectors/main';
import { confirmStep, sendPDFCopy, closeSuccessAlert } from '../../actions/main';
import { getChangeRequestList } from '../../selectors/steps';

function CommercialLeaseSigningFooter({ confirmButtonHandler, steps, isConfirmingStep }) {
    const dispatch = useDispatch();

    const user = useSelector(getUser);
    const changeRequestList = useSelector(getChangeRequestList);
    const showSuccessAlert = useSelector(getShowSuccessAlert);

    const [isOpenSignModal, setIsOpenSignModal] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    function confirmSignatureHandler(payload) {
        const currentStep = getStepViaName(TENANT_SIGNATURE_STEP, steps);
        const childStep = getStepViaName(TENANT_SIGNATURE_STEP, currentStep.childSteps);
        const data = {
            subStepNo: childStep.step,
            subStepField: TENANT_SIGNATURE_STEP,
            signature: payload.signature,
        };
        confirmButtonHandler(data);
        setIsOpenConfirmModal(true);
    }

    function confirmIdentityHandler(payload) {
        const currentStep = getStepViaName(TENANT_SIGNATURE_STEP, steps);
        const childStep = getStepViaName(TENANT_CONFIRMATION_STEP, currentStep.childSteps);
        const data = {
            step: currentStep.step,
            stepName: TENANT_SIGNATURE_STEP,
            payload: {
                subStepField: TENANT_CONFIRMATION_STEP,
                subStepNo: childStep.step,
                ...payload,
            },
        };
        dispatch(confirmStep(data));
        setIsOpenSignModal(true);
    }
    let preloader = '';
    if (isConfirmingStep) {
        preloader = <Preloader />;
    }

    return (
        <footer className="column">
            {(!changeRequestList.length || checkIfChangeRequestsAreResolved(changeRequestList)) && (
                <div>
                    <Button onClick={() => setIsOpenConfirmModal(true)} disabled={isConfirmingStep}>
                        Sign this {preloader}
                    </Button>
                </div>
            )}
            <Sign
                isPdf
                title={`${user.fullName}'s signature`}
                isOpen={isOpenSignModal}
                hideModal={() => setIsOpenSignModal(false)}
                confirmButtonHandler={(payload) => confirmSignatureHandler(payload)}
                pdfCallback={(token) => dispatch(sendPDFCopy(token))}
                showSuccessAlert={showSuccessAlert}
                closeSuccessAlert={() => dispatch(closeSuccessAlert())}
                isSignatureModal={true}
            />
            <ConfirmIdentity
                isOpen={isOpenConfirmModal}
                hideModal={() => setIsOpenConfirmModal(false)}
                confirmButtonHandler={(payload) => confirmIdentityHandler(payload)}
            />
        </footer>
    );
}

export default CommercialLeaseSigningFooter;
