import * as React from 'react';
import { connect } from 'react-redux';
import Button from 'common/components/Button';
import Sign from 'components/modal/Sign';
import Preloader from 'components/Preloader';

class DefencePolicyFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            showModalSign: false
        };
    }

    hideModal() {
        this.setState({
            ...this.state,
            showModalSign: false
        });
    }

    showModal() {
        this.setState({
            ...this.state,
            showModalSign: true
        });
    }

    render() {
        const { isConfirmingStep } = this.props;
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        return (
            <div className="footer-buttons">
                <Button onClick={() => this.showModal()} signIcon={true} className="red" disabled={isConfirmingStep}>
                    <span>Sign this {preloader}</span>
                </Button>
                <Sign
                    isOpen={this.state.showModalSign}
                    confirmButtonHandler={this.props.confirmButtonHandler}
                    isPdf={false}
                    hideModal={() => this.hideModal()}
                    title="Sign clause"
                    subtitle="Defence Policy"
                    isSignatureModal={true}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        isConfirmingStep: state.steps.isConfirmingStep
    }),
    {}
)(DefencePolicyFooter);
