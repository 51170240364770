import * as React from 'react';
import { connect } from 'react-redux';
import { confirmStep } from 'actions/main';

import * as actions from 'actions/main';
import Button from 'common/components/Button';
import Sign from 'components/modal/Sign';
import { NSW_STATE, QLD_STATE, VIC_STATE } from 'config';
import Preloader from 'components/Preloader';
import ConfirmIdentityRta from 'components/modal/residentialTenancy/ConfirmIdentity';
import ConfirmIdentitySales from 'components/modal/sales/ConfirmIdentity';
import ConfirmIdentityVic from 'components/modal/residentialTenancy/vic/ConfirmIdentityVic';
import ConfirmIdentityAct from 'components/modal/residentialTenancy/act/ConfirmIdentityAct';
import ConfirmIdentitySa from 'components/modal/residentialTenancy/sa/ConfirmIdentitySa';

import {
    checkIfChangeRequestsAreResolved,
    getStepViaName,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeSales,
    isLeaseTypeCommercialLease,
} from 'utils';
import { TENANT_SIGNATURE_STEP, TENANT_CONFIRMATION_STEP } from 'common/constants';
import { ACT_STATE, SA_STATE, SUB_LEASE_TYPE_LONG_TERM_RENEWAL } from 'config';
import ConfirmIdentitySaLongTermRenewal from 'components/modal/residentialTenancy/sa/ConfirmIdentitySaLongTermRenewal';

class SigningFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false,
            isOpenConfirmModalVic: false,
            isOpenConfirmModalAct: false,
            isOpenConfirmModalSa: false,
            isOpenConfirmModalSaLongTermRenewal: false,
        };
    }

    confirmSignatureHandler(payload) {
        const currentStep = getStepViaName(TENANT_SIGNATURE_STEP, this.props.steps);
        const childStep = getStepViaName(TENANT_SIGNATURE_STEP, currentStep.childSteps);
        const data = {
            subStepNo: childStep.step,
            subStepField: TENANT_SIGNATURE_STEP,
            signature: payload.signature,
        };
        this.props.confirmButtonHandler(data);
        if (this.props.location === VIC_STATE) {
            this.setState({
                isOpenConfirmModalVic: true,
            });
        } else if (this.props.location === ACT_STATE) {
            this.setState({
                isOpenConfirmModalAct: true,
            });
        } else if (this.props.location === SA_STATE) {
            const isLongTermRenewal = this.props.subLeaseType === SUB_LEASE_TYPE_LONG_TERM_RENEWAL;
            this.setState({
                isOpenConfirmModalSa: !isLongTermRenewal,
                isOpenConfirmModalSaLongTermRenewal: isLongTermRenewal,
            });
        } else {
            this.setState({
                isOpenConfirmModal: true,
            });
        }
    }

    confirmIdentityHandler(payload) {
        const currentStep = getStepViaName(TENANT_SIGNATURE_STEP, this.props.steps);
        const childStep = getStepViaName(TENANT_CONFIRMATION_STEP, currentStep.childSteps);
        const data = {
            step: currentStep.step,
            stepName: TENANT_SIGNATURE_STEP,
            payload: {
                subStepField: TENANT_CONFIRMATION_STEP,
                subStepNo: childStep.step,
                ...payload,
            }
        };
        this.props.confirmStep(data);
        this.setState({
            isOpenSignModal: true,
        });
    }

    openConfirmModal = () => {
        if (this.props.location === VIC_STATE) {
            this.setState({
                isOpenConfirmModalVic: true,
            });
        } else if (this.props.location === ACT_STATE) {
            this.setState({
                isOpenConfirmModalAct: true,
            });
        } else if (this.props.location === SA_STATE) {
            const isLongTermRenewal = this.props.subLeaseType === SUB_LEASE_TYPE_LONG_TERM_RENEWAL;
            this.setState({
                isOpenConfirmModalSa: !isLongTermRenewal,
                isOpenConfirmModalSaLongTermRenewal: isLongTermRenewal,
            });
        } else {
            this.setState({
                isOpenConfirmModal: true,
            });
        }
    };

    closeConfirmModal = () => {
        if (this.props.location === VIC_STATE) {
            this.setState({
                isOpenConfirmModalVic: false,
            });
        } else if (this.props.location === ACT_STATE) {
            this.setState({
                isOpenConfirmModalAct: false,
            });
        } else if (this.props.location === SA_STATE) {
            const isLongTermRenewal = this.props.subLeaseType === SUB_LEASE_TYPE_LONG_TERM_RENEWAL;
            this.setState({
                isOpenConfirmModalSa: isLongTermRenewal,
                isOpenConfirmModalSaLongTermRenewal: !isLongTermRenewal,
            });
        } else {
            this.setState({
                isOpenConfirmModal: false,
            });
        }
    };

    closeSignModal = () => {
        this.setState({
            isOpenSignModal: false,
        });
    };

    render() {
        const { changeRequestList, isConfirmingStep, location, leaseType } = this.props;
        const {
            isOpenSignModal,
            isOpenConfirmModal,
            isOpenConfirmModalVic,
            isOpenConfirmModalAct,
            isOpenConfirmModalSa,
            isOpenConfirmModalSaLongTermRenewal,
        } = this.state;
        let title = '';
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        if (this.props.user.middleName) {
            title =
                this.props.user.firstName +
                ' ' +
                this.props.user.middleName +
                ' ' +
                this.props.user.secondName +
                "'s signature";
        } else {
            title = this.props.user.firstName + ' ' + this.props.user.secondName + "'s signature";
        }

        return (
            <footer className="column">
                {(!changeRequestList.length || checkIfChangeRequestsAreResolved(changeRequestList)) && (
                    <div>
                        <Button className="red" onClick={this.openConfirmModal} disabled={isConfirmingStep}>
                            Ready to go! {preloader}
                        </Button>
                        {location === NSW_STATE && (
                            <div className="agreement">
                                TENANT INFORMATION STATEMENT - By clicking "Ready to go" The tenant acknowledges that,
                                at or before the time of signing this residential tenancy agreement, the tenant was
                                given a copy of an{' '}
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.fairtrading.nsw.gov.au/__data/assets/pdf_file/0009/608382/Tenant-information-statement.pdf"
                                    target="_blank"
                                >
                                    information statement
                                </a>{' '}
                                published by NSW Fair Trading.
                            </div>
                        )}
                        {location === QLD_STATE && (
                            <div className="agreement">
                                By clicking “Ready to go” you acknowledge that, at or before the time of signing this
                                residential tenancy agreement, you were given a copy of the{' '}
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.rta.qld.gov.au/forms-resources/forms/forms-for-general-tenancies/pocket-guide-for-tenants-houses-and-units-form"
                                    target="_blank"
                                >
                                    Form 17a (PDF)
                                </a>{' '}
                                published by Residential Tenancies Authority - QLD.
                            </div>
                        )}
                        {location === VIC_STATE && (
                            <div className="agreement">
                                By clicking “Ready to go” you acknowledge that, at or before the time of signing this
                                residential rental agreement, you were given a copy of the{' '}
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.consumer.vic.gov.au/housing/renting/starting-and-changing-rental-agreements/resources-and-guides-for-renters/renters-guide"
                                    target="_blank"
                                >
                                    Renters guide
                                </a>{' '}
                                published by Consumer affairs Victoria.
                            </div>
                        )}
                        {location === ACT_STATE && (
                            <div className="agreement">
                                By clicking “Ready to go” you acknowledge that, at or before the time of signing this
                                residential tenancy agreement, you were given a copy of the{' '}
                                <a
                                    rel="noopener noreferrer"
                                    href="https://justice.act.gov.au/sites/default/files/2021-01/Renting%20Book%202021.pdf"
                                    target="_blank"
                                >
                                    The renting book
                                </a>{' '}
                                Published by the ACT Government Justice and Community Safety Directorate.
                            </div>
                        )}
                        {location === SA_STATE && this.props.subLeaseType !== SUB_LEASE_TYPE_LONG_TERM_RENEWAL && (
                            <div className="agreement">
                                By clicking "Ready to go" you acknowledge that, at or before the time of signing this
                                residential tenancy agreement, you were given a copy of The Residential Tenancies Act
                                1995 (SA) Information Brochure
                            </div>
                        )}
                    </div>
                )}
                <Sign
                    isPdf
                    title={title}
                    isOpen={isOpenSignModal}
                    hideModal={this.closeSignModal}
                    confirmButtonHandler={(payload) => this.confirmSignatureHandler(payload)}
                    pdfCallback={this.props.sendPDFCopy}
                    showSuccessAlert={this.props.showSuccessAlert}
                    closeSuccessAlert={this.props.closeSuccessAlert}
                    isSignatureModal={true}
                />
                {isLeaseTypeResidentialTenancy(leaseType) && (
                    <ConfirmIdentityRta
                        isOpen={isOpenConfirmModal}
                        hideModal={this.closeConfirmModal}
                        confirmButtonHandler={(payload) => this.confirmIdentityHandler(payload)}
                    />
                )}
                {isLeaseTypeSales(leaseType) && (
                    <ConfirmIdentitySales
                        isOpen={isOpenConfirmModal}
                        hideModal={this.closeConfirmModal}
                        confirmButtonHandler={(payload) => this.confirmIdentityHandler(payload)}
                    />
                )}
                <ConfirmIdentityVic
                    isOpen={isOpenConfirmModalVic}
                    hideModal={this.closeConfirmModal}
                    confirmButtonHandler={(payload) => this.confirmIdentityHandler(payload)}
                />
                <ConfirmIdentityAct
                    isOpen={isOpenConfirmModalAct}
                    hideModal={this.closeConfirmModal}
                    confirmButtonHandler={(payload) => this.confirmIdentityHandler(payload)}
                />
                <ConfirmIdentitySa
                    isOpen={isOpenConfirmModalSa}
                    hideModal={this.closeConfirmModal}
                    confirmButtonHandler={(payload) => this.confirmIdentityHandler(payload)}
                />
                <ConfirmIdentitySaLongTermRenewal
                    isOpen={isOpenConfirmModalSaLongTermRenewal}
                    hideModal={this.closeConfirmModal}
                    confirmButtonHandler={(payload) => this.confirmIdentityHandler(payload)}
                />
            </footer>
        );
    }
}

export default connect(
    (state) => ({
        user: state.main.user,
        agency: state.main.lease.agency.details,
        steps: state.steps.steps,
        changeRequestList: state.steps.changeRequestList,
        showSuccessAlert: state.main.showSuccessAlert,
        location: state.main.lease.location,
        leaseType: state.main.lease.leaseType,
        isConfirmingStep: state.steps.isConfirmingStep,
        subLeaseType: state.main.lease.subLeaseType,
    }),
    {
        sendPDFCopy: actions.sendPDFCopy,
        closeSuccessAlert: actions.closeSuccessAlert,
        confirmStep,
    }
)(SigningFooter);
