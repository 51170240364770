import React from 'react';

export const SET_REQUEST_MESSAGE = 'SET_REQUEST_MESSAGE';
export const OPEN_CHANGE_REQUEST_MODAL = 'OPEN_CHANGE_REQUEST_MODAL';
export const CLOSE_CHANGE_REQUEST_MODAL = 'CLOSE_CHANGE_REQUEST_MODAL';

const initState = {
    message: '',
    isOpenChangeRequestModal: false,
    step: null,
};

export default function changeRequest(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case SET_REQUEST_MESSAGE: {
            newState.message = action.requestMessage;
            break;
        }
        case OPEN_CHANGE_REQUEST_MODAL: {
            newState.isOpenChangeRequestModal = true;
            newState.step = action.step;
            break;
        }
        case CLOSE_CHANGE_REQUEST_MODAL: {
            newState.isOpenChangeRequestModal = false;
            newState.step = null;
            break;
        }
    }

    return newState;
}
