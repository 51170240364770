import React from 'react';
import Modal from 'react-modal';
import '../../sass/changeRequest.scss';
import ModalLocationContent from '../../containers/modals/ModalLocationContent';

export default function ChangeLocation({ modalIsOpen, step, hideModal }) {
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                style={customStyle}
                className="request-modal"
                contentLabel="Modal"
                onRequestClose={hideModal}
                appElement={document.getElementById('root')}
            >
                <ModalLocationContent step={step} close={hideModal} />
            </Modal>
        </div>
    );
}

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
};
