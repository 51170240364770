import React from 'react';

import { UPDATE_SERVICES, UPDATE_PRIVACY_POLICY } from 'actions/types';

const initState = {
    services: {},
    privacyPolicy: false
};

export default function connections(state = initState, action) {
    switch (action.type) {
        case UPDATE_SERVICES: {
            return {
                ...state,
                services: action.payload.services
            };
        }
        case UPDATE_PRIVACY_POLICY: {
            return {
                ...state,
                privacyPolicy: action.payload.privacyPolicy
            };
        }
    }

    return state;
}
