export const ELECTRONIC_SERVICE_ANSWER_QUESTION = 'ELECTRONIC_SERVICE_ANSWER_QUESTION';
export const ELECTRONIC_SERVICE_UPDATE_DETAILS = 'ELECTRONIC_SERVICE_UPDATE_DETAILS';
export const OWNER_CORPORATION_ANSWER_QUESTION = 'OWNER_CORPORATION_ANSWER_QUESTION';

const initialState = {
    electronicServiceAnswers: {
        givesPermissionForElectronicNotices: null,
        emailForElectronicNotices: ''
    },
    ownerCorporationAnswer: false
};

export default function resTenQuestions(state = initialState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case ELECTRONIC_SERVICE_ANSWER_QUESTION: {
            newState.electronicServiceAnswers.givesPermissionForElectronicNotices = action.answer;
            return newState;
        }
        case ELECTRONIC_SERVICE_UPDATE_DETAILS: {
            newState.electronicServiceAnswers.emailForElectronicNotices = action.email;
            return newState;
        }
        case OWNER_CORPORATION_ANSWER_QUESTION: {
            newState.ownerCorporationAnswer = action.answer;
            return newState;
        }
        default:
            return newState;
    }
}
