import { has } from 'lodash';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const UPDATE_STEP_ERROR = 'UPDATE_STEP_ERROR';

import * as Main from './main.js';
import { CLOSE_PAYLOAD_ALERT, HTTP_STATUS_UNPROCESSABLE_ENTITY } from './main.js';
import { ChangeRequestType, StepType, UserType, AcceptResponseType, ResponseErrorType } from '../types';

interface CommonStepsState {
    tenantSignature: string;
    changeRequestList: ChangeRequestType[];
    leaseStatus: string | null;
    leaseType: string | null;
    errorMessage: string;
    showErrorPage: boolean;
    showErrorModal: boolean;
    steps: StepType[];
}

interface Action {
    type: string;
    result?: AcceptResponseType;
    error?: ResponseErrorType;
    steps?: StepType[];
    step?: StepType;
    changeRequestList?: ChangeRequestType[];
    user?: UserType;
}

const initState: CommonStepsState = {
    tenantSignature: '',
    changeRequestList: [],
    leaseStatus: null,
    leaseType: null,
    errorMessage: '',
    showErrorPage: false,
    showErrorModal: false,
    steps: [],
};

export default function commonSteps(state: CommonStepsState = initState, action: Action) {
    switch (action.type) {
        case Main.TENANT_LOGIN_REQUEST:
            return {
                ...state,
                showErrorPage: false,
            };

        case Main.TENANT_LOGIN_SUCCESS:
        case Main.TENANT_REFRESH_SUCCESS: {
            let steps = [...state.steps];
            if (action.result && !action.result.data.isRedirect) {
                steps = action.result.data.steps;
            }
            return {
                ...state,
                steps: steps,
                changeRequestList: action.result ? action.result.data.changeRequestList : [],
                tenantSignature: action.result ? action.result.data.user.signature : null,
                showErrorPage: false,
            };
        }
        case UPDATE_STEP_ERROR:
        case Main.TENANT_LOGIN_FAILURE:
        case Main.TENANT_REFRESH_FAILURE: {
            let leaseStatus: string | null = null;
            let errorMessage = '';
            let leaseType: string | null = null;

            let showErrorPage = false;
            let showErrorModal = false;
            if (
                action.error &&
                action.error.response &&
                action.error.response.status === HTTP_STATUS_UNPROCESSABLE_ENTITY
            ) {
                showErrorModal = true;
            } else {
                showErrorPage = true;
            }
            if (action.error) {
                if (has(action, 'error.response.data.leaseStatus')) {
                    leaseStatus = action.error.response.data.leaseStatus;
                }
                if (has(action, 'error.response.data.error')) {
                    errorMessage = action.error.response.data.error;
                }
                if (has(action, 'error.response.data.leaseType')) {
                    leaseType = action.error.response.data.leaseType;
                }
            }

            return {
                ...state,
                leaseStatus,
                leaseType,
                errorMessage,
                showErrorPage,
                showErrorModal,
            };
        }

        case CLOSE_PAYLOAD_ALERT: {
            return {
                ...state,
                showErrorModal: false,
            };
        }

        case SET_CURRENT_STEP: {
            const steps = action.steps || [];
            steps?.map((step, index) => {
                steps[index].current = step.name === action?.step?.name;
                if (step.name === action?.step?.name) {
                    steps[index].visited = true;
                }
            });

            return {
                ...state,
                steps: steps,
                changeRequestList: action.changeRequestList,
                tenantSignature: action.user?.signature,
                showErrorPage: false,
                showErrorModal: false,
            };
        }

        default:
            return state;
    }
}
