export const LEASE_TYPE_RESIDENTIAL = 'Residential Tenancy';
export const LEASE_TYPE_PROPERTY_MANAGEMENT = 'Property Management';
export const LEASE_TYPE_SALES = 'Sales';
export const LEASE_TYPE_COMMERCIAL_LEASE = 'Commercial Lease';

export const PERIOD_WEEKLY = 'weekly';
export const PERIOD_FORTNIGHTLY = 'fortnightly';
export const PERIOD_MONTHLY = 'monthly';

export const LABEL_CALENDAR_MONTHLY = 'calendar monthly';

export const PROPERTY_TYPE_APARTMENT = 'apartment';

export const CREATE_A_FLK_DOC_TYPE = 'create_a_flk';

export const CLAUSE_NUMBER_RENT_INCREASE = 57;

export const services = [
    {
        title: 'Gas',
        icon: 'gas',
        inputName: 'gas'
    },
    {
        title: 'Electricity',
        icon: 'electricity',
        inputName: 'electricity'
    },
    {
        title: 'Internet',
        icon: 'internet',
        inputName: 'internet'
    },
    {
        title: 'Telephone',
        icon: 'telephone',
        inputName: 'telephone'
    },
    {
        title: 'Pay Tv',
        icon: 'pay-tv',
        inputName: 'payTV'
    },
    {
        title: 'Ute or Truck hire',
        icon: 'removalist',
        inputName: 'vehicleHire'
    },
    {
        title: 'Water',
        icon: 'water',
        inputName: 'water'
    }
];

export const leaseStatuses = {
    LEASE_STATUS_DRAFT: 'draft',
    LEASE_STATUS_SENT_SIGNING: 'sent_for_signing',
    LEASE_STATUS_AWAITING_COMPLETION: 'awaiting_completion',
    LEASE_STATUS_AWAITING_RENEWAL: 'awaiting_renewal',
    LEASE_STATUS_ARCHIVED: 'archived',
    LEASE_STATUS_COMPLETE: 'complete'
} as const;

export const QLD_STATE = 'QLD';
export const NSW_STATE = 'NSW';
export const VIC_STATE = 'VIC';
export const ACT_STATE = 'ACT';
export const NT_STATE = 'NT';
export const SA_STATE = 'SA';
export const TAS_STATE = 'TAS';
export const WA_STATE = 'WA';

export const TIMEZONE_QLD_STATE = 'Australia/Brisbane';
export const TIMEZONE_NSW_STATE = 'Australia/Sydney';
export const TIMEZONE_VIC_STATE = 'Australia/Melbourne';
export const TIMEZONE_ACT_STATE = 'Australia/Sydney';
export const TIMEZONE_NT_STATE = 'Australia/Darwin';
export const TIMEZONE_SA_STATE = 'Australia/Adelaide';
export const TIMEZONE_TAS_STATE = 'Australia/Hobart';
export const TIMEZONE_WA_STATE = 'Australia/Perth';

export const STANDARD_DATE_FORMAT = 'dd MMMM yyyy';
export const DAY_DATE_FORMAT = 'd MMMM yyyy';
export const DATE_FORMAT = 'dd/MM/yyyy';

export const availableStates = [QLD_STATE, NSW_STATE];
export const PERIOD_DAY = 'day';
export const PERIOD_WEEK = 'week';
export const PERIOD_FORTNIGHT = 'fortnight';
export const PERIOD_MONTH = 'month';
export const PERIOD_CALENDAR_MONTH = 'calendar month';
export const PERIOD_YEAR = 'year';

export const propertyManagementRentPeriods = [
    { value: PERIOD_WEEK, label: 'Week', numberOfWeeks: 1 },
    { value: PERIOD_FORTNIGHT, label: 'Fortnight', numberOfWeeks: 2 },
    { value: PERIOD_MONTH, label: 'Month', numberOfWeeks: 4 },
    { value: PERIOD_YEAR, label: 'Year', numberOfWeeks: 52 }
];
export const DEFAULT_NUMBER_OF_WEEKS_FOR_PROPERTY_MANAGEMENT_BOND = 4;

export const PeriodSingularArr = [PERIOD_WEEK, PERIOD_FORTNIGHT, PERIOD_MONTH] as const;
export type PeriodSingularType = typeof PeriodSingularArr[number];

export const PeriodArr = [PERIOD_WEEKLY, PERIOD_FORTNIGHTLY, PERIOD_MONTHLY];
export type PeriodType = typeof PeriodArr[number];

export const formatPeriodSingular = (period: PeriodSingularType) => {
    if (period === PERIOD_WEEK) {
        return PERIOD_WEEK;
    } else if (period === PERIOD_FORTNIGHT) {
        return PERIOD_FORTNIGHT;
    } else if (period === PERIOD_MONTH) {
        return PERIOD_MONTH;
    }
    return null;
};
export const formatPeriod = (period: PeriodType) => {
    if (period === PERIOD_WEEKLY) {
        return PERIOD_WEEK;
    } else if (period === PERIOD_FORTNIGHTLY) {
        return PERIOD_FORTNIGHT;
    } else if (period === PERIOD_MONTHLY) {
        return PERIOD_MONTH;
    }
    return null;
};

export const DOCUMENT_STATUS_DRAFT = 'draft';
export const DOCUMENT_STATUS_SENT_FOR_SIGNING = 'sent_for_signing';
export const DOCUMENT_STATUS_AWAITING_COMPLETION = 'awaiting_completion';
export const DOCUMENT_STATUS_COMPLETE = 'complete';
export const DOCUMENT_STATUS_CANCELLED = 'cancelled';

export const DETAILS_OPTION_OPTIONAL = 'details_optional';
export const DETAILS_OPTION_REQUIRED = 'details_required';
export const DETAILS_OPTION_NO_DETAILS = 'no_details';

export const ANSWER_OPTION_SKIP = 'Skip';

export const INVITE_STATUS_ACTIVE = 'active';
export const INVITE_STATUS_CANCELLED = 'cancelled';
export const INVITE_STATUS_SIGNED = 'signed';

export const SCROLL_TIMEOUT = 250;

export const MOBILE_WIDTH = 767;

export const SUB_LEASE_TYPE_LONG_TERM_RENEWAL = 'long_term_renewal';
export const SUB_LEASE_TYPE_SHORT_TERM_RENEWAL = 'short_term_renewal';
export const DEFAULT_SUB_LEASE_TYPE = 'default';

export const YES_LABEL = 'Yes';
export const NO_LABEL = 'No';

export const OPTION_TYPE_YES_NO = 'yesOrNo';
export const OPTION_TYPE_AGREE = 'agreeOnly';

export const SERVICE_TYPE_SALE = 'Sale';
export const SERVICE_TYPE_AUCTION = 'Auction';

export const BASIS_TYPE_OPEN_LISTING = 'Open Listing';
export const BASIS_TYPE_SOLE_AGENCY = 'Sole Agency';
export const BASIS_TYPE_EXCLUSIVE_AGENCY = 'Exclusive Agency';
export const SETTLEMENT_TYPE_CASH = 'Cash';
export const SETTLEMENT_TYPE_OTHER = 'Other';
export const LABEL_AGREE = 'Agree';
export const LABEL_DO_NOT_AGREE = 'Do not agree';

export const PRICE_TYPE_RESERVE = 'Reserve';
export const PRICE_TYPE_LIST = 'List';
export const COMMISSION_PAYABLE_TYPE_OTHER = 'Other';
export const COMMISSION_PAYABLE_TYPE_SALES = 'Sales';

export const REVIEW_METHOD_CPI = 'CPI';
export const REVIEW_METHOD_FIXED_AMOUNT = 'Fixed Amount';
export const REVIEW_METHOD_FIXED_PERCENTAGE = 'Fixed Percentage';
export const REVIEW_METHOD_CURRENT_MARKET_RENT = 'Current Market Rent';

export const OUTGOING_HANDLED_AS_FIXED_PERCENTAGE = 'fixed_percentage';
export const OUTGOING_HANDLED_AS_ESTIMATES_PROVIDED = 'estimates_provided';

export const OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITHOUT_INCREASES = 'without_increases';
export const OUTGOING_HANDLED_AS_FIXED_PERCENTAGE_WITH_INCREASES = 'with_increases';

export const LABEL_YES = 'Yes';
export const LABEL_NO = 'No';
export const LABEL_AGENT = 'Agent';
export const LABEL_LANDLORD = 'Landlord';
export const LABEL_STRATA = 'Strata';
export const LABEL_COMPANY = 'Company';

export const PDF_GEN_COMPLETED_WITH_CLIENT_PLACEHOLDERS = 'PDF_GEN_COMPLETED_WITH_CLIENT_PLACEHOLDERS';
export const PDF_GEN_PROCESSING_WITH_CLIENT_PLACEHOLDERS = 'PDF_GEN_PROCESSING_WITH_CLIENT_PLACEHOLDERS';
export const PDF_GEN_FAILED_WITH_CLIENT_PLACEHOLDERS = 'PDF_GEN_FAILED_WITH_CLIENT_PLACEHOLDERS';

export const RESPONDENT_TYPE_CLIENT = 'client';
