import axios from 'axios';
import cookie from 'react-cookie';
import hashHistory from './../history';

import { updateMaintenanceMessage } from './maintenance';

export const defaultAxiosInstance = axios.create({
    baseURL: SERVICE_URL,
    withCredentials: true
});

export const lambdaAxiosInstance = axios.create({
    baseURL: SERVERLESS_URL,
    withCredentials: true
});

/**
 * Update access token and retry failed request after success update.
 *
 * @param {string} refreshToken - refresh token.
 * @param requestConfig - config of failed request.
 *
 * @return {Promise.<AxiosPromise>}
 */
const updateAccessToken = requestConfig => {
    return defaultAxiosInstance
        .post('/api/tenant/refresh', null, { withCredentials: true })
        .then(() => {
            return axios.request(requestConfig);
        })
        .catch(data => {
            hashHistory.push('/');
            return Promise.reject({ data: data });
        });
};

/**
 * Initialize http client. Set up request/response interceptors and base url.
 * @return {void}
 */
export function configureAxios(store) {
    defaultAxiosInstance.interceptors.request.use(config => {
        //Set authentications headers.
        if (config.url.includes('maps.googleapis.com')) {
            return config;
        }

        config.headers = {
            accountType: cookie.load('accountType') || '',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        };
        return config;
    });
    defaultAxiosInstance.interceptors.response.use(
        response => response,
        error => {
            const url = error.config.url.replace(error.config.baseURL, '');
            if (
                url === '/api/tenant/send-verification-code' ||
                url === '/api/tenant/verify-code' ||
                url === '/api/user/signature-twilio-access-token'
            ) {
                return Promise.reject(error);
            }
            const {
                config,
                response: { status, data }
            } = error;
            switch (status) {
                // The state is in maintenance mode redirect to maintenance page
                case 503: {
                    const systemMaintenance = data.originError ? data.originError.additionalInfo : data._doc;
                    store.dispatch(updateMaintenanceMessage(systemMaintenance));
                    hashHistory.push({
                        pathname: '/maintenance'
                    });
                    break;
                }
                // Not authorized or accessToken expired.
                case 401: {
                    //if refreshToken is exists. If refreshToken is exists update access token, else redirect to login page.
                    return updateAccessToken(config);
                }
                default: {
                    return Promise.reject(error);
                }
            }
        }
    );
}
