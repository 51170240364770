import { find } from 'lodash';
import { STEP_CONFIRM_SUCCESS } from './steps';
import { statefulActions } from '../utils';

export const TENANT_LOGIN_REQUEST = 'TENANT_LOGIN_REQUEST';
export const TENANT_LOGIN_SUCCESS = 'TENANT_LOGIN_SUCCESS';
export const TENANT_LOGIN_FAILURE = 'TENANT_LOGIN_FAILURE';

export const APP_IS_LOADING = 'APP_IS_LOADING';

export const STATEMENT_LOGIN_REQUEST = 'STATEMENT_LOGIN_REQUEST';
export const STATEMENT_LOGIN_SUCCESS = 'STATEMENT_LOGIN_SUCCESS';
export const STATEMENT_LOGIN_FAILURE = 'STATEMENT_LOGIN_FAILURE';

export const RENT_REDUCTION_LOGIN_REQUEST = 'RENT_REDUCTION_LOGIN_REQUEST';
export const RENT_REDUCTION_LOGIN_SUCCESS = 'RENT_REDUCTION_LOGIN_SUCCESS';
export const RENT_REDUCTION_LOGIN_FAILURE = 'RENT_REDUCTION_LOGIN_FAILURE';

export const CONFIRM_RENT_REDUCTION_REQUEST = 'CONFIRM_RENT_REDUCTION_REQUEST';
export const CONFIRM_RENT_REDUCTION_SUCCESS = 'CONFIRM_RENT_REDUCTION_SUCCESS';
export const CONFIRM_RENT_REDUCTION_FAILURE = 'CONFIRM_RENT_REDUCTION_FAILURE';

export const STATEMENT_CONFIRM_REQUEST = 'STATEMENT_CONFIRM_REQUEST';
export const STATEMENT_CONFIRM_SUCCESS = 'STATEMENT_CONFIRM_SUCCESS';
export const STATEMENT_CONFIRM_FAILURE = 'STATEMENT_CONFIRM_FAILURE';

export const TENANT_SIGNATURE_REQUEST = 'TENANT_SIGNATURE_REQUEST';
export const TENANT_SIGNATURE_SUCCESS = 'TENANT_SIGNATURE_SUCCESS';
export const TENANT_SIGNATURE_FAILURE = 'TENANT_SIGNATURE_FAILURE';

export const TENANT_REFRESH_REQUEST = 'TENANT_REFRESH_REQUEST';
export const TENANT_REFRESH_SUCCESS = 'TENANT_REFRESH_SUCCESS';
export const TENANT_REFRESH_FAILURE = 'TENANT_REFRESH_FAILURE';

export const TENANT_AGREEMENT_REQUEST = 'TENANT_AGREEMENT_REQUEST';
export const TENANT_AGREEMENT_SUCCESS = 'TENANT_AGREEMENT_SUCCESS';
export const TENANT_AGREEMENT_FAILURE = 'TENANT_AGREEMENT_FAILURE';

export const SALES_AGREEMENT_REQUEST = 'SALES_AGREEMENT_REQUEST';
export const SALES_AGREEMENT_SUCCESS = 'SALES_AGREEMENT_SUCCESS';
export const SALES_AGREEMENT_FAILURE = 'SALES_AGREEMENT_FAILURE';

export const PM_AGREEMENT_REQUEST = 'PM_AGREEMENT_REQUEST';
export const PM_AGREEMENT_SUCCESS = 'PM_AGREEMENT_SUCCESS';
export const PM_AGREEMENT_FAILURE = 'PM_AGREEMENT_FAILURE';

export const FULL_PM_AGREEMENT_REQUEST = 'FULL_PM_AGREEMENT_REQUEST';
export const FULL_PM_AGREEMENT_SUCCESS = 'FULL_PM_AGREEMENT_SUCCESS';
export const FULL_PM_AGREEMENT_FAILURE = 'FULL_PM_AGREEMENT_FAILURE';

export const ADD_CONTACT_PERSON_REQUEST = 'ADD_CONTACT_PERSON_REQUEST';
export const ADD_CONTACT_PERSON_SUCCESS = 'ADD_CONTACT_PERSON_SUCCESS';
export const ADD_CONTACT_PERSON_FAILURE = 'ADD_CONTACT_PERSON_FAILURE';

export const GET_ADDRESS_BY_COORDINATES_REQUEST = 'GET_ADDRESS_BY_COORDINATES_REQUEST';
export const GET_ADDRESS_BY_COORDINATES_SUCCESS = 'GET_ADDRESS_BY_COORDINATES_SUCCESS';
export const GET_ADDRESS_BY_COORDINATES_FAIL = 'GET_ADDRESS_BY_COORDINATES_FAIL';

export const SEND_PDF_COPY_REQUEST = 'SEND_PDF_COPY_REQUEST';
export const SEND_PDF_COPY_SUCCESS = 'SEND_PDF_COPY_SUCCESS';
export const SEND_PDF_COPY_FAIL = 'SEND_PDF_COPY_FAIL';

export const CLOSE_SUCCESS_ALERT = 'CLOSE_SUCCESS_ALERT';
export const CLOSE_PAYLOAD_ALERT = 'CLOSE_PAYLOAD_ALERT';

export const HTTP_STATUS_UNPROCESSABLE_ENTITY = 422;

export const DISABLE_PAGE_SIGNING = 'DISABLE_PAGE_SIGNING';

export const SET_LOCATION = 'SET_LOCATION';

export const UPDATE_AGREEMENT = 'UPDATE_AGREEMENT';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export const SET_INITIAL_HEIGHT = 'SET_INITIAL_HEIGHT';

export const UPDATE_LEASE_AND_USER = 'UPDATE_LEASE_AND_USER';

export const UPDATE_DESKTOP_SIGNING_STATUS = 'UPDATE_DESKTOP_SIGNING_STATUS';

export const UPDATE_TENANT_EMERGENCY_CONTACT = statefulActions('tenant.update_emergency_contact');

export const UPDATE_OWNER_DISCLOSURE = statefulActions('lease.update_owner_disclosure');

export const SKIP_OWNER_DISCLOSURE = statefulActions('lease.skip_owner_disclosure');;

const initState = {
    step: 1,
    isLoggedIn: false,
    appIsLoading: true,
    lease: null,
    user: null,
    invite: null,
    signature: '',
    errorMessage: '',
    smsToken: null,
    isTokenIdShift: false,
    showSuccessAlert: false,
    gettingAgreement: false,
    agreementInfo: null,
    fullAgreementInfo: null,
    gettingFullAgreement: false,
    contactPersonSuccess: false,
    isConfirmingRequest: false,
    isConfirmingRequestSuccess: false,
    isConfirmingRequestError: false,
    isConfirmedRentReductionSuccess: false,
    documentToSign: null,
    initialWindowHeight: null
};

export default function tenant(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case APP_IS_LOADING: {
            newState.appIsLoading = action.appIsLoading;
            break;
        }

        case TENANT_LOGIN_REQUEST: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = false;
            newState.agreementInfo = null;
            newState.desktopSigningStatus = null;
            break;
        }
        case TENANT_LOGIN_SUCCESS: {
            newState.smsToken = action.token.mobileToken;
            newState.refreshToken = action.result.data.refreshToken;
            newState.desktopSigningStatus = action.result.data.desktopSigningStatus;
            newState.isLoggedIn = true;
            newState.isTokenIdShift = true;
            newState.errorMessage = '';
            newState.contactPersonSuccess = false;
            newState = Object.assign({}, newState, action.result.data);
            break;
        }
        case TENANT_LOGIN_FAILURE: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = true;
            newState.errorMessage = action.error.response.data.error;
            break;
        }
        case STATEMENT_LOGIN_REQUEST: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = false;
            newState.agreementInfo = null;
            break;
        }
        case STATEMENT_LOGIN_SUCCESS: {
            newState.smsToken = action.token.mobileToken;
            newState.refreshToken = action.result.data.refreshToken;
            newState.isLoggedIn = true;
            newState.isTokenIdShift = true;
            newState.errorMessage = '';
            newState = Object.assign({}, newState, action.result.data);
            break;
        }
        case RENT_REDUCTION_LOGIN_SUCCESS: {
            newState.smsToken = action.token.mobileToken;
            newState.refreshToken = action.result.data.refreshToken;
            newState.isLoggedIn = true;
            newState.isTokenIdShift = true;
            newState.errorMessage = '';
            newState.rentReductionAgreement = action.result.data.invite.documentDetails
                ? action.result.data.invite.documentDetails.rentReductionAgreement
                : null;
            newState.documentDetails = action.result.data.invite.documentDetails;
            newState.deletedDocument = !action.result.data.invite.documentDetails;
            newState = Object.assign({}, newState, action.result.data);
            break;
        }
        case STATEMENT_LOGIN_FAILURE: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = true;
            newState.errorMessage = action.error.response.data.error;
            break;
        }

        case STATEMENT_CONFIRM_REQUEST: {
            newState.isConfirmingRequest = true;
            newState.isConfirmingRequestSuccess = false;
            newState.isConfirmingRequestError = false;
            break;
        }
        case STATEMENT_CONFIRM_SUCCESS: {
            newState.isConfirmingRequest = false;
            newState.isConfirmingRequestSuccess = true;
            newState.isConfirmingRequestError = false;
            break;
        }
        case STATEMENT_CONFIRM_FAILURE: {
            newState.isConfirmingRequest = false;
            newState.isConfirmingRequestSuccess = false;
            newState.isConfirmingRequestError = true;
            break;
        }

        case TENANT_SIGNATURE_REQUEST: {
            newState.lease = {};
            break;
        }
        case STEP_CONFIRM_SUCCESS: {
            newState.lease = action.result.data.lease;
            newState.user = {
                ...state.user,
                ...action.result.data.user
            };
            break;
        }
        case TENANT_SIGNATURE_SUCCESS: {
            newState.lease = action.result.data;
            break;
        }
        case TENANT_SIGNATURE_FAILURE: {
            newState.lease = {};
            break;
        }

        case TENANT_REFRESH_REQUEST: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = false;
            break;
        }
        case TENANT_REFRESH_SUCCESS: {
            newState.refreshToken = action.result.data.refreshToken;
            newState.errorMessage = '';
            newState.isLoggedIn = true;
            newState.isTokenIdShift = false;
            newState = Object.assign({}, newState, action.result.data);
            break;
        }
        case TENANT_REFRESH_FAILURE: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = false;
            newState.errorMessage = action.error.response.statusText;
            break;
        }
        case TENANT_AGREEMENT_REQUEST: {
            newState.gettingAgreement = true;
            break;
        }
        case TENANT_AGREEMENT_SUCCESS: {
            newState.gettingAgreement = false;
            newState.agreementInfo = {
                specialTerms: action.result.data.specialTerms,
                standardTerms: action.result.data.standardTerms,
                additionalTerms: action.result.data.additionalTerms
            };
            break;
        }
        case SALES_AGREEMENT_REQUEST: {
            newState.gettingAgreement = true;
            break;
        }
        case SALES_AGREEMENT_SUCCESS: {
            newState.gettingAgreement = false;
            newState.agreementInfo = {
                specialTerms: action.result.data.specialTerms,
                standardTerms: action.result.data.standardTerms
            };
            break;
        }
        case SALES_AGREEMENT_FAILURE: {
            newState.gettingAgreement = false;
            newState.errorMessage = action.error.response.statusText;
            break;
        }
        case PM_AGREEMENT_REQUEST: {
            newState.gettingAgreement = true;
            break;
        }
        case PM_AGREEMENT_SUCCESS: {
            newState.gettingAgreement = false;
            newState.agreementInfo = {
                specialTerms: action.result.data.specialTerms,
                standardTerms: action.result.data.standardTerms
            };
            break;
        }
        case FULL_PM_AGREEMENT_REQUEST: {
            newState.gettingFullAgreement = true;
            break;
        }
        case FULL_PM_AGREEMENT_SUCCESS: {
            newState.gettingFullAgreement = false;
            newState.fullAgreementInfo = {
                standardTerms: action.result.data.standardTerms
            };
            break;
        }
        case FULL_PM_AGREEMENT_FAILURE: {
            newState.gettingFullAgreement = false;
            // statusText may not always be present
            if (action.error.response.statusText) {
                newState.errorMessage = action.error.response.statusText;
            } else {
                newState.errorMessage = 'fail-get-standard-terms';
            }
            break;
        }
        case ADD_CONTACT_PERSON_SUCCESS: {
            newState.contactPersonSuccess = true;
            break;
        }

        case PM_AGREEMENT_FAILURE: {
            newState.gettingAgreement = false;
            newState.errorMessage = action.error.response.statusText;
            break;
        }
        case UPDATE_AGREEMENT: {
            newState.agreementInfo = action.agreement;
            break;
        }
        case TENANT_AGREEMENT_FAILURE: {
            newState.gettingAgreement = false;
            newState.errorMessage = action.error.response.statusText;
            break;
        }
        case GET_ADDRESS_BY_COORDINATES_SUCCESS: {
            newState.user = {
                ...state.user,
                signingLocation: action.result.data.address
            };
            break;
        }
        case SET_LOCATION: {
            newState.user = {
                ...state.user,
                signingLocation: action.address
            };
            break;
        }
        case SEND_PDF_COPY_SUCCESS: {
            return {
                ...state,
                showSuccessAlert: true
            };
        }
        case CLOSE_SUCCESS_ALERT: {
            return {
                ...state,
                showSuccessAlert: false
            };
        }
        case DISABLE_PAGE_SIGNING: {
            // need to update a prop inside an individual step object
            newState.steps = [...state.steps];
            const theStep = find(newState.steps, function(step) {
                return step.name === action.payload.stepName;
            });
            theStep.disablePageSigning = action.payload.value;
            return newState;
        }
        case RENT_REDUCTION_LOGIN_REQUEST: {
            newState.isConfirmedRentReductionSuccess = false;
            return newState;
        }

        case CONFIRM_RENT_REDUCTION_SUCCESS: {
            newState.isConfirmedRentReductionSuccess = true;
            return newState;
        }
        case SET_ERROR_MESSAGE: {
            newState.errorMessage = action.error;
            break;
        }

        case SET_INITIAL_HEIGHT: {
            newState.initialWindowHeight = action.initialWindowHeight;
            break;
        }
        case UPDATE_LEASE_AND_USER: {
            newState.lease = action.lease;
            newState.user = {
                ...state.user,
                ...action.user,
            };
            break;
        }
        case UPDATE_DESKTOP_SIGNING_STATUS: {
            newState.desktopSigningStatus = action.desktopSigningStatus;
            break;
        }
        case UPDATE_TENANT_EMERGENCY_CONTACT.REQUEST: {
            newState.emergencyContactStatus = {
                loading: true,
            };
            break;
        }
        case UPDATE_TENANT_EMERGENCY_CONTACT.SUCCESS: {
            newState.emergencyContactStatus = {
                loading: false,
            };
            newState.user = {
                ...state.user,
                ...(action?.result?.data || {}),
            };
            break;
        }
        case UPDATE_TENANT_EMERGENCY_CONTACT.FAIL: {
            newState.emergencyContactStatus = {
                loading: false,
            };
            break;
        }

        case UPDATE_OWNER_DISCLOSURE.REQUEST: {
            if(state?.lease?.pmRentAndPayment) {
                newState.lease = {
                    ...state.lease,
                    pmRentAndPayment: {
                        ...state.lease.pmRentAndPayment,
                        loading: true
                    }
                } 
            }
            break;
        }
        case UPDATE_OWNER_DISCLOSURE.SUCCESS: {
            if(state?.lease?.pmRentAndPayment) {
                newState.lease = {
                    ...state.lease,
                    pmRentAndPayment: {
                        ...state.lease.pmRentAndPayment,
                        ...(action?.result?.data || {}),
                        loading: false
                    }
                } 
            }
            break;
        }
        case UPDATE_OWNER_DISCLOSURE.FAIL: {
            if(state.lease.pmRentAndPayment) {
                newState.lease = {
                    ...state.lease,
                    pmRentAndPayment: {
                        ...state.lease.pmRentAndPayment,
                        loading: false
                    }
                } 
            }
            break;
        }
        case SKIP_OWNER_DISCLOSURE.REQUEST: {
            if(state?.lease?.pmRentAndPayment) {
                newState.lease = {
                    ...state.lease,
                    pmRentAndPayment: {
                        ...state.lease.pmRentAndPayment,
                        skipOwnerDisclosure: false,
                        loading: true
                    }
                } 
            }
            break;
        }
        case SKIP_OWNER_DISCLOSURE.SUCCESS: {
            if(state?.lease?.pmRentAndPayment) {
                newState.lease = {
                    ...state.lease,
                    pmRentAndPayment: {
                        ...state.lease.pmRentAndPayment,
                        skipOwnerDisclosure: true,
                        loading: false
                    }
                } 
            }
            break;
        }
        case SKIP_OWNER_DISCLOSURE.FAIL: {
            if(state.lease.pmRentAndPayment) {
                newState.lease = {
                    ...state.lease,
                    pmRentAndPayment: {
                        ...state.lease.pmRentAndPayment,
                        skipOwnerDisclosure: false,
                        loading: false
                    }
                } 
            }
            break;
        }
        
    }

    return newState;
}
