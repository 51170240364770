import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/main';
import { confirmStep } from '../../actions/main';
import Preloader from '../../components/Preloader';
import { isLeaseLocationQLD } from '../../utils';

class QuestionsFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false,
        };
    }

    submitPmAnswers = () => {
        const data = {
            step: this.props.currentStep.step,
            stepName: this.props.currentStep.name,
            payload: {
                answers: this.props.answers,
            },
        };
        if (this.props.location && isLeaseLocationQLD(this.props.location)) {
            this.props.confirmButtonHandler(data.payload);
        } else {
            this.props.confirmStep(data);
        }
        this.setState({
            isOpenSignModal: true,
        });
    };

    render() {
        const { isConfirmingStep, allAnswered } = this.props;
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        return (
            <footer className="column">
                {allAnswered && (
                    <div className="confirm-footer">
                        <button onClick={this.submitPmAnswers} disabled={isConfirmingStep}>
                            Submit{preloader}
                        </button>
                    </div>
                )}
            </footer>
        );
    }
}

export default connect(
    (state) => ({
        user: state.main.user,
        agency: state.main.lease.agency.details,
        lease: state.main.lease,
        steps: state.steps.steps,
        isConfirmingStep: state.steps.isConfirmingStep,
    }),
    {
        sendPDFCopy: actions.sendPDFCopy,
        closeSuccessAlert: actions.closeSuccessAlert,
        confirmStep,
    }
)(QuestionsFooter);
