import produce, { Draft } from 'immer';
import { ToastTypes } from '../components/new_designs/Toast';

export const ADD_NEW_TOAST = 'ADD_NEW_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

type ToastState = {
    toastList: Toast[];
};

type AddNewToastAction = {
    type: typeof ADD_NEW_TOAST;
    toast: Toast;
};

type RemoveToastAction = {
    type: typeof REMOVE_TOAST;
    id: Date;
};

export type Toast = {
    type: ToastTypes;
    id: Date;
    message: string;
    timeout?: number;
};

type ToastActionTypes = AddNewToastAction | RemoveToastAction;

const initState: ToastState = {
    toastList: [],
};

export default function toast(state: ToastState = initState, action: ToastActionTypes): ToastState {
    const newState = { ...state };

    switch (action.type) {
        case ADD_NEW_TOAST: {
            return produce(state, (draftState: Draft<ToastState>) => {
                draftState.toastList.push(action.toast);
            });
        }

        case REMOVE_TOAST: {
            return produce(state, (draftState: Draft<ToastState>) => {
                if (action.id) {
                    const index = draftState.toastList.findIndex((item: Toast) => item.id === action.id);
                    if (index !== -1) {
                        draftState.toastList.splice(index, 1);
                    }
                }
            });
        }
    }

    return newState;
}
