import React, { useEffect } from 'react';
import styles from './Preloader.module.scss';
import { useDispatch } from 'react-redux';
import { setAppIsLoading } from '../../actions/main.js';
import cx from 'classnames';

type PreloaderProps = {
    purple?: boolean;
    className?: string;
};

const Preloader = ({ purple, className }: PreloaderProps) => {
    const dispatch = useDispatch();
    useEffect(() => {
        // When the preloader mounts, store isLoading as true
        dispatch(setAppIsLoading(true));
        return () => {
            // Anything in here is fired on component unmount.
            // When we unmount the preloader, the page is no longer loading
            dispatch(setAppIsLoading(false));
        };
    }, []);
    return <div className={cx(className, styles.splashPreloader, { [styles.purple]: purple })} />;
};

export default Preloader;
