import React from 'react';

export const UPDATE_MAINTENANCE = 'UPDATE_MAINTENANCE';

const initState = {
    message: '',
    subMessage: ''
};

export default function storeMaintenanceMessage(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case UPDATE_MAINTENANCE: {
            newState.message = action.payload.message;
            newState.subMessage = action.payload.subMessage;
            break;
        }
    }

    return newState;
}
