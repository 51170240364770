import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(require('sass/popup.scss'));

export default class Popup extends React.Component {
    render() {
        const { isOpen, button, close } = this.props;

        return (
            <div className={cx('popup-wrapper', { open: isOpen })}>
                <div className="popup">
                    <div className="popup-body">{this.props.children}</div>
                    <div className="popup-footer">
                        <button onClick={close}>{button}</button>
                    </div>
                </div>
            </div>
        );
    }
}

Popup.propTypes = {
    isOpen: PropTypes.bool,
    button: PropTypes.string,
    close: PropTypes.func
};
