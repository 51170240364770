import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { getConfirmModalCustomStyle } from '../../utils';

const ConfirmGlobalAdditionalTermsInitial = ({ isOpen, hideModal, confirmButtonHandler }) => {
    const user = useSelector(state => state.main.user);

    const [confirmButtomPage, setConfirmButtomPage] = useState(false);

    const close = () => {
        hideModal();
    };

    const confirmAgreeButtonHandler = () => {
        if (confirmButtomPage) {
            confirmButtonHandler({ isConfirmDisclosureByTenant: true });
        }
    };

    const confirmButtomPageMsg = `I ${
        user.fullName
    } approve for my initials to be inserted on the bottom of each page of this agreement`;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={getConfirmModalCustomStyle()}
                className="confirm"
                contentLabel="agent-modal"
                onRequestClose={() => close()}
                appElement={document.getElementById('root')}
            >
                <div className="confirm-container">
                    <div className="confirm-title">CONFIRMATION</div>
                    <div className="confirm-details">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                value={confirmButtomPageMsg}
                                id="confirmButtomPage"
                                checked={confirmButtomPage}
                                onChange={() => setConfirmButtomPage(!confirmButtomPage)}
                            />
                            <label htmlFor="confirmButtomPage">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">{confirmButtomPageMsg}</span>
                            </label>
                        </div>
                    </div>
                    <div className="confirm-footer">
                        <button disabled={!confirmButtomPage} onClick={confirmAgreeButtonHandler}>
                            I Agree
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmGlobalAdditionalTermsInitial;
