import 'sass/changeRequestInfo.scss';

import * as React from 'react';

import { connect } from 'react-redux';

class ChangeRequestInfo extends React.Component {
    constructor() {
        super();

        this.state = {
            name: ''
        };
    }

    componentDidMount() {
        const { step, user, changeRequestList } = this.props;
        let name;

        for (let i = 0; i < changeRequestList.length; i++) {
            if (changeRequestList[i].leaseAgreementStep === step && changeRequestList[i].createdBy !== user.id) {
                name = changeRequestList[i].tenantName;
            } else {
                name = 'You';
            }
        }

        this.setState({
            ...this.state,
            name: name
        });
    }

    render() {
        const { declineButtonHandler } = this.props;
        return (
            <div>
                <div className="change-request-info">
                    <span>
                        {this.state.name} requested a change, you will be sent an updated link from your agent when
                        these changes have been addressed.
                    </span>
                    <i
                        className="icon-info"
                        onClick={() => {
                            declineButtonHandler();
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    user: state.main.user,
    changeRequestList: state.steps.changeRequestList
}))(ChangeRequestInfo);
