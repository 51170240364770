import React, { Component } from 'react';
import '../../sass/actionModal.scss';

class ModalAction extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { text, children } = this.props;

        return (
            <div className="question-modal">
                <div className="text">{text}</div>
                <div className="buttons">{children}</div>
            </div>
        );
    }
}

export default ModalAction;
