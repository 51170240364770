import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { getLease, getAgencyDetails } from 'selectors/lease';
import { getConfirmModalCustomStyle, LABEL_YES } from 'utils';
import { getUser } from 'selectors/main';

const ConfirmIdentitySa = ({ hideModal, confirmButtonHandler, isOpen }) => {
    const agency = useSelector(getAgencyDetails);
    const user = useSelector(getUser);
    const lease = useSelector(getLease);

    const [confirmed, setConfirmed] = useState(false);
    const [pdfExecutedAsAgreement, setPdfExecutedAsAgreement] = useState(false);
    const [pdfPartB, setPdfPartB] = useState(false);
    const [pdfPetAnnexure, setPdfPetAnnexure] = useState(lease.pets && lease.pets.isApprovedPets !== LABEL_YES);
    const [pdfSpecialConditions, setPdfSpecialConditions] = useState(
        !lease.specialConditions || lease.specialConditions.clauseList.length === 0
    );

    const agree = () => {
        if (confirmed) {
            confirmButtonHandler({ confirmedIdentity: true });
        }
    };

    const getSaConfirmBlock = () => {
        return (
            <div className="confirm-details">
                <span className="label-text-checkbox">
                    I confirm that my signature can be inserted onto the PDF in the below required areas:
                </span>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        value="special"
                        id="pdfExecutedAsAgreement"
                        checked={pdfExecutedAsAgreement}
                        onChange={() => setPdfExecutedAsAgreement(!pdfExecutedAsAgreement)}
                    />
                    <label htmlFor="pdfExecutedAsAgreement">
                        <span className="checkmark" />
                        <span className="label-text-checkbox">Executed as an Agreement</span>
                    </label>
                </div>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        value="special"
                        id="pdfPartB"
                        checked={pdfPartB}
                        onChange={() => setPdfPartB(!pdfPartB)}
                    />
                    <label htmlFor="pdfPartB">
                        <span className="checkmark" />
                        <span className="label-text-checkbox">Part B - General Conditions</span>
                    </label>
                </div>
                {lease.pets &&
                    lease.pets.isApprovedPets === LABEL_YES && (
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                value="signatures"
                                id="pdfPetAnnexure"
                                checked={pdfPetAnnexure}
                                onChange={() => setPdfPetAnnexure(!pdfPetAnnexure)}
                            />
                            <label htmlFor="pdfPetAnnexure">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">Pet Annexure</span>
                            </label>
                        </div>
                    )}
                {lease.specialConditions &&
                    lease.specialConditions.clauseList.length > 0 && (
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                value="signatures"
                                id="pdfSpecialConditions"
                                checked={pdfSpecialConditions}
                                onChange={() => setPdfSpecialConditions(!pdfSpecialConditions)}
                            />
                            <label htmlFor="pdfSpecialConditions">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">
                                    Annexure – Special Conditions / Additional Terms
                                </span>
                            </label>
                        </div>
                    )}
            </div>
        );
    };

    const resTenMessage = `I confirm I am ${user.fullName} as identified by documents provided to ${
        agency.agencyName
    }. This signature is my own, and I also confirm I agree to sign my Residential Tenancy Agreement in this electronic format.`;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={getConfirmModalCustomStyle()}
                className="confirm"
                contentLabel="agent-modal"
                onRequestClose={hideModal}
                appElement={document.getElementById('root')}
            >
                <div className="confirm-container">
                    <div className="confirm-title">Confirmation</div>
                    <div className="confirm-details">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="confirmation"
                                checked={confirmed}
                                onChange={() => setConfirmed(!confirmed)}
                            />
                            <label htmlFor="confirmation">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">{resTenMessage}</span>
                            </label>
                        </div>
                    </div>
                    {getSaConfirmBlock()}
                    <div className="confirm-footer">
                        <button
                            disabled={
                                !(
                                    pdfPartB &&
                                    pdfPetAnnexure &&
                                    pdfExecutedAsAgreement &&
                                    pdfSpecialConditions &&
                                    confirmed
                                )
                            }
                            onClick={agree}
                        >
                            I Agree
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmIdentitySa;
