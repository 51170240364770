import React, { Component } from 'react';
import Modal from 'react-modal';
import 'sass/furnishedModal.scss';
import { getConfirmModalCustomStyle } from '../../utils';

class Alert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    }

    close() {
        this.props.hideModal();
        this.setState({ modal: false });
    }

    render() {
        const { modalIsOpen } = this.props;

        return (
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    style={getConfirmModalCustomStyle(9999)}
                    className="furnished-modal"
                    contentLabel="Modal"
                    onRequestClose={() => this.close()}
                    appElement={document.getElementById('root')}
                >
                    <div className="furnished-modal">
                        <div className="modal-content">
                            <div className="text">
                                <p>{this.props.text}</p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.close()}>OK</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Alert;
