import React from 'react';
import maintenanceImage from '../../../public/images/scheduled-maintenance.svg';
import 'sass/maintenance.scss';
import { useSelector } from 'react-redux';
import { getMaintenanceMessage, getMaintenanceSubMessage } from '../../selectors/maintenance';

const Maintenance = () => {
    const message = useSelector(getMaintenanceMessage);
    const subMessage = useSelector(getMaintenanceSubMessage);
    return (
        <div className="maintenance">
            <div>
                <img src={maintenanceImage} alt="graphic depicting website maintenance" />
            </div>
            <section className="maintenance-text-container">
                <h2 className="text">{message}</h2>
                <p className="text">{subMessage}</p>
            </section>
        </div>
    );
};

export default Maintenance;
