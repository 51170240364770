import React, { useLayoutEffect } from 'react';
import Typography, { TypographyTypes } from './new_designs/Typography';
import Card, { CardBorder, CardShadow, CardStyles } from '../componentLibrary/Card/Card';
import { getSmsToken } from '../selectors/main/index.js';
import Button, { ButtonStyle } from '../common/components/Button.js';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import useToast from '../hooks/useToast';
import Icon, { Icons } from '../componentLibrary/Icon/Icon';
import { isTabletPortraitUp } from '../utils.js';
import { ToastTypes } from './new_designs/Toast';
import FlkHeader from './FlkHeader';
import RotateScreen from './RotateScreen';

import styles from './ContinueOnMobile.module.scss';

const DESKTOP_BREAKPOINT = '782px';

const ContinueOnMobile = () => {
    const { addNewToast } = useToast();
    const isDesktop = isTabletPortraitUp(DESKTOP_BREAKPOINT);
    const token = useSelector(getSmsToken);
    const link = `${LETS_URL}/#/${token}`;

    function copyLink() {
        navigator.clipboard.writeText(link).then(() => {
            addNewToast('Link copied!', ToastTypes.SUCCESS);
        });
    }

    useLayoutEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    return isDesktop ? (
        <div className={styles.continueOnMobile}>
            <FlkHeader />
            <div className={styles.container}>
                <div className={styles.text}>
                    <Typography
                        type={TypographyTypes.H1}
                        text="FLK it over is designed to be a smart phone signing experience."
                    />
                    <Typography text="Please scan the QR code or copy the link to a mobile device to launch your signing experience." />
                </div>
                <Card
                    className={styles.qrCodeSection}
                    style={CardStyles.SQUARE}
                    shadow={CardShadow.NONE}
                    border={CardBorder.GRAY_BORDER}
                >
                    <img className={styles.phone} src="/images/phone_scanning_qr.svg" alt="" />
                    <div className={styles.qr}>
                        <QRCode className={styles.qrCode} value={link} size={174} viewBox="0 0 174 174" />
                    </div>
                    <div className={styles.copyLinkSection}>
                        <div className={styles.linkSection}>
                            <Typography text="QR code link:" />
                            <Typography text={link} className={styles.link} />
                        </div>
                        <Button buttonStyle={ButtonStyle.PRIMARY} className={styles.copyButton} onClick={copyLink}>
                            <Icon icon={Icons.LINK} className={styles.copyIcon} />
                            Copy link
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    ) : (
        <RotateScreen isDesktop={true} />
    );
};

export default ContinueOnMobile;
