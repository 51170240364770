export default function promiseMiddleware() {
    return next => action => {
        const { promise, types, data, ...rest } = action;
        // console.log('Action in middleware ' + JSON.stringify(action));
        if (!promise) {
            return next(action);
        }
        const [REQUEST, SUCCESS, FAILURE] = types;

        next({ ...rest, type: REQUEST });

        return promise
            .then(
                result => {
                    const SUCCESS_ACTION =
                        result.data && result.data.successAction ? result.data.successAction : SUCCESS;
                    next({ ...rest, result, data, type: SUCCESS_ACTION });
                },
                error => {
                    return next({ ...rest, error, data, type: FAILURE });
                }
            )
            .catch(error => {
                // eslint-disable-next-line no-console
                console.error('MIDDLEWARE ERROR1:', error);
                console.error('MIDDLEWARE ERROR:', FAILURE);
                next({ ...rest, error, data, type: FAILURE });
            });
    };
}
