import { createSelector } from 'reselect';
export const getLease = (state) => state.main.lease;

export const getLocation = createSelector([getLease], (lease) => lease.location);
export const getLeaseType = createSelector([getLease], (lease) => lease.leaseType);
export const getSubLeaseType = createSelector([getLease], (lease) => lease.subLeaseType);
export const getAddress = createSelector([getLease], (lease) => lease.address);
export const getAgency = createSelector([getLease], (lease) => lease.agency);
export const getAgent = createSelector([getLease], (lease) => lease.agent);
export const getAgencyDetails = createSelector([getLease], (lease) => lease.agency.details);
export const getAgencySalesDetails = createSelector([getLease], (lease) => lease.agency.salesDetails);
export const getAgencyLicenseDetails = createSelector([getLease], (lease) => lease.agency.licenseDetails);
export const getAgencyLocation = createSelector([getLease], (lease) => lease.agency.details.location);
export const getExclusions = createSelector([getLease], (lease) => lease.exclusions);
export const getExecutedAsAgreement = createSelector([getLease], (lease) => lease.executedAsAgreement);
export const getLandlord = createSelector([getLease], (lease) => lease.landlord);
export const getTenant = createSelector([getLease], (lease) => lease.tenant);
export const getLessor = createSelector([getLease], (lease) => lease.lessor);
export const getLessee = createSelector([getLease], (lease) => lease.lessee);
export const getGuarantor = createSelector([getLease], (lease) => lease.guarantor);
export const getLicensee = createSelector([getLease], (lease) => lease.licensee);
export const getDisclosure = createSelector([getLease], (lease) => lease.disclosure);
export const getInstructions = createSelector([getLease], (lease) => lease.instructions);
export const getInsurance = createSelector([getLease], (lease) => lease.insurance);
export const getOutgoings = createSelector([getLease], (lease) => lease.outgoings);
export const getPets = createSelector([getLease], (lease) => lease.pets);
export const getRent = createSelector([getLease], (lease) => lease.rent);
export const getRepairsAndMaintenance = createSelector([getLease], (lease) => lease.repairsAndMaintenance);
export const getNominatedRepairers = createSelector([getLease], (lease) => lease.nominatedRepairers);
export const getTerm = createSelector([getLease], (lease) => lease.term);
export const getPermittedUse = createSelector([getLease], (lease) => lease.permittedUse);
export const getPremises = createSelector([getLease], (lease) => lease.premises);
export const getDocuments = createSelector([getLease], (lease) => lease.documents);
export const getSecurityDeposit = createSelector([getLease], (lease) => lease.securityDeposit);
export const getInformation = createSelector([getLease], (lease) => lease.information);
export const getSpecialConditions = createSelector([getLease], (lease) => lease.specialConditions);

export const getPmAuthorityPeriodDetails = createSelector([getLease], (lease) => lease.pmAuthorityPeriodDetails);
export const getPmRentAndTerm = createSelector([getLease], (lease) => lease.pmRentAndTerm);
export const getPmFees = createSelector([getLease], (lease) => lease.pmFees);
export const getPmAdditionalCosts = createSelector([getLease], (lease) => lease.pmAdditionalCosts);
export const getPmMarketingCosts = createSelector([getLease], (lease) => lease.pmMarketingCosts);
export const getPmOutgoings = createSelector([getLease], (lease) => lease.pmOutgoings);
export const getPmRentAndPayment = createSelector([getLease], (lease) => lease.pmRentAndPayment);
export const getPmRepairs = createSelector([getLease], (lease) => lease.pmRepairs);
export const getDisclosureOfRebates = createSelector([getLease], (lease) => lease.disclosureOfRebates);
export const getPmDuties = createSelector([getLease], (lease) => lease.pmDuties);
export const getPmServiceSchedule = createSelector([getLease], (lease) => lease.pmServicesSchedule);

export const getSignatories = createSelector([getLease], (lease) => lease.signatory);
export const getAgreementTerm = createSelector([getLease], (lease) => lease.term);
export const getPriceAndFee = createSelector([getLease], (lease) => lease.priceAndFee);
export const getMarketingAndAdvertising = createSelector([getLease], (lease) => lease.marketingAndAdvertising);
export const getExpensesAndCharges = createSelector([getLease], (lease) => lease.expensesAndCharges);
export const getManagingAgent = createSelector([getLease], (lease) => lease.managingAgent);
export const getLandlordsSolicitor = createSelector([getLease], (lease) => lease.landlordsSolicitor);
export const getMaterialFacts = createSelector([getLease], (lease) => lease.materialFacts);
export const getPropertyDescription = createSelector([getLease], (lease) => lease.propertyDescription);
export const getPropertyDetails = createSelector([getLease], (lease) => lease.propertyDetails);
