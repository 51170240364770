import { debounce } from 'lodash';
import React from 'react';

const ViewportHeightContext = React.createContext<number | null>(null);

const useViewportHeight = () => {
    const viewportHeight = React.useContext(ViewportHeightContext);
    return viewportHeight;
};

interface ViewportHeightProviderProps {
    children: React.ReactNode;
}

/**
 * Provides the height of the innerWindow to the application as --viewport-height CSS variable.
 * This is used as a fallback to the [dvh unit](https://caniuse.com/viewport-unit-variants).
 */
const ViewportHeightProvider: React.FC<ViewportHeightProviderProps> = ({ children }) => {
    const [viewportHeight, setViewportHeight] = React.useState<number | null>(null);

    React.useEffect(() => {
        const updateViewportHeight = () => {
            const height = window.innerHeight;
            setViewportHeight(height);
            document.documentElement.style.setProperty('--viewport-height', `${height}px`);
        };

        updateViewportHeight();

        const debouncedUpdateViewportHeight = debounce(updateViewportHeight, 100);

        window.addEventListener('resize', debouncedUpdateViewportHeight);

        return () => {
            window.removeEventListener('resize', debouncedUpdateViewportHeight);
        };
    }, []);

    return <ViewportHeightContext.Provider value={viewportHeight}>{children}</ViewportHeightContext.Provider>;
};

export { ViewportHeightProvider, useViewportHeight };

