export const SPECIAL_CONDITIONS_INITIAL_STEP = 'specialConditionsInitial';
export const AGENT_CONDITIONS_STEP = 'agent_conditions';
export const MATERIAL_FACT_STEP = 'materialFact';
export const AGREEMENT_STEP = 'agreement';
export const TENANT_SIGNATURE_STEP = 'tenantSignature';
export const TENANT_CONFIRMATION_STEP = 'tenantConfirmation';
export const WITNESS_SIGNING_STEP = 'witnessSigning';
export const DISCLOSURE_STEP = 'disclosure';
export const PM_AGREEMENT_STEP = 'pmStandardTerms';
export const SALES_AGREEMENT_STEP = 'standard_terms_conditions';

export const SALES_REBATES_STEP = 'rebates';
export const SALES_OWNER_ID_STEP = 'owner_id';
export const SALES_OWNER_SIGN_STEP = 'owner_signs';

export const AGENCY_ACCOUNT_TYPE = 'agency_account_type';
export const GENERAL_ACCOUNT_TYPE = 'general_account_type';

export const CONFIRMATION_TYPE_SIGN = 'sign';
export const CONFIRMATION_TYPE_ACKNOWLEDGE = 'acknowledge';
export const EXECUTION_TYPE_EMBEDDED_SIGNATURES = 'Embedded signatures';
export const IDENTIFICATION_HEADER_1 =
    "We are required to confirm your identification, to proceed please ensure that you take a clear image of your photo ID. E.g. driver's license or passport.";
export const IDENTIFICATION_HEADER_2 = 'Your image will not be stored on our servers.';

export const customDocumentRoutes = {
    CUSTOM_DOCUMENT_INFO: '/custom-document/details',
    CUSTOM_DOCUMENT_SIGN: '/custom-document/sign/:uploadedDocId',
    CUSTOM_DOCUMENT_WELCOME: '/custom-document/:token',
    CUSTOM_DOCUMENT_FORM: '/custom-document/form'
};

export const COMPLIANT_TYPE_COMPLIANT = 'Compliant';
export const COMPLIANT_TYPE_NON_COMPLIANT = 'Non Compliant';
export const COMPLIANT_TYPE_UNKNOWN = 'Unknown';

export const YES_VALUE = 'yes';
export const NO_VALUE = 'no';