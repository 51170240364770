import React, { createContext, useContext, useEffect, useState } from 'react';
import { defaultAxiosInstance } from '../../actions';
const { SyncClient } = require('twilio-sync');
import { useSelector } from 'react-redux';
import { getIsLoggedIn, getRentReductionAgreement } from '../../selectors/main';
import { getIsDocumentLoggedIn } from '../../selectors/documents';

/**
 * @returns {Promise}
 */
const getTwilioAccessToken = (accessTokenUrl) => {
    return defaultAxiosInstance.get(accessTokenUrl).then((response) => {
        if (response) {
            return Promise.resolve(response.data.token);
        } else {
            Promise.reject('Something went wrong getting twillio token');
        }
    });
};

const initializeSync = (accessTokenUrl) => {
    return getTwilioAccessToken(accessTokenUrl).then((token) => {
        if (!token) {
            return false;
        }

        let syncClient;
        syncClient = new SyncClient(token);

        syncClient.on('connectionStateChanged', function (state) {
            if (state !== 'connected') {
                // eslint-disable-next-line no-console
                console.log('Sync is not live (websocket connection ' + state);
            } else {
                // eslint-disable-next-line no-console
                console.log('Sync is live!');
            }
        });

        syncClient.on('tokenAboutToExpire', function () {
            // Obtain a JWT access token: https://www.twilio.com/docs/sync/identity-and-access-tokens
            getTwilioAccessToken(accessTokenUrl).then((token) => {
                syncClient.updateToken(token).catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log('There was an error updating token');
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
            });
        });

        return syncClient;
    });
};

const TwilioSyncContext = createContext(null);

export function TwilioSyncProvider({ children }) {
    const isDocumentLoggedIn = useSelector(getIsDocumentLoggedIn);
    const isAgreementLoggedIn = useSelector(getIsLoggedIn);
    const rentReductionAgreement = useSelector(getRentReductionAgreement);

    const [client, setClient] = useState();

    let accessTokenUrl = '';
    if (isDocumentLoggedIn) {
        accessTokenUrl = '/api/mobile-document/signature-twilio-access-token';
    } else if (isAgreementLoggedIn && !rentReductionAgreement) {
        accessTokenUrl = '/api/tenant/twilio-access-token';
    }

    useEffect(() => {
        if (accessTokenUrl && (isDocumentLoggedIn || isAgreementLoggedIn)) {
            initializeSync(accessTokenUrl).then((client) => {
                setClient(client);
            });
        }
    }, [isDocumentLoggedIn, isAgreementLoggedIn]);

    return <TwilioSyncContext.Provider value={{ client }}>{children}</TwilioSyncContext.Provider>;
}

export function useTwilioSyncProvider() {
    const { client } = useContext(TwilioSyncContext);

    return client;
}
