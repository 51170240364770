import * as React from 'react';
import { connect } from 'react-redux';
import Preloader from 'components/Preloader';
import Button from 'common/components/Button';

class PmApproveFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false
        };
    }

    submitPmAnswers = () => {
        const data = {
            approved: true
        };
        this.props.confirmButtonHandler(data);
        this.setState({
            isOpenSignModal: true
        });
    };

    render() {
        const { isConfirmingStep, label } = this.props;
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        return (
            <footer className="column">
                <div className="footer-buttons">
                    <Button onClick={this.submitPmAnswers} disabled={isConfirmingStep}>
                        {label}
                        {preloader}
                    </Button>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => {
    return {
        isConfirmingStep: state.steps.isConfirmingStep
    };
};

export default connect(
    mapStateToProps,
    null
)(PmApproveFooter);
