import { createSelector } from 'reselect';
export const getPmQuestions = (state) => state.pmQuestions;
export const getMain = (state) => state.main;
export const getPmOwnerEnteredPaymentMethod = (state) => state.pmOwnerEnteredPaymentMethod;
export const getPmSteps = (state) => state.pmSteps.pmSteps;

export const getLease = createSelector([getMain], (main) => main.lease);

export const getPmLandlord = createSelector([getLease], (lease) => lease.pmLandlord);
export const getPmLicensee = createSelector([getLease], (lease) => lease.licensee);
export const getThirdPartyPayment = createSelector([getLease], (lease) => lease.thirdPartyPayment);
export const getPrimaryLandlordName = createSelector(
    [getLease],
    (lease) => lease?.pmLandlord?.pmPrimaryLandlordName || ''
);
export const getPmAgreementTerm = createSelector([getLease], (lease) => lease.pmAgreementTerm);
export const getPmDetailsRepairs = createSelector([getLease], (lease) => lease.pmDetailsRepairs);
export const getPmRentAndTerm = createSelector([getLease], (lease) => lease.pmRentAndTerm);
export const getPmFees = createSelector([getLease], (lease) => lease.pmFees);
export const getPmMarketingCosts = createSelector([getLease], (lease) => lease.pmMarketingCosts);
export const getPaymentMethod = createSelector([getLease], (lease) => lease.paymentMethod);
export const getPmEmergencyContacts = createSelector([getLease], (lease) => lease.pmEmergencyContacts);
export const getInsurance = createSelector([getLease], (lease) => lease.insurance);
export const getPmDuties = createSelector([getLease], (lease) => lease.pmDuties);
export const getDocuments = createSelector([getLease], (lease) => lease.documents);
export const getDocumentNamesFormatted = createSelector([getLease], (lease) => lease.documentNamesFormatted);
export const getTenantLinks = createSelector([getLease], (lease) => lease.tenantLinks);

export const getPmMaterialFactAnswers = createSelector(
    [getPmQuestions],
    (pmQuestions) => pmQuestions.pmMaterialFactAnswers
);
export const getPmOutgoingAnswers = createSelector([getPmQuestions], (pmQuestions) => pmQuestions.pmOutgoingsAnswers);
export const getPmDisclosureAnswers = createSelector(
    [getPmQuestions],
    (pmQuestions) => pmQuestions.pmDisclosureAnswers
);
export const getPmQuestionsInsuranceConfirmed = createSelector(
    [getPmQuestions],
    (pmQuestions) => pmQuestions.insuranceDetailsConfirmed
);
