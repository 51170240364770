import * as React from 'react';
import { connect } from 'react-redux';
import { confirmStep } from '../../actions/main';
import Preloader from '../../components/Preloader';

class SkipQuestionsFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false
        };
    }

    submitAnswers = () => {
        const data = {
            step: this.props.currentStep.step,
            stepName: this.props.currentStep.name,
            payload: {
                skip: true
            }
        };
        this.props.confirmStep(data);
        this.setState({
            isOpenSignModal: true
        });
    };

    render() {
        const { isConfirmingStep } = this.props;
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        return (
            <footer className="column">
                <div className="confirm-footer">
                    <button onClick={this.submitAnswers} disabled={isConfirmingStep}>
                        Skip{preloader}
                    </button>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => {
    return {
        isConfirmingStep: state.steps.isConfirmingStep
    };
};

const mapDispatchToProps = dispatch => {
    return {
        confirmStep: data => {
            dispatch(confirmStep(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SkipQuestionsFooter);
