import React from 'react';

export const ADDRESS_REQUEST = 'ADDRESS_REQUEST';
export const ADDRESS_SUCCESS = 'ADDRESS_SUCCESS';
export const ADDRESS_FAILURE = 'ADDRESS_FAILURE';

const initState = {
    isLoading: false,
    data: []
};

export default function googleAutocomplete(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case ADDRESS_REQUEST: {
            newState.isLoading = true;
            newState.data = [];
            break;
        }
        case ADDRESS_SUCCESS: {
            newState.isLoading = false;
            newState.data = action.result.data.predictions;
            break;
        }
        case ADDRESS_FAILURE: {
            newState.isLoading = false;
            newState.data = [];
            break;
        }
    }

    return newState;
}
