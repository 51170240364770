import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'common/components/Button';
import Preloader from 'components/Preloader';
import { isCorrectEmail } from '../../../utils';

const ElectronicServiceFooter = ({ isConfirmingStep, confirmButtonHandler }) => {
    const electronicServiceAnswers = useSelector(state => state.resTenQuestions.electronicServiceAnswers);
    const emailForElectronicNotices = useSelector(
        state => state.resTenQuestions.electronicServiceAnswers.emailForElectronicNotices
    );
    const givesPermissionForElectronicNotices = useSelector(
        state => state.resTenQuestions.electronicServiceAnswers.givesPermissionForElectronicNotices
    );
    let preloader = '';
    if (isConfirmingStep) {
        preloader = <Preloader />;
    }
    return (
        <div className="footer-buttons">
            <Button
                onClick={() => confirmButtonHandler(electronicServiceAnswers)}
                disabled={
                    isConfirmingStep ||
                    givesPermissionForElectronicNotices === null ||
                    (givesPermissionForElectronicNotices && !isCorrectEmail(emailForElectronicNotices))
                }
            >
                <span>Submit {preloader}</span>
            </Button>
        </div>
    );
};

export default ElectronicServiceFooter;
