import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {
    isLeaseTypePm,
    isLeaseLocationQLD,
    isLeaseLocationACT,
    getConfirmModalCustomStyle,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeCommercialLease,
    isLeaseTypeSales,
    getName,
} from '../../utils';

class ConfirmGlobalInitial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmed: false,
        };
    }

    close = () => {
        this.props.hideModal();
    };

    toggleConfirmed = () => {
        this.setState(({ confirmed }) => ({
            confirmed: !confirmed,
        }));
    };

    confirmButtonHandler = () => {
        if (this.state.confirmed) {
            this.props.confirmButtonHandler({ confirmedGlobalInitial: true });
        }
    };

    render() {
        const { isOpen, lease, user } = this.props;
        let msg = '';
        if (
            (isLeaseTypePm(lease.leaseType) || isLeaseTypeSales(lease.leaseType)) &&
            isLeaseLocationQLD(lease.location)
        ) {
            msg = `I ${user.fullName} confirm that my initials can be inserted onto the PDF in the below required areas:`;
        } else if (isLeaseTypeResidentialTenancy(lease.leaseType) && isLeaseLocationACT(lease.location)) {
            msg = `I ${user.fullName} approve for my initials to be inserted onto the PDF in the below required areas:`;
        } else if (isLeaseTypeCommercialLease(lease.leaseType)) {
            if (user.isGuarantor) {
                msg = `I ${user.fullName}, as Guarantor, confirm that my initials can be inserted onto the PDF in the below required areas:`;
            } else if (user.isCorporation) {
                msg = `I ${getName(
                    lease,
                    user
                )}, confirm that my initials can be inserted onto the PDF in the below required areas:`;
            } else {
                msg = `I ${user.fullName}, as Lessee, confirm that my initials can be inserted onto the PDF in the below required areas:`;
            }
        } else {
            msg = `I ${user.fullName} approve for my initials to be inserted on the bottom of each page of this agreement`;
        }

        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    style={getConfirmModalCustomStyle()}
                    className="confirm"
                    contentLabel="agent-modal"
                    onRequestClose={() => this.close()}
                    appElement={document.getElementById('root')}
                >
                    <div className="confirm-container">
                        <div className="confirm-title">Confirmation</div>
                        <div className="confirm-details">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    value={msg}
                                    id="confirmation"
                                    checked={this.state.confirmed}
                                    onChange={this.toggleConfirmed}
                                />
                                <label htmlFor="confirmation">
                                    <span className="checkmark" />
                                    <span className="label-text-checkbox">{msg}</span>
                                    {isLeaseTypePm(lease.leaseType) && isLeaseLocationQLD(lease.location) && (
                                        <ul>
                                            <li>Smoke Alarm Compliance</li>
                                            {lease.specialConditions.hasSpecialConditions && (
                                                <li>Additional Terms & Conditions</li>
                                            )}
                                            <li>On the bottom of each page of the agreement</li>
                                        </ul>
                                    )}
                                    {isLeaseTypeSales(lease.leaseType) && isLeaseLocationQLD(lease.location) && (
                                        <ul>
                                            <li>On the bottom of each page of the agreement</li>
                                        </ul>
                                    )}
                                    {isLeaseTypeResidentialTenancy(lease.leaseType) &&
                                        isLeaseLocationACT(lease.location) && (
                                            <ul>
                                                <li>On the bottom of each page of this agreement</li>
                                            </ul>
                                        )}
                                    {isLeaseTypeCommercialLease(lease.leaseType) && (
                                        <ul>
                                            {lease.specialConditions.hasSpecialConditions && (
                                                <li>Special Conditions</li>
                                            )}
                                            <li>On the bottom of each page of this agreement</li>
                                        </ul>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="confirm-footer">
                            <button disabled={!this.state.confirmed} onClick={this.confirmButtonHandler}>
                                I Agree
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect((state) => ({
    agency: state.main.lease.agency.details,
    user: state.main.user,
    lease: state.main.lease,
}))(ConfirmGlobalInitial);
