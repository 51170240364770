import { cloneDeep } from 'lodash';
import * as Main from 'reducers/main';
import { validateQuestionComplete, findQuestion } from 'utils';
import { isLeaseTypePm, isLeaseTypeSales } from '../utils';

export const ADDITIONAL_TERM_ANSWER_QUESTION = 'ADDITIONAL_TERM_ANSWER_QUESTION';

const initialState = {
    agentAdditionalTermsAnswers: {},
    agentAdditionalTermsAnswered: false,
};

function createAdditionalTermsQuestions(newState, action) {
    const additionalTerms = action.result.data.lease.specialConditions.clauseList;
    const leaseType = action.result.data.lease.leaseType;
    newState.agentAdditionalTermsAnswers = { questions: [] };
    let savedData;
    if (isLeaseTypePm(leaseType)) {
        savedData = action.result.data.user.pmAdditionalTerms;
    } else if (isLeaseTypeSales(leaseType)) {
        savedData = action.result.data.user.agentAdditionalTerms;
    }
    const questions = [];
    if (!savedData) {
        for (let key in additionalTerms) {
            let additionalTermObj = additionalTerms[key];
            questions.push({
                title: additionalTermObj.clause,
                questionText: additionalTermObj.comment,
                questionId: additionalTermObj._id,
                optionType: additionalTermObj.optionType,
            });
        }
        newState.agentAdditionalTermsAnswers.questions = questions;
    } else {
        if (isLeaseTypePm(leaseType)) {
            newState.agentAdditionalTermsAnswers = action.result.data.user.pmAdditionalTerms;
        } else if (isLeaseTypeSales(leaseType)) {
            newState.agentAdditionalTermsAnswers = action.result.data.user.agentAdditionalTerms;
        }
    }
    if (newState.agentAdditionalTermsAnswers.questions.length === 0) {
        newState.agentAdditionalTermsAnswered = true;
    } else {
        newState.agentAdditionalTermsAnswered = false;
    }
}

export default function commonQuestions(state = initialState, action) {
    let newState = cloneDeep(state);

    switch (action.type) {
        case ADDITIONAL_TERM_ANSWER_QUESTION: {
            const { answer, questionId } = action;
            const questionToAnswer = findQuestion(newState, questionId, newState.agentAdditionalTermsAnswers);
            questionToAnswer.answer = answer;
            questionToAnswer.detailsConfirmed = false;
            questionToAnswer.details = '';
            newState.agentAdditionalTermsAnswered = newState.agentAdditionalTermsAnswers.questions.every(
                function hasBeenAnswered(currentValue) {
                    return validateQuestionComplete(currentValue);
                }
            );

            return newState;
        }
        case Main.TENANT_LOGIN_SUCCESS:
        case Main.TENANT_REFRESH_SUCCESS: {
            if (action.result.data.lease.specialConditions) {
                createAdditionalTermsQuestions(newState, action);
            }
            return newState;
        }
    }
    return newState;
}
