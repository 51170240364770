export const INVENTORY_PDF_ACKNOWLEDGE_ANSWER = 'INVENTORY_PDF_ACKNOWLEDGE_ANSWER';

const initialState = {
    inventoryPdfAcknowledged: false,
};

export default function commercialLeaseQuestions(state = initialState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case INVENTORY_PDF_ACKNOWLEDGE_ANSWER: {
            newState.inventoryPdfAcknowledged = action.answer;
            return newState;
        }
        default: {
            return newState;
        }
    }
}
