import * as React from 'react';
import { connect } from 'react-redux';
import Button from 'common/components/Button';
import Sign from 'components/modal/Sign';
import Preloader from 'components/Preloader';
import { confirmStep } from 'actions/main';
import { getStepViaName } from 'utils';

class MaterialFactsFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            showModalSign: false,
            disablePageSigningButton: true
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (
            newProps.materialFacts &&
            (newProps.materialFacts.hasMaterialFacts || newProps.materialFacts.hasOtherDisclosures)
        ) {
            if (getStepViaName('materialFact', newProps.steps).disablePageSigning === false) {
                this.setState({
                    disablePageSigningButton: getStepViaName('materialFact', newProps.steps).disablePageSigning
                });
            }
        } else {
            this.setState({
                disablePageSigningButton: false
            });
            if (!newProps.isConfirmingStep) {
                this.submitSkipStep();
            }
        }
    }
    hideModal() {
        this.setState({
            showModalSign: false
        });
    }

    showModal() {
        this.setState({
            showModalSign: true
        });
    }

    submitSkipStep = () => {
        const data = {
            step: this.props.currentStep.step,
            stepName: this.props.currentStep.name,
            payload: {
                skip: true
            }
        };
        this.props.confirmStep(data);
    };

    initMaterialFactsInitial(payload) {
        const data = {
            materialFactsInitial: payload.signature
        };
        this.props.confirmButtonHandler(data);
        this.hideModal();
    }

    render() {
        const { isConfirmingStep } = this.props;
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        return (
            <div className="footer-buttons">
                <Button
                    onClick={() => this.showModal()}
                    signIcon={true}
                    className="red"
                    disabled={isConfirmingStep || this.state.disablePageSigningButton}
                >
                    <span>Sign this {preloader}</span>
                </Button>
                <Sign
                    isOpen={this.state.showModalSign}
                    confirmButtonHandler={payload => this.initMaterialFactsInitial(payload)}
                    isPdf={false}
                    hideModal={() => this.hideModal()}
                    title="Sign clause"
                    subtitle="Material Facts/Other Disclosures"
                    isSignatureModal={true}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        isConfirmingStep: state.steps.isConfirmingStep,
        lease: state.main.lease,
        materialFacts: state.main.lease.materialFacts,
        steps: state.main.steps
    }),
    { confirmStep }
)(MaterialFactsFooter);
