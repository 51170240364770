import React from 'react';

/**
 * This hook can be used when we want to get the height of the mobile window EXCLUDING browser UI
 * @deprecated Use useViewportHeight instead, which also adds a '--viewport-height' CSS variable
 */
const useWindowHeight = () => {
    const [windowHeight, setWindowHeight] = React.useState(null);

    React.useEffect(() => {
        const updateWindowHeight = () => {
            setWindowHeight(window.innerHeight);
        };

        updateWindowHeight();

        window.addEventListener('resize', updateWindowHeight);

        return () => {
            window.removeEventListener('resize', updateWindowHeight);
        };
    }, []);

    return windowHeight;
};

export default useWindowHeight;
