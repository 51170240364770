import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { getFormError } from '../../util/final-form-utils';
import PhoneInput from 'react-phone-input-2';
import { startsWith } from 'lodash';

import cx from 'classnames';
import styles from './MobileInput.module.scss';
import 'react-phone-input-2/lib/style.css';

type MobileInputProps = {
    name: string;
    label?: string;
    required?: boolean;
    validateOnTouch?: boolean;
    disabled?: boolean;
    className?: string;
    containerClassName?: string;
};

type MobileInputComponentProps = FieldRenderProps<string, HTMLElement> & MobileInputProps;

export const formatPhoneNumber = (formattedValue: string) => {
    let newFormattedValue = formattedValue;
    if (formattedValue.startsWith('+61')) {
        // Don't allow entering 0 as the first character
        if (formattedValue[4] && formattedValue[4] === '0') {
            if (formattedValue.length === 5) {
                newFormattedValue = formattedValue.substr(0, 4);
            } else {
                newFormattedValue = '+61 ' + formattedValue.substr(5);
            }
            // this means they pasted in the international format like: +61411595337
        } else if (formattedValue.startsWith('+61 61')) {
            newFormattedValue = '+61' + formattedValue.substr(6);
        }
        // Don't allow entering more than 11 digits
        if (newFormattedValue.replace(/[\D]/g, '').length > 11) {
            newFormattedValue = newFormattedValue.slice(0, -1);
        }
    } else if (formattedValue.startsWith('+0')) {
        // This means they removed everything and pasted 0411595337;
        newFormattedValue = '+61' + newFormattedValue.substr(2);
    }

    // separate new value in 3 characters groups with space
    if (newFormattedValue.startsWith('+61')) {
        return newFormattedValue
            .replace(/\s/g, '')
            .replace(/(.{3})/g, '$1 ')
            .replace(/(^\s+|\s+$)/, '');
    } else {
        if (formattedValue === '+') {
            return '';
        } else {
            return newFormattedValue;
        }
    }
};

const MobileInputComponent: React.FC<MobileInputComponentProps> = ({
    input,
    meta,
    label,
    required,
    disabled,
    className,
    containerClassName,
    validateOnTouch = true,
}) => {
    const error = getFormError(meta, validateOnTouch);
    return (
        <div className={cx(styles.container, containerClassName, { [styles.withError]: !!error })}>
            {label && (
                <label className={styles.label} htmlFor={input.name}>
                    {label + (required ? '*' : '')}
                </label>
            )}
            <div className={cx(styles.inputContainer, className)}>
                <PhoneInput
                    country={'au'}
                    masks={{ au: '... ... ...' }}
                    placeholder="+61 400 000 000"
                    countryCodeEditable={true}
                    disableDropdown={false}
                    disabled={disabled}
                    value={input.value}
                    copyNumbersOnly={true}
                    enableLongNumbers={true}
                    isValid={(inputNumber: string, _c: object, countries: { dialCode?: string }[]) => {
                        return countries.some((country) => {
                            return (
                                startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber)
                            );
                        });
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        // this is used to re render final form if user try to enter more 0 as first character
                        if (e.currentTarget.value.startsWith('+61') && e.currentTarget.value.length === 15) {
                            input.onChange(`${e.currentTarget.value} `);
                        }
                    }}
                    onChange={(value, country, e, formattedValue) => {
                        input.onChange(formatPhoneNumber(formattedValue));
                    }}
                    inputClass={styles.input}
                    containerClass={cx(styles.container, { disabled: disabled })}
                    dropdownClass={styles.dropdown}
                    buttonClass={styles.button}
                />
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

const MobileInput: React.FC<MobileInputProps> = ({ ...props }) => {
    return <Field component={MobileInputComponent} {...props} />;
};

export { MobileInput };
