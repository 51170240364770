import React, { useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { getConfirmModalCustomStyle } from 'utils';
import { getLease, getAgencyDetails } from 'selectors/lease';
import { getUser } from 'selectors/main';

export default function ConfirmIdentity({ isOpen, hideModal, confirmButtonHandler }) {
    const agency = useSelector(getAgencyDetails);
    const user = useSelector(getUser);
    const lease = useSelector(getLease);

    const [confirmed, setConfirmed] = useState(false);

    function toggleConfirmed() {
        setConfirmed(v => !v);
    }

    function onAgreeClicked() {
        if (confirmed) {
            confirmButtonHandler({ confirmedIdentity: true });
        }
    }

    let message = `I confirm I am ${user.fullName} as identified by documents provided to ${
        agency.agencyName
    }. This signature is my own, and I also confirm I agree to sign my ${
        lease.leaseType
    } Agreement in this electronic format.`;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={getConfirmModalCustomStyle()}
                className="confirm"
                contentLabel="agent-modal"
                onRequestClose={hideModal}
                appElement={document.getElementById('root')}
            >
                <div className="confirm-container">
                    <div className="confirm-title">Confirmation</div>
                    <div className="confirm-details">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                value={message}
                                id="confirmation"
                                checked={confirmed}
                                onChange={toggleConfirmed}
                            />
                            <label htmlFor="confirmation">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">{message}</span>
                            </label>
                        </div>
                    </div>
                    <div className="confirm-footer">
                        <button disabled={!confirmed} onClick={onAgreeClicked}>
                            I Agree
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
