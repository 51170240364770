export const DISCLOSURE_LOGIN_REQUEST = 'DISCLOSURE_LOGIN_REQUEST';
export const DISCLOSURE_LOGIN_SUCCESS = 'DISCLOSURE_LOGIN_SUCCESS';
export const DISCLOSURE_LOGIN_FAILURE = 'DISCLOSURE_LOGIN_FAILURE';

export const DISCLOSURE_CONFIRM_REQUEST = 'DISCLOSURE_CONFIRM_REQUEST';
export const DISCLOSURE_CONFIRM_SUCCESS = 'DISCLOSURE_CONFIRM_SUCCESS';
export const DISCLOSURE_CONFIRM_FAILURE = 'DISCLOSURE_CONFIRM_FAILURE';

const initState = {
    isLoggedIn: false,
    user: null,
    invite: null,
    isTokenIdShift: false,
    disclosureDocument: null
};

export default function disclosure(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case DISCLOSURE_LOGIN_REQUEST: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = false;
            newState.disclosureDocument = null;
            break;
        }
        case DISCLOSURE_LOGIN_SUCCESS: {
            newState.smsToken = action.token.mobileToken;
            newState.refreshToken = action.result.data.refreshToken;
            newState.isLoggedIn = true;
            newState.isTokenIdShift = true;
            newState.errorMessage = '';
            newState.disclosureDocument = action.result.data.disclosureDocument;
            break;
        }
        case DISCLOSURE_LOGIN_FAILURE: {
            newState.isLoggedIn = false;
            newState.isTokenIdShift = true;
            newState.errorMessage = action.error.response.data.error;
            break;
        }
    }

    return newState;
}
