import 'sass/changeRequestModal.scss';

import * as main from 'actions/main';

import React, { Component } from 'react';

import { connect } from 'react-redux';

class ModalRequestContent extends Component {
    componentDidMount() {
        const { changeRequestList, step } = this.props;
        let { changeRequest } = this.props;

        changeRequestList.forEach(item => {
            if (item.leaseAgreementStep === step) {
                changeRequest.message = item.requestMessage;
                changeRequest.readOnly = true;
            }
        });
    }

    render() {
        const { changeRequest, setRequestMessage, close } = this.props;
        return (
            <div className="change-request">
                <div className="change-request-header">
                    <button className="button-cancel" onClick={close} />
                    {!changeRequest.readOnly && <h3>Request change</h3>}
                    {changeRequest.readOnly && <h3>Requested change</h3>}
                    {!changeRequest.readOnly && (
                        <button
                            className="button-send"
                            disabled={!changeRequest.message}
                            onClick={this.sendRequest.bind(this)}
                        >
                            Send
                        </button>
                    )}
                </div>
                <div className="divider" />
                <div className="input-wrapper">
                    <textarea
                        placeholder="What's wrong?"
                        readOnly={!!changeRequest.readOnly}
                        value={changeRequest.message}
                        onChange={e => setRequestMessage(e.target.value)}
                    />
                </div>
            </div>
        );
    }

    sendRequest() {
        const { step, rejectStep, changeRequest, token, refreshToken } = this.props;

        rejectStep({
            step: step,
            payload: { requestMessage: changeRequest.message, token: token, refreshToken: refreshToken }
        });

        this.close();
    }

    close() {
        this.props.close();
        this.props.setRequestMessage(event.target.value);
    }
}

export default connect(
    state => ({
        lease: state.main.lease,
        changeRequest: state.changeRequest,
        changeRequestList: state.steps.changeRequestList
    }),
    {
        setRequestMessage: main.setRequestMessage,
        rejectStep: main.rejectStep
    }
)(ModalRequestContent);
