import * as React from 'react';
import { connect } from 'react-redux';
import { confirmStep } from 'actions/main';
import * as actions from 'actions/main';
import Sign from 'components/modal/Sign';
import Preloader from 'components/Preloader';
import ConfirmIdentity from 'components/modal/propertyManagement/ConfirmIdentity';

import { checkIfChangeRequestsAreResolved, isLeaseTypeResidentialTenancy } from 'utils';

class PmSigningFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false
        };
    }

    confirmSignatureHandler(payload) {
        const data = {
            step: this.props.currentStep.step,
            stepName: this.props.currentStep.name,
            signature: payload.signature
        };
        this.props.confirmButtonHandler(data);
        this.setState({
            isOpenConfirmModal: true
        });
    }

    confirmIdentityHandler(payload) {
        const SUB_STEP = '.1';
        let data;
        if (isLeaseTypeResidentialTenancy(this.props.lease.leaseType)) {
            data = {
                step: this.props.currentStep.step,
                stepName: this.props.currentStep.name,
                payload: {
                    subStepField: 'confirmId',
                    ...payload
                }
            };
        } else {
            data = {
                step: parseFloat(this.props.currentStep.step + SUB_STEP),
                stepName: this.props.currentStep.name,
                payload: {
                    subStepField: 'confirmId',
                    ...payload
                }
            };
        }
        this.props.confirmStep(data);
        this.setState({
            isOpenSignModal: true
        });
    }

    openConfirmModal = () => {
        this.setState({
            isOpenConfirmModal: true
        });
    };

    closeConfirmModal = () => {
        this.setState({
            isOpenConfirmModal: false
        });
    };

    closeSignModal = () => {
        this.setState({
            isOpenSignModal: false
        });
    };

    componentDidMount() {
        if (!this.props.changeRequestList.length || checkIfChangeRequestsAreResolved(this.props.changeRequestList)) {
            this.openConfirmModal();
        }
    }

    render() {
        const { isConfirmingStep } = this.props;
        const { isOpenSignModal, isOpenConfirmModal } = this.state;
        let title = '';
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        if (this.props.user.middleName) {
            title =
                this.props.user.firstName +
                ' ' +
                this.props.user.middleName +
                ' ' +
                this.props.user.secondName +
                "'s signature";
        } else if (this.props.user.firstName) {
            title = this.props.user.firstName + ' ' + this.props.user.secondName + "'s signature";
        } else if (this.props.user.corporationName) {
            title = this.props.user.corporationName + "'s signature";
        }

        return (
            <footer className="column">
                <Sign
                    isPdf
                    title={title}
                    isOpen={isOpenSignModal}
                    hideModal={this.closeSignModal}
                    confirmButtonHandler={payload => this.confirmSignatureHandler(payload)}
                    pdfCallback={this.props.sendPDFCopy}
                    showSuccessAlert={this.props.showSuccessAlert}
                    closeSuccessAlert={this.props.closeSuccessAlert}
                    isSignatureModal={true}
                />
                <ConfirmIdentity
                    isOpen={isOpenConfirmModal}
                    hideModal={this.closeConfirmModal}
                    confirmButtonHandler={payload => this.confirmIdentityHandler(payload)}
                />
            </footer>
        );
    }
}

export default connect(
    state => ({
        user: state.main.user,
        agency: state.main.lease.agency.details,
        lease: state.main.lease,
        steps: state.steps.steps,
        changeRequestList: state.steps.changeRequestList,
        showSuccessAlert: state.main.showSuccessAlert,
        location: state.main.lease.location,
        isConfirmingStep: state.steps.isConfirmingStep
    }),
    {
        sendPDFCopy: actions.sendPDFCopy,
        closeSuccessAlert: actions.closeSuccessAlert,
        confirmStep
    }
)(PmSigningFooter);
