import { useDispatch, useSelector } from 'react-redux';

import { useDocumentProgressUpdate } from '../hooks/useDocumentProgressUpdate.js';
import { getAgency } from '../selectors/lease/index.js';
import { getUser } from '../selectors/main/index.js';
import { SigningStatus, UserType } from '../types.js';
import { updateDesktopSigningStatus } from '../actions/main.js';

const TwilioSyncUpdates = () => {
    const dispatch = useDispatch();

    const agency = useSelector(getAgency);
    const user: UserType = useSelector(getUser);

    useDocumentProgressUpdate(
        agency,
        (item: { data: { _user: string; desktopSigningStatus: SigningStatus; selectedCharity: string } }) => {
            if (item.data._user === user.id) {
                dispatch(updateDesktopSigningStatus(item.data.desktopSigningStatus));
            }
        }
    );

    return null;
};

export default TwilioSyncUpdates;