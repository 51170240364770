import { defaultAxiosInstance } from './index';
import * as Main from './../reducers/main';
import * as Map from './../reducers/map';
import * as Address from './../reducers/address';
import * as Step from './../reducers/steps';
import * as SalesSteps from './../reducers/salesSteps';
import * as PmSteps from './../reducers/pmSteps';
import * as changeRequest from './../reducers/changeRequest';

export const googleApiKey = `&key=${MY_API_KEY}`;

/**
 * Get lease and user info by token from server
 * @param {string} token - unique token
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function mobileLogin(token) {
    return {
        types: [Main.TENANT_LOGIN_REQUEST, Main.TENANT_LOGIN_SUCCESS, Main.TENANT_LOGIN_FAILURE],
        promise: defaultAxiosInstance.post(`/api/tenant/login`, token),
        token,
    };
}

/**
 * Get statement,lease and user info by token from server
 * @param {string} token - unique token
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function mobileLoginForLandlordStatement(token) {
    return {
        types: [Main.STATEMENT_LOGIN_REQUEST, Main.STATEMENT_LOGIN_SUCCESS, Main.STATEMENT_LOGIN_FAILURE],
        promise: defaultAxiosInstance.post(`/api/landlord/statement`, token),
        token,
    };
}

/**
 * Get statement,lease and user info by token from server
 * @param {string} token - unique token
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function mobileLoginForRentReduction(token) {
    return {
        types: [
            Main.RENT_REDUCTION_LOGIN_REQUEST,
            Main.RENT_REDUCTION_LOGIN_SUCCESS,
            Main.RENT_REDUCTION_LOGIN_FAILURE,
        ],
        promise: defaultAxiosInstance.post(`/api/landlord/rent-reduction-login`, token),
        token,
    };
}

/**
 * @param {string} data - signature hash
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function sendSignature(data) {
    return {
        types: [Main.TENANT_SIGNATURE_REQUEST, Main.TENANT_SIGNATURE_SUCCESS, Main.TENANT_SIGNATURE_FAILURE],
        promise: defaultAxiosInstance.post(`/api/tenant/sign`, data),
    };
}

export function getLeaseAgreementInformation() {
    return {
        types: [Main.TENANT_AGREEMENT_REQUEST, Main.TENANT_AGREEMENT_SUCCESS, Main.TENANT_AGREEMENT_FAILURE],
        promise: defaultAxiosInstance.get(`/api/tenant/lease-agreement`),
    };
}

export function getSalesAgreementInformation(location, saleMethod) {
    return {
        types: [Main.SALES_AGREEMENT_REQUEST, Main.SALES_AGREEMENT_SUCCESS, Main.SALES_AGREEMENT_FAILURE],
        promise: defaultAxiosInstance.get(`/api/tenant/sales-agreement`, {
            params: {
                location: location.toUpperCase(),
                saleMethod: saleMethod,
            },
        }),
    };
}

export function getPmAgreementInformation(location) {
    return {
        types: [Main.PM_AGREEMENT_REQUEST, Main.PM_AGREEMENT_SUCCESS, Main.PM_AGREEMENT_FAILURE],
        promise: defaultAxiosInstance.get(`/api/tenant/pm-agreement`, {
            params: {
                location: location.toUpperCase(),
            },
        }),
    };
}

export function getCommercialLeaseAgreementInformation() {
    return {
        types: [Main.TENANT_AGREEMENT_REQUEST, Main.TENANT_AGREEMENT_SUCCESS, Main.TENANT_AGREEMENT_FAILURE],
        promise: defaultAxiosInstance.get(`/api/tenant/commercial-lease-agreement`),
    };
}

export function getFullPmAgreementInformation(location) {
    return {
        types: [Main.FULL_PM_AGREEMENT_REQUEST, Main.FULL_PM_AGREEMENT_SUCCESS, Main.FULL_PM_AGREEMENT_FAILURE],
        promise: defaultAxiosInstance.get(`/api/tenant/pm-agreement-full`, {
            params: {
                location: location.toUpperCase(),
            },
        }),
    };
}

/**
 * Set current step as confirmed.
 * @param {{step: number, payload: object}} data - data object
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function confirmStep(data) {
    return {
        data,
        types: [Step.STEP_CONFIRM_REQUEST, Step.STEP_CONFIRM_SUCCESS, Step.STEP_CONFIRM_FAILURE],
        promise: defaultAxiosInstance.post(`/api/tenant/accept`, data),
    };
}

export function confirmRentReduction(data) {
    return {
        types: [
            Main.CONFIRM_RENT_REDUCTION_REQUEST,
            Main.CONFIRM_RENT_REDUCTION_SUCCESS,
            Main.CONFIRM_RENT_REDUCTION_FAILURE,
        ],
        promise: defaultAxiosInstance.post(`/api/tenant/confirm-rent-reduction`, data),
        data,
    };
}

/**
 * Set current step as confirmed with a promise.
 * @param data
 * @returns {function(*): AxiosPromise}
 */
export function confirmStepPromise(data) {
    return (dispatch) => {
        dispatch({
            type: Step.STEP_CONFIRM_REQUEST,
        });

        return defaultAxiosInstance
            .post('/api/tenant/accept', data)
            .then((result) => {
                dispatch({
                    type: Step.STEP_CONFIRM_SUCCESS,
                    result,
                });
                return result;
            })
            .catch((error) => {
                dispatch({
                    type: Step.STEP_CONFIRM_FAILURE,
                    error,
                });
                throw error;
            });
    };
}

export function addContactPerson(data) {
    return {
        types: [Main.ADD_CONTACT_PERSON_REQUEST, Main.ADD_CONTACT_PERSON_SUCCESS, Main.ADD_CONTACT_PERSON_FAILURE],
        promise: defaultAxiosInstance.post(`/api/tenant/contact-person`, data),
    };
}

/**
 * Set current step as declined.
 * @param {{step: number, payload: object}} data - data object
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function rejectStep(data) {
    return {
        types: [Step.STEP_REJECT_REQUEST, Step.STEP_REJECT_SUCCESS, Step.STEP_REJECT_FAILURE],
        promise: defaultAxiosInstance.post(`/api/tenant/reject`, data),
    };
}

/**
 * Set step as current
 * @param {{url: string, name:string, status: string}} step - step by which the transition occurs
 * @returns {{type, step: *}}
 */
export function setCurrentStep(step, lease) {
    let stepAction = '';
    if (lease) {
        switch (lease.leaseType) {
            case 'Sales':
                stepAction = {
                    type: SalesSteps.SET_CURRENT_SALES_STEP,
                    step,
                };
                break;
            case 'Property Management':
                stepAction = {
                    type: PmSteps.SET_CURRENT_PM_STEP,
                    step,
                };
                break;
            default:
                stepAction = {
                    type: Step.SET_CURRENT_STEP,
                    step,
                };
        }
    }
    return stepAction;
}

/**
 * Get coordinates by address string from google geo-coder.
 * @param {string} address - address of searching coordinates.
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function getCoordinates(address) {
    return {
        types: [Map.LOCATION_REQUEST, Map.LOCATION_SUCCESS, Map.LOCATION_FAILURE],
        promise: defaultAxiosInstance.post('/api/geocode', { address, googleApiKey }),
    };
}

/**
 * Get address for the auto-completition from google API.
 * @param {string} string - part of searching address.
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function getAddresses(string) {
    return {
        types: [Address.ADDRESS_REQUEST, Address.ADDRESS_SUCCESS, Address.ADDRESS_FAILURE],
        promise: defaultAxiosInstance.post('/api/search-address', {
            address: string,
            googleApiKey: googleApiKey,
            placeType: 'regions',
        }),
    };
}

/**
 * Changing message of request.
 * @param {string} requestMessage - message of request
 * @returns {{type: *, requestMessage: *}}
 */
export function setRequestMessage(requestMessage) {
    return {
        type: changeRequest.SET_REQUEST_MESSAGE,
        requestMessage,
    };
}

/**
 * Getting address by latitude and longitude.
 * @param {number} latitude
 * @param {number} longitude
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function getAddressByCoordinates(latitude, longitude) {
    return {
        types: [
            Main.GET_ADDRESS_BY_COORDINATES_REQUEST,
            Main.GET_ADDRESS_BY_COORDINATES_SUCCESS,
            Main.GET_ADDRESS_BY_COORDINATES_FAIL,
        ],
        promise: defaultAxiosInstance.post('/api/tenant/coordinates', { latitude: latitude, longitude: longitude }),
    };
}

/**
 * Set current location address.
 * @param {string} address - current location address
 * @returns {{type, address: *}}
 */
export function setLocation(address) {
    return {
        type: Main.SET_LOCATION,
        address,
    };
}

/**
 * Send PDF copy of lease agreement to tenant email for review.
 *
 * @return {object}}
 */
export function sendPDFCopy() {
    return {
        types: [Main.SEND_PDF_COPY_REQUEST, Main.SEND_PDF_COPY_SUCCESS, Main.SEND_PDF_COPY_FAIL],
        promise: defaultAxiosInstance.get('/api/tenant/email-pdf-copy'),
    };
}

/**
 * Send PDF copy of rent reduction agreement to tenant email for review.
 * @param {string} token - unique token
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function sendRentReductionPDFCopy(token) {
    return {
        types: [Main.SEND_PDF_COPY_REQUEST, Main.SEND_PDF_COPY_SUCCESS, Main.SEND_PDF_COPY_FAIL],
        promise: defaultAxiosInstance.post(`/api/tenant/rent-reduction/email-pdf-copy`, { token }),
        token,
    };
}

export function confirmViewLandLordInformationStatement(token) {
    return {
        types: [Main.STATEMENT_CONFIRM_REQUEST, Main.STATEMENT_CONFIRM_SUCCESS, Main.STATEMENT_CONFIRM_FAILURE],
        promise: defaultAxiosInstance.post(`/api/landlord/statement-view`, token),
        token,
    };
}

export function closeSuccessAlert() {
    return {
        type: Main.CLOSE_SUCCESS_ALERT,
    };
}

export function closePayloadAlert() {
    return {
        type: Main.CLOSE_PAYLOAD_ALERT,
    };
}

export function disablePageSigning(stepName, value) {
    return {
        type: Main.DISABLE_PAGE_SIGNING,
        payload: {
            stepName,
            value,
        },
    };
}

export function submitConnections(data) {
    return {
        types: [Step.SUBMIT_CONNECTIONS_REQUEST, Step.SUBMIT_CONNECTIONS_SUCCESS, Step.SUBMIT_CONNECTIONS_FAIL],
        promise: defaultAxiosInstance.post('/api/tenant/connections', data),
    };
}

export function saveCharity(data) {
    return {
        types: [Step.SUBMIT_CHARITY_REQUEST, Step.SUBMIT_CHARITY_SUCCESS, Step.SUBMIT_CHARITY_FAIL],
        promise: defaultAxiosInstance.post('/api/tenant/charity', data),
    };
}

export function updateAgreement(agreement) {
    return {
        type: Main.UPDATE_AGREEMENT,
        agreement,
    };
}

export function setErrorMessage(error) {
    return {
        type: Main.SET_ERROR_MESSAGE,
        error,
    };
}

export function setAppIsLoading(appIsLoading) {
    return {
        type: Main.APP_IS_LOADING,
        appIsLoading,
    };
}

export function setInitialWindowHeight(initialWindowHeight) {
    return {
        type: Main.SET_INITIAL_HEIGHT,
        initialWindowHeight,
    };
}

export function updateLeaseAndUser(lease, user) {
    return {
        type: Main.UPDATE_LEASE_AND_USER,
        lease,
        user,
    };
}

export function updateDesktopSigningStatus(desktopSigningStatus) {
    return {
        type: Main.UPDATE_DESKTOP_SIGNING_STATUS,
        desktopSigningStatus,
    };
}

export function updateEmergencyContact(data) {
    return {
        types: [
            Main.UPDATE_TENANT_EMERGENCY_CONTACT.REQUEST,
            Main.UPDATE_TENANT_EMERGENCY_CONTACT.SUCCESS,
            Main.UPDATE_TENANT_EMERGENCY_CONTACT.FAIL,
        ],
        promise: defaultAxiosInstance.post(`/api/tenant/emergency-contact`, data),
    };
}

export function updateOwnerDisclosure(data) {
    return {
        types: [
            Main.UPDATE_OWNER_DISCLOSURE.REQUEST,
            Main.UPDATE_OWNER_DISCLOSURE.SUCCESS,
            Main.UPDATE_OWNER_DISCLOSURE.FAIL,
        ],
        promise: defaultAxiosInstance.post(`/api/tenant/owner-disclosure`, data),
    };
}

export function skipOwnerDisclosure() {
    return {
        types: [
            Main.SKIP_OWNER_DISCLOSURE.REQUEST,
            Main.SKIP_OWNER_DISCLOSURE.SUCCESS,
            Main.SKIP_OWNER_DISCLOSURE.FAIL,
        ],
        promise: defaultAxiosInstance.post(`/api/tenant/skip-owner-disclosure`),
    };
}
