import * as Main from './main';
export const PM_SUBMIT_OWNER_ENTERED_REQUEST = 'PM_SUBMIT_OWNER_ENTERED_REQUEST';
export const PM_SUBMIT_OWNER_ENTERED_SUCCESS = 'PM_SUBMIT_OWNER_ENTERED_SUCCESS';
export const PM_SUBMIT_OWNER_ENTERED_FAILURE = 'PM_SUBMIT_OWNER_ENTERED_FAILURE';

export const PM_SKIP_ENTER_PAYMENT_DETAILS = 'PM_SKIP_ENTER_PAYMENT_DETAILS';

const initialState = {
    ownerEnteredSuccess: false,
    skipEntering: false
};

export default function pmOwnerEnteredPaymentMethod(state = initialState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case PM_SUBMIT_OWNER_ENTERED_SUCCESS: {
            newState.ownerEnteredSuccess = true;
            return newState;
        }

        case PM_SKIP_ENTER_PAYMENT_DETAILS: {
            newState.skipEntering = action.data;
            return newState;
        }
        case Main.TENANT_LOGIN_REQUEST: {
            newState.skipEntering = false;
            newState.ownerEnteredSuccess = false;
            return newState;
        }
    }
    return newState;
}
