import React from 'react';
import styles from './Card.module.scss';
import cx from 'classnames';
// Workaround for using latest framer-motion with webpack v4 - can be removed once upgrade to v5 is complete
import { motion } from 'framer-motion/dist/framer-motion';

enum CardStyles {
    DEFAULT,
    SQUARE,
}

enum CardShadow {
    DEFAULT,
    NONE,
    SMALL,
}

enum CardBorder {
    DEFAULT,
    BLACK_BORDER,
    GRAY_BORDER,
}

type CardProps = React.PropsWithChildren<{
    className?: string;
    testId?: string;
    style?: CardStyles;
    shadow?: CardShadow;
    border?: CardBorder;
    animate?: boolean;
}>;

const Card: React.FC<CardProps> = React.forwardRef(
    (
        {
            className,
            testId,
            children,
            style = CardStyles.DEFAULT,
            shadow = CardShadow.DEFAULT,
            border = CardBorder.DEFAULT,
            animate = false,
        },
        ref
    ) => {
        const ComponentToRender = animate ? motion.div : 'div';
        return (
            <ComponentToRender
                ref={ref}
                layout="size"
                className={cx(styles.card, className, {
                    [styles.square]: style === CardStyles.SQUARE,
                    [styles.noShadow]: shadow === CardShadow.NONE,
                    [styles.smallShadow]: shadow === CardShadow.SMALL,
                    [styles.blackBorder]: border === CardBorder.BLACK_BORDER,
                    [styles.grayBorder]: border === CardBorder.GRAY_BORDER,
                })}
                data-testid={testId}
            >
                {children}
            </ComponentToRender>
        );
    }
);

export { CardStyles, CardShadow, CardBorder };
export default Card;
