import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { getConfirmModalCustomStyle } from 'utils';

class ConfirmIdentityVic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmed: false,
            vicConfirmed: false,
            addSignatureToPdf: false,
            pdfDisclosure: false,
            pdfAdditional: false,
            pdfSpecial: false,
            pdfSignatures: false
        };
    }

    close = () => {
        this.props.hideModal();
    };

    toggleConfirmed = () => {
        this.setState(({ confirmed }) => ({
            confirmed: !confirmed
        }));
    };

    vicConfirmed() {
        return (
            this.state.pdfDisclosure && this.state.pdfAdditional && this.state.pdfSpecial && this.state.pdfSignatures
        );
    }

    toggleAddSignatureToPdf = event => {
        this.setState(
            {
                [event.target.id]: !this.state[event.target.id]
            },
            () => {
                if (this.vicConfirmed()) {
                    this.setState(() => ({
                        vicConfirmed: true
                    }));
                } else {
                    this.setState(() => ({
                        vicConfirmed: false
                    }));
                }
            }
        );
    };

    confirmButtonHandler = () => {
        if (this.state.confirmed) {
            this.props.confirmButtonHandler({ confirmedIdentity: true });
        }
    };

    render() {
        const { isOpen, agency, user } = this.props;
        const resTenMessage = `I confirm I am ${user.fullName} as identified by documents provided to ${
            agency.agencyName
        }. This signature is my own, and I also confirm I agree to sign my Residential Rental Agreement in this electronic format.`;

        let confirmed = this.state.confirmed;
        const vicConfirmed = this.state.vicConfirmed && confirmed;

        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    style={getConfirmModalCustomStyle()}
                    className="confirm"
                    contentLabel="agent-modal"
                    onRequestClose={() => this.close()}
                    appElement={document.getElementById('root')}
                >
                    <div className="confirm-container">
                        <div className="confirm-title">Confirmation</div>
                        <div className="confirm-details">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id="confirmation"
                                    checked={this.state.confirmed}
                                    onChange={this.toggleConfirmed}
                                />
                                <label htmlFor="confirmation">
                                    <span className="checkmark" />
                                    <span className="label-text-checkbox">{resTenMessage}</span>
                                </label>
                            </div>
                        </div>
                        {this.getVicConfirmBlock()}
                        <div className="confirm-footer">
                            <button disabled={!vicConfirmed} onClick={this.confirmButtonHandler}>
                                I Agree
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    getVicConfirmBlock() {
        return (
            <div className="confirm-details">
                <span className="label-text-checkbox">
                    I confirm that my signature can be inserted onto the PDF in the below required areas:
                </span>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        value="disclosure"
                        id="pdfDisclosure"
                        checked={this.state.pdfDisclosure}
                        onChange={event => this.toggleAddSignatureToPdf(event)}
                    />
                    <label htmlFor="pdfDisclosure">
                        <span className="checkmark" />
                        <span className="label-text-checkbox">Disclosure by renter</span>
                    </label>
                </div>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        value="additional"
                        id="pdfAdditional"
                        checked={this.state.pdfAdditional}
                        onChange={event => this.toggleAddSignatureToPdf(event)}
                    />
                    <label htmlFor="pdfAdditional">
                        <span className="checkmark" />
                        <span className="label-text-checkbox">Part E 31(1) - Additional Terms</span>
                    </label>
                </div>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        value="special"
                        id="pdfSpecial"
                        checked={this.state.pdfSpecial}
                        onChange={event => this.toggleAddSignatureToPdf(event)}
                    />
                    <label htmlFor="pdfSpecial">
                        <span className="checkmark" />
                        <span className="label-text-checkbox">Part E 31(2) - Additional Terms</span>
                    </label>
                </div>
                <div className="checkbox">
                    <input
                        type="checkbox"
                        value="signatures"
                        id="pdfSignatures"
                        checked={this.state.pdfSignatures}
                        onChange={event => this.toggleAddSignatureToPdf(event)}
                    />
                    <label htmlFor="pdfSignatures">
                        <span className="checkmark" />
                        <span className="label-text-checkbox">Part E 32 - Signatures</span>
                    </label>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    agency: state.main.lease.agency.details,
    user: state.main.user,
    lease: state.main.lease,
    leaseType: state.main.lease.leaseType,
    location: state.main.lease.location
}))(ConfirmIdentityVic);
