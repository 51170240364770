import { useDispatch } from 'react-redux';
import { addNewToast as addNewToastAction } from '../actions/toast';
import { ToastTypes } from '../components/new_designs/Toast';

const useToast = () => {
    const dispatch = useDispatch();
    const addNewToast = (message: string, type = ToastTypes.SUCCESS) => {
        dispatch(addNewToastAction({ message, type, id: new Date() }));
    };
    return {
        addNewToast,
    };
};

export default useToast;
