import React, { Component } from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { leaseStatuses } from 'config';

import '../sass/outdated-lease.scss';

const leaseSignedStatuses = [
    leaseStatuses.LEASE_STATUS_AWAITING_COMPLETION,
    leaseStatuses.LEASE_STATUS_ARCHIVED,
    leaseStatuses.LEASE_STATUS_COMPLETE
];

const expiredMessages = ['Invalid token', 'Link expired', 'Unauthorized', 'invalid signature'];
const documentMessages = "can't sign document";

class ErrorPage extends Component {
    getMessage = () => {
        if (includes(leaseSignedStatuses, this.props.leaseStatus)) {
            const people = this.props.leaseType === 'Sales' ? 'vendors' : 'tenants';
            const agreementType = this.props.leaseType === 'Sales' ? 'sales' : 'lease';
            return (
                <div>
                    <p>
                        This link is no longer valid because all {people} have completed and signed the {agreementType}{' '}
                        agreement.
                    </p>
                    <p>If this information is not correct, please contact your agent.</p>
                </div>
            );
        }

        if (this.props.leaseStatus === leaseStatuses.LEASE_STATUS_DRAFT) {
            return (
                <div>
                    <p>
                        Sorry, your agent has cancelled the signing for this this agreement. You'll need a new link to
                        continue.
                    </p>
                    <p>
                        If you do not receive a new agreement link in an SMS or email shortly, contact your agent to
                        resend the link.
                    </p>
                </div>
            );
        }

        if (this.props.errorMessage === 'Tenant not found on lease') {
            return (
                <div>
                    <p>Sorry, your agent has updated this agreement. You'll need a new link to continue.</p>
                    <p>
                        If you do not receive a new agreement link in an SMS or email shortly, contact your agent to
                        resend the link.
                    </p>
                </div>
            );
        }

        if (includes(this.props.mainErrorMessage, documentMessages)) {
            return (
                <div>
                    <p>Sorry, your agent has updated this document. You'll need a new link to continue.</p>
                    <p>
                        If you do not receive a new document link in an SMS shortly, contact your agent to resend the
                        link.
                    </p>
                </div>
            );
        }

        if (includes(expiredMessages, this.props.errorMessage)) {
            return (
                <div className="expired-message">
                    <strong>Whoops, it looks like this document has either:</strong>
                    <ol>
                        <li>
                            Had changes requested. You should receive a new link once the agent has made all change
                            requests
                        </li>
                        <li>Exceeded the deadline time for signing</li>
                        <li>Been cancelled</li>
                        <li>Or, you may have clicked on an old link</li>
                    </ol>
                    <p>Please check with the agent if you have further issues.</p>
                </div>
            );
        }
        if (includes(expiredMessages, this.props.mainErrorMessage)) {
            return (
                <div>
                    <p>
                        Looks like the page you’re trying to view doesn’t exist or the link has expired. Please check
                        the URL and try again.
                    </p>
                    <p>If that doesn't solve it please contact your agent.</p>
                </div>
            );
        }

        return (
            <div>
                <p>It looks like something has gone wrong.</p>
                <p>Please try again, and if the same error occurs, contact the person who sent you the document.</p>
            </div>
        );
    };

    getTitle = () => {
        if (includes(expiredMessages, this.props.errorMessage)) {
            return null;
        }
        if (includes(this.props.mainErrorMessage, documentMessages)) {
            return 'Outdated Document link';
        }

        // return 'Outdated lease agreement link';
        return this.props.errorMessage;
    };

    render() {
        return (
            <div className="wrap image">
                <div className="outdated-lease-screen">
                    <div className="logo logo-index">
                        <img src="/images/main_logo.svg" alt="logo" />
                    </div>

                    <div className="outdated-lease-title">
                        <h2>{this.getTitle()}</h2>
                    </div>

                    <div className="outdated-lease-message">{this.getMessage()}</div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        leaseStatus: state.steps.leaseStatus,
        errorMessage: state.steps.errorMessage,
        mainErrorMessage: state.main.errorMessage,
        leaseType: state.main.lease ? state.main.lease.leaseType : state.steps.leaseType
    }),
    {}
)(ErrorPage);
