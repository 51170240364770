import * as React from 'react';
import { connect } from 'react-redux';
import { confirmStep } from 'actions/main';
import * as actions from 'actions/main';
import Sign from 'components/modal/Sign';
import Preloader from 'components/Preloader';
import ConfirmIdentitySales from '../../components/modal/ConfirmIdentitySales';
import { checkIfChangeRequestsAreResolved } from 'utils';
import { getStepViaName, isLeaseLocationQLD } from '../../utils';
import { SALES_OWNER_SIGN_STEP } from '../../common/constants';
import Button from '../../common/components/Button';

class SalesSigningFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false,
        };
    }

    confirmSignatureHandler(payload) {
        const data = {
            signature: payload.signature,
        };
        this.props.confirmButtonHandler(data);
        this.setState({
            isOpenConfirmModal: true,
        });
    }

    confirmIdentityHandler(payload) {
        const step = getStepViaName(SALES_OWNER_SIGN_STEP, this.props.salesSteps);
        const data = {
            step: step.step,
            stepName: step.name,
            payload: {
                subStepField: 'confirm_id',
                subStepNo: step.step + 0.1,
                ...payload,
            },
        };
        this.props.confirmStep(data);
        this.setState({
            isOpenSignModal: true,
        });
    }

    openConfirmModal = () => {
        this.setState({
            isOpenConfirmModal: true,
        });
    };

    closeConfirmModal = () => {
        this.setState({
            isOpenConfirmModal: false,
        });
    };

    closeSignModal = () => {
        this.setState({
            isOpenSignModal: false,
        });
    };

    componentDidMount() {
        if (
            (!this.props.changeRequestList.length || checkIfChangeRequestsAreResolved(this.props.changeRequestList)) &&
            !isLeaseLocationQLD(this.props.location)
        ) {
            this.openConfirmModal();
        }
    }

    render() {
        const { isConfirmingStep } = this.props;
        const { isOpenSignModal, isOpenConfirmModal } = this.state;
        let title = '';
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        if (this.props.user.middleName) {
            title =
                this.props.user.firstName +
                ' ' +
                this.props.user.middleName +
                ' ' +
                this.props.user.secondName +
                "'s signature";
        } else {
            title = this.props.user.firstName + ' ' + this.props.user.secondName + "'s signature";
        }

        return (
            <footer className="column">
                {isLeaseLocationQLD(this.props.location) && (
                    <Button onClick={this.openConfirmModal} disabled={isConfirmingStep}>
                        I have read & agree to this! {preloader}
                    </Button>
                )}
                <Sign
                    isPdf
                    title={title}
                    isOpen={isOpenSignModal}
                    hideModal={this.closeSignModal}
                    confirmButtonHandler={(payload) => this.confirmSignatureHandler(payload)}
                    pdfCallback={this.props.sendPDFCopy}
                    showSuccessAlert={this.props.showSuccessAlert}
                    closeSuccessAlert={this.props.closeSuccessAlert}
                    isSignatureModal={true}
                />
                <ConfirmIdentitySales
                    isOpen={isOpenConfirmModal}
                    hideModal={this.closeConfirmModal}
                    confirmButtonHandler={(payload) => this.confirmIdentityHandler(payload)}
                />
            </footer>
        );
    }
}

export default connect(
    (state) => ({
        user: state.main.user,
        agency: state.main.lease.agency.details,
        salesSteps: state.salesSteps.salesSteps,
        changeRequestList: state.steps.changeRequestList,
        showSuccessAlert: state.main.showSuccessAlert,
        location: state.main.lease.location,
        isConfirmingStep: state.steps.isConfirmingStep,
    }),
    {
        sendPDFCopy: actions.sendPDFCopy,
        closeSuccessAlert: actions.closeSuccessAlert,
        confirmStep,
    }
)(SalesSigningFooter);
