import { OPEN_CHANGE_REQUEST_MODAL, CLOSE_CHANGE_REQUEST_MODAL } from '../reducers/changeRequest.js';

export function openChangeRequestModal(step: number) {
    return {
        type: OPEN_CHANGE_REQUEST_MODAL,
        step,
    };
}

export function closeChangeRequestModal() {
    return {
        type: CLOSE_CHANGE_REQUEST_MODAL,
    };
}
