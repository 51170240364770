import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'common/components/Button';
import Preloader from 'components/Preloader';

const OwnerCorporationFooter = ({ isConfirmingStep, confirmButtonHandler }) => {
    const ownerCorporationAnswer = useSelector(state => state.resTenQuestions.ownerCorporationAnswer);
    let preloader = '';
    if (isConfirmingStep) {
        preloader = <Preloader />;
    }
    return (
        <div className="footer-buttons">
            <Button
                onClick={() => confirmButtonHandler({ ownerCorporationAnswer })}
                disabled={isConfirmingStep || !ownerCorporationAnswer}
            >
                <span>Continue {preloader}</span>
            </Button>
        </div>
    );
};

export default OwnerCorporationFooter;
