import { find, map } from 'lodash';
import { format } from 'date-fns';
import parse from 'html-react-parser';
import { TimelineMax } from 'gsap';
import {
    PERIOD_FORTNIGHTLY,
    PERIOD_MONTHLY,
    QLD_STATE,
    VIC_STATE,
    NSW_STATE,
    ACT_STATE,
    NT_STATE,
    SA_STATE,
    TAS_STATE,
    WA_STATE,
    TIMEZONE_QLD_STATE,
    TIMEZONE_VIC_STATE,
    TIMEZONE_NSW_STATE,
    TIMEZONE_ACT_STATE,
    TIMEZONE_NT_STATE,
    TIMEZONE_SA_STATE,
    TIMEZONE_TAS_STATE,
    TIMEZONE_WA_STATE,
    MOBILE_WIDTH,
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
} from 'config';
import { isMobile } from 'mobile-device-detect';

import { utcToZonedTime } from 'date-fns-tz';
import { AGREEMENT_STEP, PM_AGREEMENT_STEP, SALES_AGREEMENT_STEP } from './common/constants';

const formatPhoneForDisplayInternational = (phoneNumber) => {
    if (phoneNumber) {
        return phoneNumber.replace(/(\+\d\d)\s*(\d\d\d)\s*(\d\d\d)\s*(\d\d\d)/, '$1 $2 $3 $4');
    }
};

const checkIfChangeRequestsAreResolved = (changeRequestList) => {
    let resolved = true;
    map(changeRequestList, (value) => {
        if (value.status !== 'resolved') {
            resolved = false;
            return false;
        }
    });

    return resolved;
};

/**
 * Calculate the rent based on the weekly rent amount and a given time period
 *
 * @param weeklyRent
 * @param forPeriod - weekly|fortnightly|monthly
 * @returns {*}
 */
const calculateRent = (weeklyRent, forPeriod) => {
    switch (forPeriod) {
        case PERIOD_MONTHLY:
            return parseFloat(((weeklyRent / 7) * 365) / 12).toFixed(2);
        case PERIOD_FORTNIGHTLY:
            return parseFloat(weeklyRent * 2).toFixed(2);
        default:
            return parseFloat(weeklyRent).toFixed(2);
    }
};

export const formatCurrency = (value, withFractions = false) => {
    if (value) {
        let minimumFractionDigits = 0;
        // if there are decimals we always want to show both digits
        // Examples:
        // 26.4 -> 26.40
        // 132 -> 132
        if (withFractions && value % 1) {
            minimumFractionDigits = 2;
        }
        return value.toLocaleString(undefined, {
            minimumFractionDigits,
            maximumFractionDigits: 2,
        });
    } else {
        return '0';
    }
};

export const formatCurrencyWithSeparator = (value) => {
    if (value === 'NIL') {
        return value;
    }
    if (value) {
        return `${value.startsWith('$') ? '' : '$'}${value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
    } else {
        return '$0.00';
    }
};

const getStepViaName = (name, steps) => {
    return find(steps, (step) => {
        return step.name === name;
    });
};

const getDynamicAgreementStep = (state) => {
    let steps = state.steps.steps;
    let name = AGREEMENT_STEP;
    if (isLeaseTypePm(state.main.lease.leaseType)) {
        steps = state.pmSteps.pmSteps;
        name = PM_AGREEMENT_STEP;
    } else if (isLeaseTypeSales(state.main.lease.leaseType)) {
        steps = state.salesSteps.salesSteps;
        name = SALES_AGREEMENT_STEP;
    }
    return find(steps, (step) => {
        return step.name === name;
    });
};

const noOfEnabledMaterialFacts = (materialFacts) => {
    let count = 0;
    Object.keys(materialFacts).map((key) => {
        if (key !== 'other' && materialFacts[key].enabled) {
            count++;
        } else if (key === 'other') {
            materialFacts[key].map(() => {
                count++;
            });
        }
    });
    return count;
};
const noOfEnabledOtherDisclosures = (materialFacts) => {
    let count = 0;
    Object.keys(materialFacts.otherDisclosures).map((key) => {
        if (materialFacts.otherDisclosures[key].enabled) {
            count++;
        }
    });
    return count;
};

export function isLeaseTypeSales(leaseType) {
    if (leaseType === LEASE_TYPE_SALES) {
        return true;
    }
    return false;
}
export function isLeaseTypePm(leaseType) {
    if (leaseType === LEASE_TYPE_PROPERTY_MANAGEMENT) {
        return true;
    }
    return false;
}
export function isLeaseTypeResidentialTenancy(leaseType) {
    if (leaseType === LEASE_TYPE_RESIDENTIAL) {
        return true;
    }
    return false;
}
export function isLeaseTypeCommercialLease(leaseType) {
    if (leaseType === LEASE_TYPE_COMMERCIAL_LEASE) {
        return true;
    }
    return false;
}
export function getAgreementItem(agreementObject, mainItem, subItem, contentItem) {
    if (contentItem) {
        return parse(
            agreementObject.standardTerms.h1[0].h2[0].content[mainItem - 1].content[subItem - 1].content[
                contentItem - 1
            ].lineItem
        );
    }
    return parse(agreementObject.standardTerms.h1[0].h2[0].content[mainItem - 1].content[subItem - 1].lineItem);
}

export const STEP_STATE_STATUS_CONFIRMED = 'confirmed';
export const LABEL_YES = 'Yes';

export function isLeaseLocationNSW(location) {
    if (location === NSW_STATE) {
        return true;
    }
    return false;
}
export function isLeaseLocationQLD(location) {
    if (location === QLD_STATE) {
        return true;
    }
    return false;
}
export function isLeaseLocationVIC(location) {
    if (location === VIC_STATE) {
        return true;
    }
    return false;
}
export function isLeaseLocationACT(location) {
    if (location === ACT_STATE) {
        return true;
    }
    return false;
}

export function isLeaseLocationSA(location) {
    if (location === SA_STATE) {
        return true;
    }
    return false;
}

export function hasBeenFilledIn(field) {
    return field !== '$';
}

export const getTimeZoneForLocation = (location) => {
    if (location === QLD_STATE) {
        return TIMEZONE_QLD_STATE;
    } else if (location === VIC_STATE) {
        return TIMEZONE_VIC_STATE;
    } else if (location === NSW_STATE) {
        return TIMEZONE_NSW_STATE;
    } else if (location === ACT_STATE) {
        return TIMEZONE_ACT_STATE;
    } else if (location === NT_STATE) {
        return TIMEZONE_NT_STATE;
    } else if (location === SA_STATE) {
        return TIMEZONE_SA_STATE;
    } else if (location === TAS_STATE) {
        return TIMEZONE_TAS_STATE;
    } else if (location === WA_STATE) {
        return TIMEZONE_WA_STATE;
    }
};

export const formatDate = (date, dateFormat, timeZone) => {
    return format(utcToZonedTime(date, timeZone), dateFormat);
};

export const formatDateTime = (date, dateFormat, timeFormat) => {
    const currentDateTime = new Date(date);
    const signedTime = currentDateTime.toLocaleString('en-US', timeFormat);
    const signedDate = formatDate(currentDateTime, dateFormat);
    return `${signedTime} ${signedDate}`;
};

export const capitalize = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isCorrectEmail = (field) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(field);
};

/**
 * This web site has two types of URL patterns
 *  1. /steps/welcome (normal)
 *  2. /steps/VIC/welcome (location based)
 * Location based one and normal one.
 * This function return whether it use location in URL pattern or not
 *
 * @param leaseType
 * @param location
 * @returns {Boolean}
 */
export const isLocationBaseURL = (leaseType, location) => {
    if (isLeaseTypeResidentialTenancy(leaseType)) {
        return isLeaseLocationVIC(location) || isLeaseLocationACT(location) || isLeaseLocationSA(location);
    } else if (isLeaseTypePm(leaseType)) {
        return isLeaseLocationVIC(location) || isLeaseLocationSA(location) || isLeaseLocationQLD(location);
    } else if (isLeaseTypeCommercialLease(leaseType)) {
        return true;
    } else if (isLeaseTypeSales(leaseType)) {
        return isLeaseLocationQLD(location);
    }
    return false;
};

export const cancelMove = (e) => {
    e.preventDefault();
};

export const documentScroll = (
    isForceScrollingToButton,
    setIsForceScrollingToButton,
    setIsShake,
    initialWindowHeight
) => {
    const windowHeight = initialWindowHeight ?? window.innerHeight;
    // if we're already moving, don't try to move until we're done
    if (isForceScrollingToButton) {
        return;
    }
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    const elements = document.getElementsByClassName('toHide');
    if (elements.length) {
        const el = elements[0];
        const top = scrollTop - windowHeight + el.getBoundingClientRect().top + el.getBoundingClientRect().height + 16;
        if (scrollTop > top) {
            freezeWindow(top, setIsForceScrollingToButton, setIsShake);
        }
    }
};

export const freezeWindow = (value, setIsForceScrollingToButton, setIsShake) => {
    const timeline = new TimelineMax();

    document.addEventListener('touchmove', cancelMove);
    document.addEventListener('mousewheel', cancelMove);

    timeline.to([document.documentElement, document.body], 0.7, {
        scrollTop: value,
    });
    setIsForceScrollingToButton(true);

    setTimeout(() => {
        document.removeEventListener('touchmove', cancelMove);
        document.removeEventListener('mousewheel', cancelMove);
        setIsShake(true);
        setIsForceScrollingToButton(false);
    }, 700);

    setTimeout(() => {
        setIsShake(false);
    }, 1700);
};

export const getConfirmModalCustomStyle = (zIndex = 90) => {
    return {
        overlay: {
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
};
const formatPhoneNumber = (formattedValue) => {
    let newFormattedValue = formattedValue;
    if (formattedValue.startsWith('+61')) {
        // Don't allow entering 0 as the first character
        if (formattedValue[4] && formattedValue[4] === '0') {
            if (formattedValue.length === 5) {
                newFormattedValue = formattedValue.substr(0, 4);
            } else {
                newFormattedValue = '+61 ' + formattedValue.substr(5);
            }
            console.log(newFormattedValue);
            // this means they pasted in the international format like: +61411595337
        } else if (formattedValue.startsWith('+61 61')) {
            newFormattedValue = '+61' + formattedValue.substr(6);
        }
        // Don't allow entering more than 11 digits
        if (newFormattedValue.replace(/[\D]/g, '').length > 11) {
            newFormattedValue = newFormattedValue.slice(0, -1);
        }
    }

    // separate new value in 3 characters groups with space
    if (newFormattedValue.startsWith('+61')) {
        return newFormattedValue
            .replace(/\s/g, '')
            .replace(/(.{3})/g, '$1 ')
            .replace(/(^\s+|\s+$)/, '');
    } else {
        if (formattedValue === '+') {
            return '';
        } else {
            return newFormattedValue;
        }
    }
};

function isMobileWidth(breakPoint) {
    const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (breakPoint) {
        return isMobile || width <= breakPoint;
    }
    return isMobile || width <= MOBILE_WIDTH;
}

// Adapted from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
function isTouchScreen() {
    if ('maxTouchPoints' in navigator) {
        return navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
        return navigator.msMaxTouchPoints > 0;
    } else {
        const mQ = window.matchMedia?.('(pointer:coarse)');
        if (mQ?.media === '(pointer:coarse)') {
            return !!mQ.matches;
        } else if ('orientation' in window) {
            return true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = navigator.userAgent;
            return (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }
}

const isTabletPortraitUp = (breakPoint = '37.5rem') => window.matchMedia(`(min-width: ${breakPoint})`).matches;

function getUrlStepType(leaseType) {
    let stepType = 'steps';
    if (isLeaseTypeCommercialLease(leaseType)) {
        stepType = 'commercial-lease-steps';
    } else if (isLeaseTypeSales(leaseType)) {
        stepType = 'sales-steps';
    } else if (isLeaseTypePm(leaseType)) {
        stepType = 'pm-steps';
    }
    return stepType;
}

function findQuestion(newState, questionId, answers) {
    return find(answers.questions, function(question) {
        return question.questionId === questionId;
    });
}

function validateQuestionComplete(currentValue, checkDetails = true) {
    if (currentValue.hasOwnProperty('answer')) {
        if (!checkDetails) {
            return true;
        } else if (currentValue.detailsRequired) {
            if (currentValue.answer === true) {
                if (currentValue.details && currentValue.detailsConfirmed) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else if (currentValue.detailsRequiredOnNo) {
            if (currentValue.answer === false) {
                if (currentValue.details && currentValue.detailsConfirmed) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    } else if (currentValue.hasOwnProperty('visible')) {
        if (!currentValue.visible) {
            return true;
        }
    }
    return false;
}

function removeFileExtension(filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
}

function getName(lease, user) {
    if (user.isCorporation) {
        return `${user.personFullName}, ${user.roleDescription} of ${lease.lessee.corporationName}`;
    } else {
        return user.fullName;
    }
}

function formatUrl(url) {
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        return `https://${url}`;
    }
    return url;
}

// imported from app
function statefulActions(action) {
    return {
        REQUEST: `${action}.request`,
        SUCCESS: `${action}.success`,
        FAIL: `${action}.fail`,
    };
}

export {
    formatPhoneForDisplayInternational,
    checkIfChangeRequestsAreResolved,
    calculateRent,
    getStepViaName,
    noOfEnabledMaterialFacts,
    noOfEnabledOtherDisclosures,
    formatPhoneNumber,
    isMobileWidth,
    getDynamicAgreementStep,
    getUrlStepType,
    findQuestion,
    validateQuestionComplete,
    removeFileExtension,
    isTouchScreen,
    getName,
    formatUrl,
    isTabletPortraitUp,
    statefulActions,
};
