/* eslint-disable no-case-declarations */
import React from 'react';
import { get, has } from 'lodash';

export const STEP_CONFIRM_REQUEST = 'STEP_CONFIRM_REQUEST';
export const STEP_CONFIRM_SUCCESS = 'STEP_CONFIRM_SUCCESS';
export const STEP_CONFIRM_FAILURE = 'STEP_CONFIRM_FAILURE';

export const STEP_REJECT_SUCCESS = 'STEP_REJECT_SUCCESS';
export const STEP_REJECT_FAILURE = 'STEP_REJECT_FAILURE';

export const SET_CURRENT_PM_STEP = 'SET_CURRENT_PM_STEP';
export const SET_STEPS = 'SET_STEPS';

import * as Main from './main';
import { CLOSE_PAYLOAD_ALERT, HTTP_STATUS_UNPROCESSABLE_ENTITY } from './main';

/**
 * Available statuses of steps.
 * @type {string}
 */
export const STEP_STATE_STATUS_NONE = 'none';
export const STEP_STATE_STATUS_DECLINE = 'declined';
export const STEP_STATE_STATUS_CONFIRMED = 'confirmed';

const initState = {
    isConfirmingStep: false,
    isConfirmingRedirectPmStep: true,
    tenantSignature: '',
    changeRequestList: [],
    leaseStatus: null,
    errorMessage: '',
    showErrorPage: false,
    isRedirect: false,
    redirectStep: 0,
    pmSteps: []
};

export default function pmSteps(state = initState, action) {
    switch (action.type) {
        case STEP_CONFIRM_REQUEST:
            return {
                ...state,
                isConfirmingStep: true,
                isConfirmingRedirectPmStep: true
            };

        case STEP_CONFIRM_SUCCESS:
        case STEP_REJECT_SUCCESS:
        case Main.TENANT_LOGIN_SUCCESS:
        case Main.TENANT_REFRESH_SUCCESS: {
            let pmSteps = [...state.pmSteps];
            if (!action.result.data.isRedirect) {
                pmSteps = action.result.data.steps;
            }
            return {
                ...state,
                pmSteps: pmSteps,
                isRedirect: action.result.data.isRedirect,
                redirectStep: action.result.data.redirectStep,
                changeRequestList: action.result.data.changeRequestList,
                tenantSignature: action.result.data.user.signature,
                isConfirmingStep: false,
                isConfirmingRedirectPmStep: false
            };
        }

        case Main.TENANT_LOGIN_FAILURE:
        case Main.TENANT_REFRESH_FAILURE:
        case STEP_CONFIRM_FAILURE:
        case STEP_REJECT_FAILURE:
            let leaseStatus = null;
            let errorMessage = '';
            let showErrorPage = false;
            let showErrorModal = false;
            if (
                action.error &&
                action.error.response &&
                action.error.response.status === HTTP_STATUS_UNPROCESSABLE_ENTITY
            ) {
                showErrorModal = true;
            } else {
                showErrorPage = true;
            }

            if (has(action, 'error.response.data.leaseStatus')) {
                leaseStatus = action.error.response.data.leaseStatus;
            }

            if (has(action, 'error.response.data.error')) {
                errorMessage = action.error.response.data.error;
            }

            return {
                ...state,
                leaseStatus,
                errorMessage,
                showErrorPage,
                showErrorModal,
                isConfirmingStep: false,
                isConfirmingRedirectPmStep: true
            };

        case CLOSE_PAYLOAD_ALERT: {
            return {
                ...state,
                showErrorModal: false
            };
        }

        case SET_CURRENT_PM_STEP: {
            const pmSteps = [...state.pmSteps];

            pmSteps.map((step, index) => {
                pmSteps[index].current = step.name === action.step.name;
                if (step.name === action.step.name) {
                    pmSteps[index].visited = true;
                }
            });

            return Object.assign({}, state, {
                pmSteps: pmSteps,
                isConfirmingRedirectPmStep: true
            });
        }

        case SET_STEPS: {
            return Object.assign({}, state, {
                pmSteps: action.pmSteps
            });
        }

        default:
            return state;
    }
}
