import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { getLease, getAgencyDetails } from 'selectors/lease';
import { getConfirmModalCustomStyle, getName } from 'utils';
import { getUser } from 'selectors/main';

const ConfirmIdentity = ({ hideModal, confirmButtonHandler, isOpen }) => {
    const agency = useSelector(getAgencyDetails);
    const user = useSelector(getUser);
    const lease = useSelector(getLease);

    const [confirmed, setConfirmed] = useState(false);
    const [executionClause, setExecutionClause] = useState(false);

    const agree = () => {
        if (confirmed) {
            confirmButtonHandler({ confirmedIdentity: true });
        }
    };

    const getConfirmBlock = () => {
        return (
            <div className="confirm-details">
                <div className="checkbox">
                    <input
                        type="checkbox"
                        value="special"
                        id="executionClause"
                        checked={executionClause}
                        onChange={() => setExecutionClause(!executionClause)}
                    />
                    <label htmlFor="executionClause">
                        <span className="checkmark" />
                        <span className="label-text-checkbox">
                            I confirm my signature can be inserted on the document in the required areas:
                        </span>
                        <br />
                        <ul>
                            <li>On execution clause</li>
                        </ul>
                    </label>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={getConfirmModalCustomStyle()}
                className="confirm"
                contentLabel="agent-modal"
                onRequestClose={hideModal}
                appElement={document.getElementById('root')}
            >
                <div className="confirm-container">
                    <div className="confirm-title">Confirmation</div>
                    {(user.isGuarantor || !user.isCorporation) && (
                        <div className="confirm-label">
                            You're signing an important document, please grab a friend or colleague to witness your
                            signature
                        </div>
                    )}
                    <div className="confirm-details">
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="confirmation"
                                checked={confirmed}
                                onChange={() => setConfirmed(!confirmed)}
                            />
                            <label htmlFor="confirmation">
                                <span className="checkmark" />
                                <span className="label-text-checkbox">
                                    I confirm I am {getName(lease, user)} as identified by documents provided to{' '}
                                    {agency.agencyName}. The signature is my own, and I also confirm I agree to sign my
                                    Commercial Lease Agreement in this electronic format
                                </span>
                            </label>
                        </div>
                    </div>
                    {getConfirmBlock()}
                    <div className="confirm-footer">
                        <button disabled={!(executionClause && confirmed)} onClick={agree}>
                            I Agree
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmIdentity;
