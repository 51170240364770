import React from 'react';
import cx from 'classnames';
import Typography, { TypographyTypes } from './Typography';
import { Tag, TagType } from '../../common/components/Tag.js';

import styles from './SectionHeader.module.scss';

type SectionHeaderProps = {
    text: string;
    tag?: string;
    type?: SectionHeaderType;
};

enum SectionHeaderType {
    DEFAULT,
    WARNING,
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ text, tag, type = SectionHeaderType.DEFAULT }) => {
    return (
        <div className={cx(styles.sectionHeader, { [styles.warning]: type === SectionHeaderType.WARNING })}>
            {tag && <Tag className={styles.tag} text={tag} tagType={TagType.ITEM} />}
            <Typography type={TypographyTypes.H1} text={text} className={styles.text} />
        </div>
    );
};

export { SectionHeaderType };
export default SectionHeader;
