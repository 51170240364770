import { createSelector } from 'reselect';
const getMain = (state) => state.main;

export const getMapData = (state) => state.mapData;
export const getRentReductionAgreement = createSelector([getMain], (main) => main.rentReductionAgreement);
export const getLease = createSelector([getMain], (main) => main.lease);
export const getFullAgreementInfo = createSelector([getMain], (main) => main.fullAgreementInfo);
export const getUser = createSelector([getMain], (main) => main.user);
export const getLocation = createSelector([getLease], (lease) => lease.location);
export const getAgreementInfo = createSelector([getMain], (main) => main.agreementInfo);
export const getShowSuccessAlert = createSelector([getMain], (main) => main.showSuccessAlert);
export const getIsLoggedIn = createSelector([getMain], (main) => main.isLoggedIn);
export const getErrorMessage = createSelector([getMain], (main) => main.errorMessage);
export const getInitialWindowHeight = createSelector([getMain], (main) => main.initialWindowHeight);
export const getSmsToken = createSelector([getMain], (main) => main.smsToken);
export const getUserMobile = createSelector([getUser], (user) => user?.mobile);
export const getDesktopSigningStatus = createSelector([getMain], (main) => main.desktopSigningStatus || null);
export const getEmergencyContactStatus = createSelector((main) => main?.emergencyContactStatus);
