import 'sass/footer.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import hashHistory from './../history';

import Button from '../common/components/Button';
import ConfirmLocationFooter from '../containers/steps/ConfirmLocationFooter';
import ReadAndAgreeFooter from '../containers/steps/ReadAndAgreeFooter';
import PmSigningFooter from '../containers/pm_steps/PmSigningFooter';
import QuestionsFooter from '../containers/common/QuestionsFooter';
import SkipQuestionsFooter from '../containers/common/SkipQuestionsFooter';
import PmApproveFooter from '../containers/pm_steps/PmApproveFooter';
import QuestionsSignFooter from '../containers/common/QuestionsSignFooter';
import PmSpecialConditionsSignFooter from '../containers/pm_steps/qld/PmSpecialConditionsSignFooter';
import * as tenantLogin from '../actions/main';
import { isLeaseLocationNSW, isLeaseLocationQLD, isLeaseLocationVIC, isLeaseTypePm, isLocationBaseURL } from 'utils';
import { getPmLabel } from '../labelUtils';

const INITIAL_AGREMEENT = 'Initial this agreement';
class PmFooter extends Component {
    footerContent() {
        const {
            steps,
            step,
            currentStepStatus,
            isConfirmingStep,
            pmDisclosureAnswered,
            pmMaterialFactAnswered,
            pmDisclosureAnswers,
            pmMaterialFactAnswers,
            pmDynamicStandardTermsAnswered,
            pmDynamicStandardTermsAnswers,
            pmOutgoingsAnswered,
            pmOutgoingsAnswers,
            poolSafetyAndSmokeAlarmsAnswers,
            poolSafetyAndSmokeAlarmsAnswered,
            agentAdditionalTermsAnswers,
            agentAdditionalTermsAnswered,
            user,
            contactPersonSuccess,
            ownerEnteredSuccess,
            skipEntering,
            paymentMethod,
            pmRentAndPayment,
            location,
            lease,
            waterConsumptionAndTenantPayments,
            waterConsumptionConfirmed,
            priorAppointmentAnswer,
            pmInsuranceAnswer,
            pmInsuranceAnswered,
        } = this.props;

        let currentStep = steps[step];

        let neverDefaultFooter = [
            'welcome', // Always agree to terms
            'summary', // Final page
            'woohoo',
            'pause',
        ];

        let defaultFooter = (disableNext = false) => {
            return (
                <footer className={currentStepStatus}>
                    {currentStepStatus === 'declined' && <div className="button button-cancel" />}
                    {currentStepStatus === 'declined' && <div className="button button-success" />}
                    {currentStepStatus === 'none' && (
                        <div className="button button-cancel">
                            <button onClick={() => this.props.declineButtonHandler()} disabled={isConfirmingStep}>
                                <div className="icon" />
                            </button>
                        </div>
                    )}
                    {currentStepStatus === 'none' && !disableNext &&  (
                        <div className="button button-success">
                            <button onClick={() => this.props.confirmButtonHandler()} disabled={isConfirmingStep}>
                                <div className="icon" />
                            </button>
                        </div>
                    )}
                    {currentStepStatus !== 'none' &&
                        currentStepStatus !== 'declined' && (
                            <div className="button button-prev">
                                <button onClick={() => this.toStep(step - 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                    {currentStepStatus !== 'none' &&
                        currentStepStatus !== 'declined' && (
                            <div className="button button-next">
                                <button onClick={() => this.toStep(step + 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                </footer>
            );
        }

        if (currentStep.status === 'confirmed' && neverDefaultFooter.indexOf(currentStep.name) === -1) {
            return defaultFooter();
        }
        switch (currentStep.name) {
            case 'welcome': {
                return (
                    <div className="footer-buttons">
                        <Button
                            onClick={() => {
                                if (currentStep.status === 'confirmed') {
                                    this.toStep(step + 1, steps);
                                } else {
                                    this.props.confirmButtonHandler();
                                }
                            }}
                            disabled={isConfirmingStep}
                        >
                            Get Started
                        </Button>
                        <p>
                            By clicking "Get Started" you agree to FLK IT OVER'S{' '}
                            <a
                                href="http://www.flkitover.com/terms-conditions/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                terms and conditions
                            </a>{' '}
                            and{' '}
                            <a
                                href="http://www.flkitover.com/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                privacy policy
                            </a>
                        </p>
                    </div>
                );
            }
            case 'location': {
                return (
                    <ConfirmLocationFooter
                        confirmButtonHandler={this.props.confirmButtonHandler}
                        enterLocationManually={this.props.enterLocationManually}
                    />
                );
            }
            // This extra case can be remove after we confirm old routes no longer exist in DocumentProgress
            case 'signing_location':
            case 'signingLocation': {
                return (
                    <ConfirmLocationFooter
                        confirmButtonHandler={this.props.confirmButtonHandler}
                        enterLocationManually={this.props.enterLocationManually}
                    />
                );
            }
            case 'pmDetailsRepairs': {
                if (!isLeaseLocationNSW(location) || contactPersonSuccess || !user.isPrimaryLandlord) {
                    return defaultFooter();
                } else {
                    return null;
                }
            }
            case 'pmRentAndTerm': {
                if (
                    isLeaseLocationNSW(location) ||
                    isLeaseLocationVIC(location) ||
                    !pmRentAndPayment.paymentData.OwnerToEnter.enabled ||
                    !user.isPrimaryLandlord ||
                    (
                        (ownerEnteredSuccess || skipEntering) && 
                        (pmRentAndPayment.isPropertyBeenLeaseOutUnderGTA || pmRentAndPayment.skipOwnerDisclosure)
                    )
                ) {
                    if(isLeaseLocationQLD(location) && (
                        (!user.isPrimaryLandlord && !pmRentAndPayment.skipOwnerDisclosure) || 
                        (user.isPrimaryLandlord && !pmRentAndPayment.isPropertyBeenLeaseOutUnderGTA)
                    )) {
                        return defaultFooter(true);
                    } else {
                        return defaultFooter();
                    }
                } else {
                    return defaultFooter(true);
                }
            }
            // This extra case can be remove after we confirm old routes no longer exist in DocumentProgress
            case 'agent_conditions':
            case 'agentConditions': {
                if (
                    isLeaseTypePm(lease.leaseType) &&
                    isLeaseLocationQLD(location) &&
                    lease.specialConditions.hasSpecialConditions
                ) {
                    return (
                        <PmSpecialConditionsSignFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={agentAdditionalTermsAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={user.isPrimaryLandlord ? agentAdditionalTermsAnswered : true}
                            title={'Initial confirmation'}
                            subtitle={'Initial on the document'}
                            label={INITIAL_AGREMEENT}
                        />
                    );
                } else if (user.isPrimaryLandlord && agentAdditionalTermsAnswers.questions.length > 0) {
                    return (
                        <QuestionsSignFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={agentAdditionalTermsAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={agentAdditionalTermsAnswered}
                            title={getPmLabel('agentConditions.footer.title', location)}
                            subtitle={''}
                            label={'Sign this'}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'waterConsumptionAndTenantPayments': {
                if (user.isPrimaryLandlord) {
                    return (
                        <QuestionsFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={waterConsumptionAndTenantPayments}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={waterConsumptionConfirmed}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'pmInsurance': {
                if (user.isPrimaryLandlord) {
                    return (
                        <QuestionsFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={pmInsuranceAnswer}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={pmInsuranceAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'priorAppointment': {
                if (user.isPrimaryLandlord) {
                    return (
                        <QuestionsFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={priorAppointmentAnswer}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={priorAppointmentAnswer}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }

            case 'pmFees': {
                if (
                    isLeaseLocationQLD(location) ||
                    !paymentMethod.OwnerToEnter.enabled ||
                    !user.isPrimaryLandlord ||
                    ownerEnteredSuccess ||
                    skipEntering
                ) {
                    return defaultFooter();
                } else {
                    return null;
                }
            }

            case '': {
                return (
                    <footer className={currentStepStatus}>
                        {currentStepStatus !== 'none' && (
                            <div className="button button-prev">
                                <button onClick={() => this.toStep(step - 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                        {currentStepStatus !== 'none' && (
                            <div className="button button-next">
                                <button onClick={() => this.toStep(step + 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                    </footer>
                );
            }
            // This extra case can be remove after we confirm old routes no longer exist in DocumentProgress
            case 'owner_id':
            case 'ownerId': {
                return (
                    <footer className={currentStepStatus}>
                        {currentStepStatus !== 'none' && (
                            <div className="button button-prev">
                                <button onClick={() => this.toStep(step - 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                        {currentStepStatus !== 'none' && (
                            <div className="button button-next">
                                <button onClick={() => this.toStep(step + 1, steps)}>
                                    <div className="icon" />
                                </button>
                            </div>
                        )}
                    </footer>
                );
            }
            case 'pmDisclosureOfInformation': {
                if (user.isPrimaryLandlord) {
                    return (
                        <QuestionsFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={pmDisclosureAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={pmDisclosureAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'pmMaterialFact': {
                if (user.isPrimaryLandlord) {
                    return (
                        <QuestionsFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={pmMaterialFactAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={pmMaterialFactAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'pmOutgoings': {
                if (user.isPrimaryLandlord && pmOutgoingsAnswers.questions.length > 0) {
                    return (
                        <QuestionsFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={pmOutgoingsAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={pmOutgoingsAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'pmPoolSafetyAndSmokeAlarms': {
                if (user.isPrimaryLandlord) {
                    return (
                        <QuestionsFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={poolSafetyAndSmokeAlarmsAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={poolSafetyAndSmokeAlarmsAnswered}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            case 'pmDynamicTermsConditions': {
                if (user.isPrimaryLandlord) {
                    return (
                        <QuestionsSignFooter
                            step={step}
                            steps={steps}
                            currentStep={currentStep}
                            answers={pmDynamicStandardTermsAnswers}
                            isConfirmingStep={isConfirmingStep}
                            confirmButtonHandler={this.props.confirmButtonHandler}
                            allAnswered={pmDynamicStandardTermsAnswered}
                            title={'Confirm Smoke Alarm maintenance'}
                            subTitle={''}
                            label={'Initial this'}
                        />
                    );
                } else {
                    return <SkipQuestionsFooter currentStep={currentStep} />;
                }
            }
            // This extra case can be remove after we confirm old routes no longer exist in DocumentProgress
            case 'owner_signs':
            case 'ownerSigns': {
                return (
                    <PmSigningFooter
                        step={step}
                        steps={steps}
                        currentStep={currentStep}
                        isConfirmingStep={isConfirmingStep}
                        confirmButtonHandler={this.props.confirmButtonHandler}
                    />
                );
            }
            case 'pmStandardTerms': {
                if (
                    isLeaseTypePm(lease.leaseType) &&
                    isLeaseLocationQLD(location) &&
                    lease.specialConditions.hasSpecialConditions
                ) {
                    return (
                        <PmApproveFooter
                            currentStep={currentStep}
                            label="I have read and agree to this"
                            confirmButtonHandler={this.props.confirmButtonHandler}
                        />
                    );
                }
                return (
                    <ReadAndAgreeFooter
                        step={step}
                        steps={steps}
                        currentStep={currentStep}
                        label={
                            isLeaseTypePm(lease.leaseType) && isLeaseLocationQLD(location)
                                ? INITIAL_AGREMEENT
                                : 'I’ve read and agree to this!'
                        }
                        confirmButtonHandler={this.props.confirmButtonHandler}
                    />
                );
            }
            case 'summary': {
                let preloader = '';
                let disableButton = false;
                return (
                    <div className="footer-buttons">
                        <Button
                            onClick={() => this.props.confirmButtonHandler()}
                            disabled={disableButton || isConfirmingStep}
                        >
                            FLK it back to your agent {preloader}
                        </Button>
                    </div>
                );
            }
            case 'woohoo':
            case 'pause': {
                return <footer />;
            }
            default: {
                return defaultFooter();
            }
        }
    }

    toStep(step, steps) {
        if (isLocationBaseURL(this.props.lease.leaseType, this.props.location)) {
            hashHistory.push(`/pm-steps/${this.props.location}/${steps[step].name}`);
        } else {
            hashHistory.push(`/pm-steps/${steps[step].name}`);
        }
        this.props.setCurrentStep(steps[step], this.props.lease);
        window.scrollTo(0, 0);
    }

    render() {
        const appIsLoadingClass = this.props.appIsLoading ? 'hide' : '';
        return <div className={appIsLoadingClass}>{this.footerContent()}</div>;
    }
}

export default connect(
    state => ({
        stepDataLocal: state.main.steps,
        location: state.main.lease.location,
        isConfirmingStep: state.steps.isConfirmingStep,
        lease: state.main.lease,
        user: state.main.user,
        pmDisclosureAnswers: state.pmQuestions.pmDisclosureAnswers,
        priorAppointmentAnswer: state.pmQuestions.priorAppointmentAnswer,
        waterConsumptionAndTenantPayments: state.pmQuestions.waterConsumptionAndTenantPayments,
        waterConsumptionConfirmed: state.pmQuestions.waterConsumptionAndTenantPayments.confirmed,
        pmMaterialFactAnswers: state.pmQuestions.pmMaterialFactAnswers,
        pmMaterialFactAnswered: state.pmQuestions.pmMaterialFactAnswered,
        pmDynamicStandardTermsAnswers: state.pmQuestions.pmDynamicStandardTermsAnswers,
        pmDisclosureAnswered: state.pmQuestions.pmDisclosureAnswered,
        pmDynamicStandardTermsAnswered: state.pmQuestions.pmDynamicStandardTermsAnswered,
        pmOutgoingsAnswered: state.pmQuestions.pmOutgoingsAnswered,
        pmOutgoingsAnswers: state.pmQuestions.pmOutgoingsAnswers,
        poolSafetyAndSmokeAlarmsAnswers: state.pmQuestions.poolSafetyAndSmokeAlarmsAnswers,
        poolSafetyAndSmokeAlarmsAnswered: state.pmQuestions.poolSafetyAndSmokeAlarmsAnswered,
        agentAdditionalTermsAnswers: state.commonQuestions.agentAdditionalTermsAnswers,
        agentAdditionalTermsAnswered: state.commonQuestions.agentAdditionalTermsAnswered,
        contactPersonSuccess: state.main.contactPersonSuccess,
        ownerEnteredSuccess: state.pmOwnerEnteredPaymentMethod.ownerEnteredSuccess,
        skipEntering: state.pmOwnerEnteredPaymentMethod.skipEntering,
        paymentMethod: state.main.lease.paymentMethod,
        pmRentAndPayment: state.main.lease.pmRentAndPayment,
        pmInsuranceAnswered: state.pmQuestions.pmInsuranceAnswer.allAnswered,
        pmInsuranceAnswer: state.pmQuestions.pmInsuranceAnswer,
        appIsLoading: state.main.appIsLoading
    }),
    {
        setCurrentStep: tenantLogin.setCurrentStep
    }
)(PmFooter);
