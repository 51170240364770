/* eslint-disable no-case-declarations */
import React from 'react';
import { get, has } from 'lodash';

export const STEP_CONFIRM_REQUEST = 'STEP_CONFIRM_REQUEST';
export const STEP_CONFIRM_SUCCESS = 'STEP_CONFIRM_SUCCESS';
export const STEP_CONFIRM_FAILURE = 'STEP_CONFIRM_FAILURE';

export const STEP_REJECT_REQUEST = 'STEP_REJECT_REQUEST';
export const STEP_REJECT_SUCCESS = 'STEP_REJECT_SUCCESS';
export const STEP_REJECT_FAILURE = 'STEP_REJECT_FAILURE';

export const SUBMIT_CONNECTIONS_REQUEST = 'SUBMIT_CONNECTIONS_REQUEST';
export const SUBMIT_CONNECTIONS_SUCCESS = 'SUBMIT_CONNECTIONS_SUCCESS';
export const SUBMIT_CONNECTIONS_FAIL = 'SUBMIT_CONNECTIONS_FAIL';

export const SET_CURRENT_SALES_STEP = 'SET_CURRENT_SALES_STEP';
export const SET_STEPS = 'SET_STEPS';

import * as Main from './main';
import { CLOSE_PAYLOAD_ALERT, HTTP_STATUS_UNPROCESSABLE_ENTITY } from './main';

/**
 * Available statuses of steps.
 * @type {string}
 */
export const STEP_STATE_STATUS_NONE = 'none';

const initState = {
    isConfirmingStep: false,
    isConfirmingRedirectSalesStep: true,
    tenantSignature: '',
    changeRequestList: [],
    leaseStatus: null,
    errorMessage: '',
    showErrorPage: false,
    isRedirect: false,
    redirectStep: 0,
    salesSteps: []
};

export default function salesSteps(state = initState, action) {
    switch (action.type) {
        case STEP_CONFIRM_REQUEST:
            return {
                ...state,
                isConfirmingStep: true,
                isConfirmingRedirectSalesStep: true
            };

        case STEP_CONFIRM_SUCCESS:
        case STEP_REJECT_SUCCESS:
        case Main.TENANT_LOGIN_SUCCESS:
        case Main.TENANT_REFRESH_SUCCESS: {
            let salesSteps = [...state.salesSteps];
            if (!action.result.data.isRedirect) {
                if (action.result.data.steps) {
                    salesSteps = action.result.data.steps;
                }
            }

            return {
                ...state,
                salesSteps: salesSteps,
                isRedirect: action.result.data.isRedirect,
                redirectStep: action.result.data.redirectStep,
                changeRequestList: action.result.data.changeRequestList,
                tenantSignature: action.result.data.user.signature,
                isConfirmingStep: false,
                isConfirmingRedirectSalesStep: false
            };
        }
        case SUBMIT_CONNECTIONS_SUCCESS: {
            return {
                ...state,
                isConfirmingStep: false,
                isConfirmingRedirectSalesStep: false
            };
        }

        case Main.TENANT_LOGIN_FAILURE:
        case Main.TENANT_REFRESH_FAILURE:
        case STEP_CONFIRM_FAILURE:
        case STEP_REJECT_FAILURE:
            let leaseStatus = null;
            let errorMessage = '';

            let showErrorPage = false;
            let showErrorModal = false;
            if (
                action.error &&
                action.error.response &&
                action.error.response.status === HTTP_STATUS_UNPROCESSABLE_ENTITY
            ) {
                showErrorModal = true;
            } else {
                showErrorPage = true;
            }

            if (has(action, 'error.response.data.leaseStatus')) {
                leaseStatus = action.error.response.data.leaseStatus;
            }

            if (has(action, 'error.response.data.error')) {
                errorMessage = action.error.response.data.error;
            }

            return {
                ...state,
                leaseStatus,
                errorMessage,
                showErrorPage,
                showErrorModal,
                isConfirmingStep: false,
                isConfirmingRedirectSalesStep: true
            };

        case CLOSE_PAYLOAD_ALERT: {
            return {
                ...state,
                showErrorModal: false
            };
        }

        case SET_CURRENT_SALES_STEP: {
            const salesSteps = [...state.salesSteps];

            salesSteps.map((step, index) => {
                salesSteps[index].current = step.url === action.step.url;
                if (step.url === action.step.url) {
                    salesSteps[index].visited = true;
                }
            });

            return Object.assign({}, state, {
                salesSteps: salesSteps,
                isConfirmingRedirectSalesStep: true
            });
        }

        case SET_STEPS: {
            return Object.assign({}, state, {
                salesSteps: action.steps
            });
        }

        default:
            return state;
    }
}
