import React from 'react';
import styles from './Toast.module.scss';
import cx from 'classnames';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import { useDispatch } from 'react-redux';
import { removeToast } from '../../actions/toast';

enum ToastTypes {
    SUCCESS,
    ERROR,
    CONGRATULATIONS,
    INFO,
}

type ToastProps = {
    type?: ToastTypes;
    message: string;
    id: string;
    timeout?: number;
    className?: string;
};

const getClass = (type: ToastTypes) => {
    switch (type) {
        case ToastTypes.SUCCESS:
            return styles.successNotification;
        case ToastTypes.ERROR:
            return styles.errorNotification;
        case ToastTypes.CONGRATULATIONS:
            return styles.successNotification;
        case ToastTypes.INFO:
            return styles.infoNotification;
        default:
            return '';
    }
};

const getIcon = (type: ToastTypes) => {
    switch (type) {
        case ToastTypes.SUCCESS:
            return <Icon className={styles.icon} icon={Icons.CHECK} />;
        case ToastTypes.ERROR:
            return <Icon className={styles.icon} icon={Icons.CLOSE} />;
        case ToastTypes.CONGRATULATIONS:
            return <div className={styles.emoji}>🎉</div>;
        case ToastTypes.INFO:
            return <Icon className={styles.icon} icon={Icons.INFO_ICON} />;
        default:
            throw new Error('An invalid icon prop was passed to the Toast component.');
    }
};

/**
 * To add a toast message use addNewToast
 * const { addNewToast } = useToast();
 * addNewToast('Sample message', ToastTypes.SUCCESS)
 */
const Toast: React.FC<ToastProps> = ({ type = ToastTypes.SUCCESS, message, id, timeout = 10000, className }) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTimeout(() => {
            dispatch(removeToast(id));
        }, timeout);
    }, [id]);

    return (
        <div className={cx(styles.toast, getClass(type), className)}>
            {getIcon(type)}
            {message}
        </div>
    );
};

export default Toast;
export { ToastTypes };
