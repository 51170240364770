import walkme from './walkme/walkme';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: `${GTM_ID}`,
};

const vendor = [walkme];

export const initVendor = () => {
    vendor.map((vendorInit) => vendorInit());
};

if (GTM_ID) {
    TagManager.initialize(tagManagerArgs);
}
