import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps } from 'react-final-form';

import styles from './TextAreaInput.module.scss';

enum TextAreaInputType {
    DEFAULT,
    WARNING,
}

interface TextAreaInputComponentProps extends TextAreaInputProps, FieldRenderProps<string, HTMLElement> {}

const TextAreaInputComponent: React.FC<TextAreaInputComponentProps> = ({
    input,
    label,
    disabled,
    required,
    placeholder,
    textAreaType = TextAreaInputType.DEFAULT,
}) => {
    return (
        <div className={cx(styles.textAreaInput, { [styles.warning]: textAreaType === TextAreaInputType.WARNING })}>
            {label && (
                <label className={styles.label}>
                    {label}
                    {required ? '*' : ''}
                </label>
            )}
            <textarea type="text" className={styles.input} disabled={disabled} placeholder={placeholder} {...input} />
        </div>
    );
};

interface TextAreaInputProps {
    name: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    textAreaType?: TextAreaInputType;
}

const TextAreaInput: React.FC<TextAreaInputProps> = (props) => {
    return <Field {...props} component={TextAreaInputComponent} type={'text'} />;
};

export { TextAreaInputType };
export default TextAreaInput;
