import { createSelector } from 'reselect';
import { RootState } from '../../types';

export const getMainSteps = (state: RootState) => state.commonSteps;

export const getSteps = createSelector([getMainSteps], (steps) => steps.steps);

export const getIsConfirmingStep = createSelector([getMainSteps], (steps) => steps.isConfirmingStep);

export const getShowErrorPage = createSelector([getMainSteps], (steps) => steps.showErrorPage);

export const getChangeRequestList = createSelector([getMainSteps], (steps) => steps.changeRequestList);
