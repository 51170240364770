import React, { Component } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { STEP_STATE_STATUS_DECLINE, STEP_STATE_STATUS_CONFIRMED, STEP_STATE_STATUS_NONE } from 'reducers/steps';
import '../../sass/navigation.scss';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setCurrentStep(menuItem) {
        this.props.setCurrentStep(menuItem);
        this.close();
    }

    close() {
        this.props.hideNavigation();
    }

    className(menuItem) {
        let className = '';

        if (menuItem.name === '') {
            return className;
        }

        className += 'menu-item';

        if (menuItem.status === STEP_STATE_STATUS_CONFIRMED) {
            className += ' confirmed';
        }

        if (menuItem.status === STEP_STATE_STATUS_DECLINE) {
            className += ' decline';
        }

        if (menuItem.visited) {
            className += ' visited';
        }

        if (menuItem.current) {
            className += ' current';
        }

        return className;
    }

    render() {
        const { modalIsOpen, menuItems } = this.props;
        let customStyle = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                zIndex: 3
            }
        };

        return (
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    style={customStyle}
                    className="navigation-modal"
                    contentLabel="Modal"
                    onRequestClose={() => this.close()}
                    appElement={document.getElementById('root')}
                >
                    <div className="navigation-container">
                        <ul>
                            {menuItems.map((item, index) => {
                                return (
                                    <li key={index} className={this.className(item)}>
                                        {item.status !== STEP_STATE_STATUS_NONE || item.current || item.visited ? (
                                            <Link to={'steps/' + item.name} onClick={() => this.setCurrentStep(item)}>
                                                {item.name}
                                            </Link>
                                        ) : (
                                            <span onClick={() => this.close()}>{item.name}</span>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Navigation;
