import React from 'react';
import '../../../sass/label.scss';

const Label = ({ children, className, dataTest }) => {
    return (
        <div data-test={dataTest} className={className ? className + ' label' : 'label'}>
            {children}
        </div>
    );
};

export default Label;
