import { UPDATE_WITNESS_FORM, UPDATE_WITNESS_FORM_INVALID } from 'actions/types';
import { TENANT_LOGIN_SUCCESS } from './main';

const initialState = {
    firstName: '',
    lastName: '',
    confirmation: false,
    sameAsUser: false
};

export default function witness(state = initialState, action) {
    switch (action.type) {
        case UPDATE_WITNESS_FORM: {
            return {
                ...state,
                [action.payload.fieldName]: action.payload.value
            };
        }
        case TENANT_LOGIN_SUCCESS: {
            return {
                ...initialState
            };
        }

        case UPDATE_WITNESS_FORM_INVALID: {
            return {
                ...state,
                sameAsUser: true,
                firstName: '',
                lastName: ''
            };
        }
    }

    return state;
}
