import * as React from 'react';
import { connect } from 'react-redux';
import Button from 'common/components/Button';
import Sign from 'components/modal/Sign';
import Preloader from 'components/Preloader';
import { isLeaseTypeResidentialTenancy, isLeaseTypeSales } from 'utils';
import { getStepViaName } from 'utils';
import { confirmStep } from 'actions/main';
import { SPECIAL_CONDITIONS_INITIAL_STEP } from 'common/constants';

class AdditionalTermsFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            showModalSign: false,
            disablePageSigningButton: true
        };
    }

    checkSpecialConditions = props => {
        if (props.clauseList.length > 0) {
            let thisStep = null;
            if (isLeaseTypeSales(props.lease.leaseType)) {
                thisStep = getStepViaName('agent_conditions', props.steps);
                this.setState({
                    disablePageSigningButton: thisStep.disablePageSigning
                });
            } else if (isLeaseTypeResidentialTenancy(props.lease.leaseType)) {
                const specialConditionStep = getStepViaName(SPECIAL_CONDITIONS_INITIAL_STEP, props.steps);
                this.setState({
                    disablePageSigningButton: specialConditionStep.disablePageSigning
                });
            }
        } else {
            this.setState({
                disablePageSigningButton: false
            });
            if (!props.isConfirmingStep) {
                this.submitSkipStep();
            }
        }
    };

    componentDidMount() {
        this.checkSpecialConditions(this.props);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.checkSpecialConditions(newProps);
    }

    hideModal() {
        this.setState({
            showModalSign: false
        });
    }

    showModal() {
        this.setState({
            showModalSign: true
        });
    }

    submitSkipStep = () => {
        const data = {
            step: this.props.currentStep.step,
            stepName: this.props.currentStep.name,
            payload: {
                skip: true
            }
        };
        this.props.confirmStep(data);
    };

    initSpecialConditionsInitial(payload) {
        const data = {
            specialConditionsInitial: payload.signature
        };
        this.props.confirmButtonHandler(data);
        this.hideModal();
    }

    render() {
        const { isConfirmingStep } = this.props;
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }
        return (
            <div className="footer-buttons">
                <Button
                    onClick={() => this.showModal()}
                    signIcon={true}
                    className="red"
                    disabled={isConfirmingStep || this.state.disablePageSigningButton}
                >
                    <span>Sign this {preloader}</span>
                </Button>
                <Sign
                    isOpen={this.state.showModalSign}
                    confirmButtonHandler={payload => this.initSpecialConditionsInitial(payload)}
                    isPdf={false}
                    hideModal={() => this.hideModal()}
                    title="Sign clause"
                    subtitle="Special Conditions and Terms"
                    isSignatureModal={true}
                />
            </div>
        );
    }
}

export default connect(
    state => ({
        isConfirmingStep: state.steps.isConfirmingStep,
        lease: state.main.lease,
        clauseList: state.main.lease.specialConditions.clauseList,
        steps: state.main.steps
    }),
    { confirmStep }
)(AdditionalTermsFooter);
