import React from 'react';
import styles from './FlkHeader.module.scss';

const FlkHeader = () => {
    return (
        <div className={styles.header}>
            <img className={styles.logo} src="/images/logo.svg" alt="logo" />
        </div>
    );
};

export default FlkHeader;
