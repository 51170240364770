import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import { defaultAxiosInstance } from '../../actions/index.js';
import { useMutation } from '@tanstack/react-query';
import { closeChangeRequestModal } from '../../actions/changeRequest';
import { setCurrentStep, setStepError } from '../../actions/commonSteps';
import { updateLeaseAndUser } from '../../actions/main.js';
import { getIsOpenChangeRequestModal, getChangeRequestStep } from '../../selectors/changeRequest';
import { getSmsToken } from '../../selectors/main/index.js';
import { DEFINE_ME, AcceptResponseType, StepType } from '../../types';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import FullWidthModal from './FullWidthModal';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import SectionHeader, { SectionHeaderType } from './SectionHeader';
import TextAreaInput, { TextAreaInputType } from '../../componentLibrary/TextAreaInput/TextAreaInput';
import Header from './Header';
import Card, { CardShadow, CardStyles } from '../../componentLibrary/Card/Card';
import { isTabletPortraitUp } from '../../utils.js';

import styles from './ChangeRequest.module.scss';

type ChangeRequestFormProps = {
    handleSubmit: (values: { message: string }) => void;
    isLoading?: boolean;
};

const ChangeRequestForm: React.FC<ChangeRequestFormProps> = ({ handleSubmit, isLoading }) => (
    <Form initialValues={{ message: '' }} onSubmit={handleSubmit}>
        {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
                <SectionHeader text="Request a change" type={SectionHeaderType.WARNING} />
                <TextAreaInput
                    name="message"
                    textAreaType={TextAreaInputType.WARNING}
                    placeholder="Add your change request here"
                />
                <Button
                    buttonStyle={ButtonStyle.PRIMARY}
                    className={styles.submitButton}
                    disabled={!values.message || isLoading}
                    type="submit"
                >
                    Send
                    {isLoading && <div className={styles.spinner} />}
                </Button>
            </form>
        )}
    </Form>
);

type DesktopChangeRequestProps = ChangeRequestFormProps & {
    step: StepType;
    close: () => void;
};

const DesktopChangeRequest: React.FC<DesktopChangeRequestProps> = ({ handleSubmit, step, close, isLoading }) => {
    return (
        <>
            <Header showTitle={false} currentStep={step} />
            <div className={styles.body}>
                <Card style={CardStyles.SQUARE} shadow={CardShadow.NONE} className={styles.section} animate>
                    <Button buttonStyle={ButtonStyle.ICON} className={styles.closeButton} onClick={close}>
                        <Icon className={styles.closeIcon} icon={Icons.CLOSE} />
                    </Button>
                    <ChangeRequestForm handleSubmit={handleSubmit} isLoading={isLoading} />
                </Card>
            </div>
        </>
    );
};

type MobileChangeRequestProps = ChangeRequestFormProps & {
    close: () => void;
};

const MobileChangeRequest: React.FC<MobileChangeRequestProps> = ({ handleSubmit, close, isLoading }) => {
    return (
        <>
            <Button buttonStyle={ButtonStyle.ICON} className={styles.closeButton} onClick={close}>
                <Icon className={styles.closeIcon} icon={Icons.CLOSE} />
            </Button>
            <ChangeRequestForm handleSubmit={handleSubmit} isLoading={isLoading} />
        </>
    );
};

type ChangeRequestProps = {
    step: StepType;
};

const ChangeRequest: React.FC<ChangeRequestProps> = ({ step }) => {
    const dispatch = useDispatch();
    const isOpenChangeRequestModal = useSelector(getIsOpenChangeRequestModal);
    const changeRequestStep = useSelector(getChangeRequestStep);
    const isDesktop = isTabletPortraitUp();

    const token: string = useSelector(getSmsToken);

    const reject = useMutation(
        (data: { message: string }) => {
            return defaultAxiosInstance.post(`/api/tenant/reject`, {
                step: changeRequestStep,
                payload: { requestMessage: data.message, token },
            });
        },
        {
            onSuccess: (response: AcceptResponseType) => {
                dispatch(updateLeaseAndUser(response.data.lease, response.data.user));
                const nextStep: StepType | undefined = response.data.steps.find(
                    (step) => step.step === changeRequestStep
                );
                if (nextStep) {
                    dispatch(
                        setCurrentStep(
                            nextStep,
                            response.data.steps,
                            response.data.changeRequestList,
                            response.data.user
                        )
                    );
                    dispatch(closeChangeRequestModal());
                }
            },
            onError: (error: DEFINE_ME) => {
                dispatch(setStepError(error));
            },
        }
    );

    function close() {
        dispatch(closeChangeRequestModal());
    }

    return (
        <FullWidthModal isOpen={!!isOpenChangeRequestModal} className={styles.requestModal} onRequestClose={close}>
            {isDesktop ? (
                <DesktopChangeRequest
                    handleSubmit={reject.mutate}
                    step={step}
                    close={close}
                    isLoading={reject.isLoading}
                />
            ) : (
                <MobileChangeRequest handleSubmit={reject.mutate} close={close} isLoading={reject.isLoading} />
            )}
        </FullWidthModal>
    );
};

export default ChangeRequest;
