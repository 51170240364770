import React from 'react';
import cx from 'classnames';

import styles from './Spinner.module.scss';

export enum SpinnerTypes {
    PRIMARY,
    SECONDARY,
    TERTIARY,
    QUATERNARY,
    DOTS,
}

export enum SpinnerSizes {
    DEFAULT,
    SMALL,
    MEDIUM,
    LARGE,
    LARGER,
}

export interface SpinnerProps {
    size?: SpinnerSizes;
    type?: SpinnerTypes;
    className?: string;
    fullScreen?: boolean;
}

export function Spinner({
    size = SpinnerSizes.DEFAULT,
    type = SpinnerTypes.PRIMARY,
    className,
    fullScreen,
}: SpinnerProps) {
    const otherClasses = {
        [styles.secondary]: type === SpinnerTypes.SECONDARY,
        [styles.tertiary]: type === SpinnerTypes.TERTIARY,
        [styles.quaternary]: type === SpinnerTypes.QUATERNARY,
        [styles.dots]: type === SpinnerTypes.DOTS,

        [styles.small]: size === SpinnerSizes.SMALL,
        [styles.medium]: size === SpinnerSizes.MEDIUM,
        [styles.large]: size === SpinnerSizes.LARGE,
        [styles.larger]: size === SpinnerSizes.LARGER,
    };

    if (fullScreen) {
        return (
            <div className={styles.backdrop}>
                <div className={cx(className, styles.spinner, otherClasses)} />
            </div>
        );
    }

    return <div className={cx(className, styles.spinner, otherClasses)} />;
}

export default Spinner;
