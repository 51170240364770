import { createSelector } from 'reselect';
import { RootState } from '../types';

export const getChangeRequest = (state: RootState) => state.changeRequest;

export const getIsOpenChangeRequestModal = createSelector(
    [getChangeRequest],
    (changeRequest) => changeRequest.isOpenChangeRequestModal
);

export const getChangeRequestStep = createSelector([getChangeRequest], (changeRequest) => changeRequest.step);
