import React, { useEffect } from 'react';
import '../sass/preloader.scss';
import { useDispatch } from 'react-redux';
import { setAppIsLoading } from '../actions/main';

const preloader = ({ inverse }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        // When the preloader mounts, store isLoading as true
        dispatch(setAppIsLoading(true));
        return () => {
            // Anything in here is fired on component unmount.
            // When we unmount the preloader, the page is no longer loading
            dispatch(setAppIsLoading(false));
        };
    }, []);
    return <div className={inverse ? 'splash-preloader inverse' : 'splash-preloader'} />;
};

export default preloader;
