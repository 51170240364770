/* eslint-disable react/no-string-refs */
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Label from '../steps/blocks/Label';
import classNames from 'classnames/bind';
import Alert from './Alert';
import Preloader from 'components/Preloader';

const Modal = require('react-modal');
import SignaturePad from 'react-signature-canvas';

const cx = classNames.bind(require('sass/sign.scss'));
const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 95,
        height: '100vh',
    },
};
const Sign = ({
    isOpen,
    title,
    subtitle,
    isPdf,
    isPdfError,
    closeErrorAlert,
    tenantSignaturePreview,
    tenantName,
    isSignatureModal,
    confirmButtonHandler,
    token,
    pdfCallback,
    hideModal,
    showSuccessAlert,
    closeSuccessAlert,
    modalClassName,
    displayEmailMeCopy = true
}) => {
    let mySignature = React.useRef(null);
    const [isSignature, setIsSignature] = useState(false);
    const isConfirmingStep = useSelector((state) => state.steps.isConfirmingStep);
    useLayoutEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        }
        return () => {
            if (isOpen) {
                document.body.classList.remove('no-scroll');
            }
        };
    }, [isOpen]);

    const eraseSignature = () => {
        const signature = mySignature;

        setIsSignature(false);

        signature.clear();
    };

    const done = () => {
        const signature = mySignature;
        const signImage = signature.getTrimmedCanvas().toDataURL();
        if (!signature.isEmpty()) {
            confirmButtonHandler({ signature: signImage });
        } else {
            alert('No initial/signature received, please try again');
        }
    };

    const drawStart = () => {
        setIsSignature(true);
    };

    const emailPdf = () => {
        if (token) {
            pdfCallback(token);
        } else {
            pdfCallback();
        }
    };

    const closeModal = () => {
        hideModal();
        setIsSignature(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            style={customStyle}
            className={`sign-modal ${modalClassName}`}
            contentLabel="Modal"
            onRequestClose={closeModal}
            appElement={document.getElementById('root')}
        >
            <div
                className={cx('sign', {
                    open: isOpen,
                    'full-height': tenantSignaturePreview,
                })}
            >
                {!!tenantSignaturePreview && (
                    <div className="tenant-signature-preview">
                        <Label>You're witnessing {tenantName}'s signature</Label>
                        <img src={tenantSignaturePreview.base64SignatureImage} alt="" />
                    </div>
                )}
                {isSignatureModal && (
                    <div className="sign-header signature-modal-header">
                        <div>
                            <h3>
                                Please use your <strong>full signature</strong> below
                            </h3>
                        </div>
                    </div>
                )}
                <div className="sign-header">
                    <button className="button-cancel" onClick={closeModal}>
                        Cancel
                    </button>
                    <div>
                        <h3>{title}</h3>
                        {!!subtitle && <span dangerouslySetInnerHTML={{ __html: subtitle }} />}
                    </div>
                    {isSignature && !isConfirmingStep && (
                        <button className={`button-done`} onClick={done}>
                            {isConfirmingStep && <Preloader />}
                            Done
                        </button>
                    )}
                    {(!isSignature || isConfirmingStep) && (
                        <button className={`button-done`} onClick={done} disabled={true}>
                            {isConfirmingStep && <Preloader />}
                            Done
                        </button>
                    )}
                </div>
                <div
                    className={`signature-pad-block ${
                        !isSignature ? (isSignatureModal ? 'signature-background' : 'initial-background') : ''
                    }`}
                >
                    <SignaturePad
                        onBegin={drawStart}
                        ref={(ref) => {
                            mySignature = ref;
                        }}
                        penColor="#000000"
                        minWidth={1.5}
                        canvasProps={{
                            width: window.innerWidth,
                            height: window.innerHeight * 0.45,
                            className: 'signCanvas',
                        }}
                    />
                </div>
                <div className="sign-footer">
                    {!!isPdf && displayEmailMeCopy && (
                        <button className="button-email" onClick={emailPdf}>
                            Email me a PDF copy for review
                        </button>
                    )}
                    <div />
                    {isSignature && <button className="button-erase" onClick={() => eraseSignature()} />}
                </div>
            </div>
            <Alert modalIsOpen={showSuccessAlert} hideModal={closeSuccessAlert} text="Email with PDF has been sent" />
            <Alert modalIsOpen={isPdfError} hideModal={closeErrorAlert} text="Something went wrong. Please try again" />
        </Modal>
    );
};

export default Sign;
