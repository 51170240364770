import React from 'react';
import { connect } from 'react-redux';
import Button from 'common/components/Button';

class ConnectionsFooter extends React.Component {
    confirmButtonHandler = (declineConnections = false) => {
        let services = this.props.services;
        if (declineConnections) {
            services = {};
        }

        this.props.confirmButtonHandler({
            services,
            privacyPolicy: this.props.privacyPolicy
        });
    };

    render() {
        return (
            <div className="footer-buttons">
                <Button
                    onClick={() => this.confirmButtonHandler()}
                    disabled={
                        (this.props.connectionsEnabled && !this.props.privacyPolicy) || this.props.isConfirmingStep
                    }
                >
                    Submit
                </Button>
                {this.props.connectionsEnabled && (
                    <Button blue onClick={() => this.confirmButtonHandler(true)} disabled={this.props.isConfirmingStep}>
                        I don't want Connections
                    </Button>
                )}
            </div>
        );
    }
}

export default connect(state => ({
    privacyPolicy: state.connections.privacyPolicy,
    services: state.connections.services,
    connectionsEnabled: state.main.lease.connections.connectionsEnabled,
    isConfirmingStep: state.steps.isConfirmingStep
}))(ConnectionsFooter);
