import { debounce } from 'lodash';
import React, { ReactNode, createContext, useContext, useLayoutEffect, useState } from 'react';

// Adapted from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export function isTouchScreenFn(): boolean {
    const { maxTouchPoints, userAgent } = navigator;

    if (maxTouchPoints) {
        return maxTouchPoints > 0;
    } else {
        const { msMaxTouchPoints } = navigator as unknown as { msMaxTouchPoints: number };

        if (msMaxTouchPoints) {
            return msMaxTouchPoints > 0;
        }
    }
    const mQ = window.matchMedia?.('(pointer:coarse)');

    if (mQ?.media === '(pointer:coarse)') {
        return !!mQ.matches;
    } else if ('orientation' in window) {
        return true; // deprecated, but good fallback
    } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = userAgent;
        return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
}

interface MediaQueryContextProps {
    isMobile: boolean;
    isTabletPortrait: boolean;
    isTabletLandscape: boolean;
    isDesktop: boolean;
    isTouchScreen: boolean;
}

const MediaQueryContext = createContext<MediaQueryContextProps | undefined>(undefined);

interface MediaQueryProviderProps {
    children: ReactNode;
}

const MediaQueryProvider: React.FC<MediaQueryProviderProps> = ({ children }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTabletPortrait, setIsTabletPortrait] = useState(false);
    const [isTabletLandscape, setIsTabletLandscape] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isTouchScreen, setIsTouchScreen] = useState(false);

    useLayoutEffect(() => {
        const handleResize = debounce(() => {
            setIsMobile(window.matchMedia('( max-width: 37.49rem )').matches);
            setIsTabletPortrait(window.matchMedia('(max-width: 56.25rem) and (min-width: 37.50rem)').matches);
            setIsTabletLandscape(window.matchMedia('(max-width: 74.99rem) and (min-width: 56.25rem)').matches);
            setIsDesktop(window.matchMedia('( min-width: 75rem )').matches);
        });

        handleResize();
        setIsTouchScreen(isTouchScreenFn());
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <MediaQueryContext.Provider
            value={{
                isMobile,
                isDesktop,
                isTabletLandscape,
                isTabletPortrait,
                isTouchScreen,
            }}
        >
            {children}
        </MediaQueryContext.Provider>
    );
};

const MockMobileMediaQueryProvider: React.FC<MediaQueryProviderProps> = ({ children }) => {
    return (
        <MediaQueryContext.Provider
            value={{
                isMobile: true,
                isDesktop: false,
                isTabletLandscape: false,
                isTabletPortrait: false,
                isTouchScreen: true,
            }}
        >
            {children}
        </MediaQueryContext.Provider>
    );
};

const MockDesktopMediaQueryProvider: React.FC<MediaQueryProviderProps> = ({ children }) => {
    return (
        <MediaQueryContext.Provider
            value={{
                isMobile: false,
                isDesktop: true,
                isTabletLandscape: false,
                isTabletPortrait: false,
                isTouchScreen: false,
            }}
        >
            {children}
        </MediaQueryContext.Provider>
    );
};

const useMediaQueryContext = (): MediaQueryContextProps => {
    const context = useContext(MediaQueryContext);
    if (!context) {
        throw new Error('useMediaQueryContext must be used within a MediaQueryProvider');
    }
    return context;
};

export { MediaQueryProvider, MockDesktopMediaQueryProvider, MockMobileMediaQueryProvider, useMediaQueryContext };

