import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/main';
import { confirmStep } from '../../actions/main';
import Preloader from '../../components/Preloader';
import Button from '../../common/components/Button';
import Sign from '../../components/modal/Sign';

class QuestionsSignFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false
        };
    }

    initialDynamicStandardTerms = signature => {
        const data = {
            step: this.props.currentStep.step,
            stepName: this.props.currentStep.name,
            payload: {
                answers: this.props.answers,
                signature: signature.signature
            }
        };

        this.props.confirmStep(data);
        this.setState({
            isOpenSignModal: true
        });
    };

    hideModal = () => {
        this.setState({
            showModalSign: false
        });
    };

    showModal() {
        this.setState({
            showModalSign: true
        });
    }

    render() {
        const { isConfirmingStep, allAnswered, title, subtitle, label } = this.props;
        let preloader = '';
        if (isConfirmingStep) {
            preloader = <Preloader />;
        }

        return (
            <footer className="column">
                {allAnswered && (
                    <div>
                        <div className="line" />
                        <Button onClick={() => this.showModal()} disabled={isConfirmingStep} signIcon={true}>
                            <span className="normal-text">
                                {label} {preloader}
                            </span>
                        </Button>
                        <Sign
                            isOpen={this.state.showModalSign}
                            confirmButtonHandler={payload => this.initialDynamicStandardTerms(payload)}
                            isPdf={false}
                            hideModal={this.hideModal}
                            title={title}
                            subtitle={subtitle}
                        />
                    </div>
                )}
            </footer>
        );
    }
}

export default connect(
    state => ({
        user: state.main.user,
        agency: state.main.lease.agency.details,
        lease: state.main.lease,
        steps: state.steps.steps,
        isConfirmingStep: state.steps.isConfirmingStep
    }),
    {
        sendPDFCopy: actions.sendPDFCopy,
        closeSuccessAlert: actions.closeSuccessAlert,
        confirmStep
    }
)(QuestionsSignFooter);
