import * as React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../selectors/main/index.js';
import { getChangeRequestList } from '../../selectors/steps/index.js';
import { StepType, UserType, ChangeRequestType } from '../../types';
import Toast, { ToastTypes } from './Toast';

import styles from './changeRequestInfo.module.scss';

interface ChangeRequestInfoProps {
    currentStep: StepType;
}

const ChangeRequestInfo: React.FC<ChangeRequestInfoProps> = ({ currentStep }) => {
    const user: UserType = useSelector(getUser);
    const changeRequestList: ChangeRequestType[] = useSelector(getChangeRequestList);

    const [name, setName] = React.useState('You');

    React.useEffect(() => {
        const userName =
            changeRequestList.find(
                (changeRequest) => changeRequest.leaseAgreementStep === currentStep.step && changeRequest.createdBy !== user.id
            )?.tenantName || 'You';
        setName(userName);
    }, [user, changeRequestList]);

    return (
        <div className={styles.changeRequestInfo}>
            <Toast
                type={ToastTypes.INFO}
                className={styles.toast}
                message={`${name} requested a change, you will be sent an updated link from your agent when the changes have been addressed.`}
                id="changeRequestInfo"
            />
        </div>
    );
};

export default ChangeRequestInfo;
