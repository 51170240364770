import * as Maintenance from 'reducers/maintenance';

export function updateMaintenanceMessage(value) {
    return {
        type: Maintenance.UPDATE_MAINTENANCE,
        payload: {
            message: value.tenantMessage.message,
            subMessage: value.tenantMessage.subMessage
        }
    };
}
