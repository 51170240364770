import React from 'react';
import Preloader from '../../components/Preloader';
import '../../sass/custom.scss';

// In the absence of a proper Layout component for custom documents, the fallback container uses the same classes as CustomDocument and CustomDocumentSign to ensure
// consistent UI
const CustomFallback = () => (
    <div className="custom-document wrap image">
        <div className="custom-document--spinner">
            <Preloader />
        </div>
    </div>
);

export default CustomFallback;
