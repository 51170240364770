import * as React from 'react';
import * as actions from 'actions/main';
import { confirmStep } from 'actions/main';
import { connect } from 'react-redux';
import Button from 'common/components/Button';
import Sign from 'components/modal/Sign';
import ConfirmGlobalInitial from 'components/modal/ConfirmGlobalInitial';
import ConfirmGlobalAdditionalTermsInitial from 'components/modal/ConfirmGlobalAdditionalTermsInitial';

import { isLeaseTypeCommercialLease, isLeaseTypeResidentialTenancy } from 'utils';
import { VIC_STATE } from 'config';
import { getStepViaName } from '../../utils';

class ReadAndAgreeFooter extends React.Component {
    constructor() {
        super();

        this.state = {
            isRetry: false,
            isOpenSignModal: false,
            isOpenConfirmModal: false,
            isOpenAdditionalTermsInitialModal: false,
        };
    }

    confirmSignatureHandler(payload) {
        let data;
        if (
            isLeaseTypeResidentialTenancy(this.props.lease.leaseType) ||
            isLeaseTypeCommercialLease(this.props.lease.leaseType)
        ) {
            data = {
                subStepNo: getStepViaName('isConfirmGlobalInitial', this.props.currentStep.childSteps).step,
                subStepField: 'isConfirmGlobalInitial',
                confirmGlobalInitial: payload.signature,
            };
        } else {
            data = {
                step: this.props.currentStep.step,
                stepName: this.props.currentStep.name,
                signature: payload.signature,
            };
        }
        this.props.confirmButtonHandler(data);
        this.setState({
            isOpenConfirmModal: true,
        });
    }

    confirmGlobalInitialHandler(payload) {
        const SUB_STEP = '.1';
        let data;
        if (
            isLeaseTypeResidentialTenancy(this.props.lease.leaseType) ||
            isLeaseTypeCommercialLease(this.props.lease.leaseType)
        ) {
            data = {
                step: this.props.currentStep.step,
                stepName: this.props.currentStep.name,
                payload: {
                    subStepNo: getStepViaName('globalInitialConfirmation', this.props.currentStep.childSteps).step,
                    subStepField: 'globalInitialConfirmation',
                    ...payload,
                },
            };
        } else {
            data = {
                step: parseFloat(this.props.currentStep.step),
                stepName: this.props.currentStep.name,
                payload: {
                    subStepNo: parseFloat(this.props.currentStep.step + SUB_STEP),
                    subStepField: 'globalInitialConfirmation',
                    ...payload,
                },
            };
        }

        this.props.confirmStep(data);
        this.setState({
            isOpenSignModal: true,
        });
    }

    confirmGlobalAdditionalTermsSignatureHandler(payload) {
        let data;
        if (isLeaseTypeResidentialTenancy(this.props.lease.leaseType)) {
            data = {
                subStepNo: 14.9,
                subStepField: 'isConfirmGlobalInitial',
                confirmGlobalInitial: payload.signature,
            };
        } else {
            data = {
                step: this.props.currentStep.step,
                stepName: this.props.currentStep.name,
                signature: payload.signature,
            };
        }
        this.props.confirmButtonHandler(data);
        this.setState({
            isOpenConfirmModal: true,
        });
    }

    confirmDisclosureByTenantHandler(payload) {
        let data;
        if (isLeaseTypeResidentialTenancy(this.props.lease.leaseType)) {
            data = {
                step: this.props.step,
                stepName: this.props.currentStep.name,
                payload: {
                    subStepField: 'isConfirmDisclosureByTenant',
                    subStepNo: 14.8,
                    ...payload,
                },
            };
        }

        this.props.confirmStep(data);
        this.setState({
            isOpenSignModal: true,
        });
    }

    openConfirmModal = () => {
        this.setState({
            isOpenConfirmModal: true,
        });
    };

    closeConfirmModal = () => {
        this.setState({
            isOpenConfirmModal: false,
        });
    };

    openAdditionalTermsInitialModal = () => {
        this.setState({
            isOpenAdditionalTermsInitialModal: true,
        });
    };

    closeAdditionalTermsInitialModal = () => {
        this.setState({
            isOpenAdditionalTermsInitialModal: false,
        });
    };

    closeSignModal = () => {
        this.setState({
            isOpenSignModal: false,
        });
    };

    render() {
        const { isOpenSignModal, isOpenConfirmModal } = this.state;
        const { location, lease, label, disabled } = this.props;

        return (
            <footer className="column">
                <div className="footer-buttons">
                    <Button
                        onClick={location === VIC_STATE ? this.openAdditionalTermsInitialModal : this.openConfirmModal}
                        disabled={disabled}
                    >
                        {label ? label : 'I’ve read and agree to this!'}
                    </Button>
                </div>
                <Sign
                    isPdf={false}
                    title={'Initial confirmation'}
                    subtitle={
                        isLeaseTypeResidentialTenancy(lease.leaseType)
                            ? location === VIC_STATE
                                ? ''
                                : 'Initial on bottom of each page'
                            : 'Initial on the document'
                    }
                    isOpen={isOpenSignModal}
                    hideModal={this.closeSignModal}
                    confirmButtonHandler={
                        location === VIC_STATE
                            ? (payload) => this.confirmGlobalAdditionalTermsSignatureHandler(payload)
                            : (payload) => this.confirmSignatureHandler(payload)
                    }
                    isSignatureModal={false}
                />
                <ConfirmGlobalInitial
                    isOpen={isOpenConfirmModal}
                    hideModal={this.closeConfirmModal}
                    confirmButtonHandler={(payload) => this.confirmGlobalInitialHandler(payload)}
                />
                <ConfirmGlobalAdditionalTermsInitial
                    isOpen={this.state.isOpenAdditionalTermsInitialModal}
                    hideModal={this.closeAdditionalTermsInitialModal}
                    confirmButtonHandler={(payload) => this.confirmDisclosureByTenantHandler(payload)}
                />
            </footer>
        );
    }
}

export default connect(
    (state) => ({
        user: state.main.user,
        agency: state.main.lease.agency.details,
        steps: state.steps.steps,
        changeRequestList: state.steps.changeRequestList,
        showSuccessAlert: state.main.showSuccessAlert,
        location: state.main.lease.location,
        lease: state.main.lease,
    }),
    {
        sendPDFCopy: actions.sendPDFCopy,
        closeSuccessAlert: actions.closeSuccessAlert,
        confirmStep,
    }
)(ReadAndAgreeFooter);
