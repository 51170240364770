import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import Agent from '../modal/Agent.js';
import styles from './Header.module.scss';
import ChangeRequestInfo from './ChangeRequestInfo';
import { STEP_STATE_STATUS_DECLINE } from '../../reducers/steps.js';
import { getSteps } from '../../selectors/commonSteps/index';
import cx from 'classnames';
import { StepType } from '../../types';
import { findIndex } from 'lodash';

interface HeaderProps {
    currentStep: StepType;
    showTitle?: boolean;
    title?: string;
}

const Header: React.FC<HeaderProps> = ({ currentStep, title, showTitle = true }) => {
    const steps: StepType[] = useSelector(getSteps);
    const [question, setQuestion] = useState<boolean>(false);

    const declined: boolean = currentStep.status === STEP_STATE_STATUS_DECLINE;

    const currentStepIndex = findIndex(steps, { name: currentStep.name });

    return (
        <>
            <header className={cx(styles.header)}>
                {showTitle && <div className={styles.step}>{title || `Step ${currentStepIndex + 1}`}</div>}
                <Button className={styles.infoButton} buttonStyle={ButtonStyle.ICON} onClick={() => setQuestion(true)}>
                    <Icon icon={Icons.INFO_ICON} className={styles.icon} />
                </Button>
            </header>
            {declined && <ChangeRequestInfo currentStep={currentStep} />}
            <Agent modalIsOpen={question} hideModal={() => setQuestion(false)} />
        </>
    );
};

export default Header;
