import React from 'react';
import { useSelector } from 'react-redux';
import { getToastList } from '../../selectors/toast';

import Toast from '../new_designs/Toast';
import styles from './ToastContainer.module.scss';

type ToastContainerProps = React.PropsWithChildren<{}>;

export const ToastContainer: React.FC<ToastContainerProps> = ({ children }) => {
    const toastList = useSelector(getToastList);
    return (
        <>
            {toastList?.length > 0 && (
                <div className={styles.container}>
                    {toastList.map((toast) => (
                        <Toast key={toast.id} {...toast} />
                    ))}
                </div>
            )}
            {children}
        </>
    );
};
