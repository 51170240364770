import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { getConfirmModalCustomStyle, isLeaseLocationNSW, isLeaseLocationQLD } from '../../utils';

class ConfirmIdentitySales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmed: false,
            addSignatureToPdf: false,
        };
    }

    close = () => {
        this.props.hideModal();
    };

    toggleConfirmed = () => {
        this.setState(({ confirmed }) => ({
            confirmed: !confirmed,
        }));
    };

    toggleAddSignatureToPdf = () => {
        this.setState(({ addSignatureToPdf }) => ({
            addSignatureToPdf: !addSignatureToPdf,
        }));
    };

    isFullyConfirmed = () => {
        let confirmed = this.state.confirmed;
        if (this.props.user.isPrimaryVendor) {
            confirmed = this.state.confirmed && this.state.addSignatureToPdf;
        }
        return confirmed;
    };

    confirmButtonHandler = () => {
        if (this.isFullyConfirmed()) {
            this.props.confirmButtonHandler({ confirmedIdentity: true });
        }
    };

    render() {
        const { isOpen, user, location, lease } = this.props;

        let msg = `I confirm I am ${user.fullName} the intended recipient of this document. 
        This signature is my own and I also confirm I agree to sign my sales agreement in this electronic format`;

        if (isLeaseLocationQLD(location)) {
            msg = `I confirm I am ${user.fullName} the intended recipient of this document. This signature is my own, and I also confirm I agree to sign my Sales Agreement in this electronic format`;
        }

        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    style={getConfirmModalCustomStyle()}
                    className="confirm"
                    contentLabel="agent-modal"
                    onRequestClose={() => this.close()}
                    appElement={document.getElementById('root')}
                >
                    <div className="confirm-container" data-test="confirm-container">
                        <div className="confirm-title">Confirmation</div>
                        <div className="confirm-details" data-test="confirm-item">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    value={msg}
                                    id="confirmation"
                                    checked={this.state.confirmed}
                                    onChange={this.toggleConfirmed}
                                />
                                <label htmlFor="confirmation">
                                    <span className="checkmark" />
                                    <span className="label-text-checkbox">{msg}</span>
                                </label>
                            </div>
                        </div>
                        {(user.isPrimaryVendor || isLeaseLocationQLD(location)) && (
                            <div className="confirm-details" data-test="confirm-item">
                                <div className="checkbox">
                                    <input
                                        type="checkbox"
                                        value="PDF"
                                        id="pdf-confirmation"
                                        checked={this.state.addSignatureToPdf}
                                        onChange={this.toggleAddSignatureToPdf}
                                    />
                                    <label htmlFor="pdf-confirmation">
                                        <span className="checkmark" />
                                        <span className="label-text-checkbox">
                                            I confirm that my signature can be inserted onto the PDF in the below
                                            required areas:
                                        </span>
                                        {isLeaseLocationNSW(location) && (
                                            <ul>
                                                <li>Material facts disclosure</li>
                                            </ul>
                                        )}
                                        {isLeaseLocationQLD(location) && (
                                            <ul>
                                                <li>
                                                    <b>Form 6 - Part 9</b>
                                                </li>
                                                <li>
                                                    <b>Additional Terms -</b> Appointment of Real Estate Agent –
                                                    Residential Sales. Schedule to Form 6
                                                </li>
                                                {lease.specialConditions.hasSpecialConditions && (
                                                    <li>
                                                        <b>Special Conditions</b>
                                                    </li>
                                                )}
                                            </ul>
                                        )}
                                    </label>
                                </div>
                            </div>
                        )}

                        <div className="confirm-footer">
                            <button disabled={!this.isFullyConfirmed()} onClick={this.confirmButtonHandler}>
                                I Agree
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect((state) => ({
    agency: state.main.lease.agency.details,
    user: state.main.user,
    lease: state.main.lease,
    leaseType: state.main.lease.leaseType,
    location: state.main.lease.location,
}))(ConfirmIdentitySales);
