import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(require('sass/buttons.scss'));

const ButtonStyle = {
    // Green rounded button. Can pass signIcon to render the signature icon SVG at the end of the button
    DEFAULT: 'default',
    // Enabled by iconButton prop. Renders a circular button - is intended only for icons
    ICON: 'icon',
    // Has the same styling as the Card component. Can add custom start and end icons with the startIconComponent and endIconComponent prop.
    CARD: 'card',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
};

const ButtonTheme = {
    DEFAULT: 'default',
    DARK: 'dark',
};

const ButtonSize = {
    DEFAULT: 'default',
    LARGE: 'large',
    SMALL: 'small',
};

export default class Button extends React.Component {
    render() {
        const {
            dataAutomationId,
            buttonStyle = ButtonStyle.DEFAULT,
            buttonTheme = ButtonTheme.DEFAULT,
            buttonSize = ButtonSize.DEFAULT,
            blue,
            className,
            onClick,
            signIcon,
            disabled,
            visible,
            buttonRef,
            type,
        } = this.props;
        let options = {};
        if (this.props.disabled) {
            options.disabled = 'disabled';
        }

        if (this.props.visible) {
            options.hidden = visible;
        }

        return (
            <button
                data-automation-id={dataAutomationId}
                className={cx('button--base', className, {
                    blue: blue,
                    gray: disabled,
                    'button--default': buttonStyle === ButtonStyle.DEFAULT,
                    'button--icon': buttonStyle === ButtonStyle.ICON,
                    'button--card': buttonStyle === ButtonStyle.CARD,
                    'button--primary': buttonStyle === ButtonStyle.PRIMARY,
                    'button--secondary': buttonStyle === ButtonStyle.SECONDARY,
                    'button--tertiary': buttonStyle === ButtonStyle.TERTIARY,
                    'button--dark': buttonTheme === ButtonTheme.DARK,
                    'button--large': buttonSize === ButtonSize.LARGE,
                    'button--small': buttonSize === ButtonSize.SMALL,
                })}
                onClick={onClick}
                ref={buttonRef}
                type={type}
                {...options}
            >
                {this.props.children}
                {!!signIcon && <i className="button--default__sign-icon" />}
            </button>
        );
    }
}

export { ButtonStyle, ButtonTheme, ButtonSize };

Button.propTypes = {
    blue: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    // signIcon is only intended to work with the default button style.
    signIcon: PropTypes.bool,
    buttonStyle: PropTypes.oneOf(Object.values(ButtonStyle)),
    buttonTheme: PropTypes.oneOf(Object.values(ButtonTheme)),
};
