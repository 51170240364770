import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { getConfirmModalCustomStyle } from 'utils';

class ConfirmIdentityAct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmed: false,
            addSignatureToPdf: false,
            pdfTerms: false,
            pdfAdditional: false,
            pdfLawyersSpecialConditions: false,
            pdfAgentSpecialCOnditions: false,
            pdfSignatures: false
        };
    }

    close = () => {
        this.props.hideModal();
    };

    toggleConfirmed = () => {
        this.setState(({ confirmed }) => ({
            confirmed: !confirmed
        }));
    };

    confirmButtonHandler = () => {
        if (this.state.confirmed) {
            this.props.confirmButtonHandler({ confirmedIdentity: true });
        }
    };

    render() {
        const { isOpen, agency, user } = this.props;
        const resTenMessage = `I confirm I am ${user.fullName} as identified by documents provided to ${
            agency.agencyName
        }. This signature is my own, and I also confirm I agree to sign my Residential Tenancy Agreement in this electronic format.`;

        let confirmed = this.state.confirmed;

        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    style={getConfirmModalCustomStyle()}
                    className="confirm"
                    contentLabel="agent-modal"
                    onRequestClose={() => this.close()}
                    appElement={document.getElementById('root')}
                >
                    <div className="confirm-container">
                        <div className="confirm-title">Confirmation</div>
                        <div className="confirm-details">
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id="confirmation"
                                    checked={this.state.confirmed}
                                    onChange={this.toggleConfirmed}
                                />
                                <label htmlFor="confirmation">
                                    <span className="checkmark" />
                                    <span className="label-text-checkbox">{resTenMessage}</span>
                                </label>
                            </div>
                        </div>
                        <div className="confirm-footer">
                            <button disabled={!confirmed} onClick={this.confirmButtonHandler}>
                                I Agree
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(state => ({
    agency: state.main.lease.agency.details,
    user: state.main.user,
    lease: state.main.lease,
    leaseType: state.main.lease.leaseType,
    location: state.main.lease.location
}))(ConfirmIdentityAct);
