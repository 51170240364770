import { createSelector } from 'reselect';
export const getSalesQuestions = (state) => state.salesQuestions;
export const getSalesSteps = (state) => state.salesSteps.salesSteps;
export const getPrimaryVendorName = (state) => state.main.lease?.signatory?.primaryVendorName || '';
export const getSalesMaterialFactAnswers = createSelector(
    [getSalesQuestions],
    (salesQuestions) => salesQuestions.salesMaterialFactAnswers
);
export const getSalesInsuranceAnswers = createSelector(
    [getSalesQuestions],
    (salesQuestions) => salesQuestions.salesInsuranceAnswers
);
export const getSalesOutgoingsAnswers = createSelector(
    [getSalesQuestions],
    (salesQuestions) => salesQuestions.salesOutgoingsAnswers
);
