import React, { Component } from 'react';
import Modal from 'react-modal';
import '../../sass/agent.scss';
import Action from './ModalAction';
import { defaultAxiosInstance } from './../../actions/index';
import { connect } from 'react-redux';
import { getConfirmModalCustomStyle, formatPhoneForDisplayInternational } from '../../utils';

class Agent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    }

    close() {
        this.props.hideModal();
        this.setState({ modal: false });
    }

    requestCallback() {
        const { lease, user } = this.props;
        this.sendRequestCallbackAPIRequest()
            .then(response => {
                if (response.data.success) {
                    this.setState({ modal: true });
                } else {
                    this.handleRequestCallbackFail();
                }
            })
            .catch(err => {
                this.handleRequestCallbackFail();
            });
    }

    handleRequestCallbackFail() {
        //not sure how to handle this, but let's temporarily use an alert so the user at least knows what's going on
        this.close();
        alert('Sorry, an error occurred. Please try again later.');
    }

    sendRequestCallbackAPIRequest(lease, user) {
        let data = { lease: lease, user: user };
        return defaultAxiosInstance.post(`/api/tenant/request-callback`, data);
    }

    render() {
        const { modalIsOpen, leaseOrDoc } = this.props;

        let className = 'agent-modal',
            modal =
                leaseOrDoc && leaseOrDoc.agent && leaseOrDoc.agency ? (
                    <div className="agent">
                        <div className="agent-details">
                            <div className="agent-photo">
                                <img src={leaseOrDoc.agent.image} />
                            </div>
                            <div className="agent-description">Your agent</div>
                            <div className="agent-name">
                                {leaseOrDoc.agent.firstName} {leaseOrDoc.agent.secondName}
                            </div>
                            <div className="agent-agency">{leaseOrDoc.agency.details.agencyName}</div>
                            <div className="agent-contacts">
                                {leaseOrDoc.agency.details.address}
                                <br />
                                <a href={'tel:' + leaseOrDoc.agent.contactNumber}>
                                    {formatPhoneForDisplayInternational(leaseOrDoc.agent.contactNumber)}
                                </a>
                            </div>
                        </div>
                        {/*
                        <button onClick={() => this.requestCallback()}>
                            Request a call back
                        </button>
                        */}
                    </div>
                ) : null;

        if (this.state.modal) {
            let text = 'Your agent will contact you as soon as possible.';
            className = 'agent-modal-faded';

            modal = (
                <Action text={text}>
                    <button className="continue" onClick={() => this.close()}>
                        OK
                    </button>
                </Action>
            );
        }

        return (
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    style={getConfirmModalCustomStyle(3)}
                    className={className}
                    contentLabel="agent-modal"
                    onRequestClose={() => this.close()}
                    appElement={document.getElementById('root')}
                >
                    {modal}
                </Modal>
            </div>
        );
    }
}

export default connect(state => ({
    leaseOrDoc: state.main.lease ? state.main.lease : state.documents.document,
    user: state.main.user
}))(Agent);
