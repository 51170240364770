import { SA_STATE, VIC_STATE, ACT_STATE, QLD_STATE, NSW_STATE } from './config';
import { isLeaseTypeCommercialLease, isLeaseTypePm, isLeaseTypeSales } from './utils';

const DEFAULT = 'default';

export const getLabel = (label, location, leaseType) => {
    let labelObject = resTenLabels;
    if (isLeaseTypeSales(leaseType)) {
        labelObject = salesLabels;
    } else if (isLeaseTypePm(leaseType)) {
        labelObject = pmLabels;
    } else if (isLeaseTypeCommercialLease(leaseType)) {
        labelObject = commercialLeaseLabels;
    }
    if (labelObject[label][location]) {
        return labelObject[label][location];
    }
    return labelObject[label][DEFAULT];
};

export const getResTenLabel = (label, location) => {
    if (resTenLabels[label][location]) {
        return resTenLabels[label][location];
    }
    return resTenLabels[label][DEFAULT];
};

export const getPmLabel = (label, location) => {
    if (pmLabels[label][location]) {
        return pmLabels[label][location];
    }
    return pmLabels[label][DEFAULT];
};

export const getSalesLabel = (label, location) => {
    if (salesLabels[label][location]) {
        return salesLabels[label][location];
    }
    return salesLabels[label][DEFAULT];
};

export const resTenLabels = {
    tenant: {
        [VIC_STATE]: `Renter`,
        [DEFAULT]: 'Tenant',
    },
    tenantLower: {
        [VIC_STATE]: `renter`,
        [DEFAULT]: 'tenant',
    },
    tenants: {
        [VIC_STATE]: `Renters`,
        [DEFAULT]: 'Tenants',
    },
    tenantsLower: {
        [VIC_STATE]: `renters`,
        [DEFAULT]: 'tenants',
    },
    tenantsDetails: {
        [VIC_STATE]: `RENTER’S DETAILS`,
        [ACT_STATE]: `TENANT(S) JOINTLY AND SEVERALLY`,
        [SA_STATE]: `TENANT(S)`,
        [DEFAULT]: 'TENANT’S DETAILS',
    },
    eft: {
        [VIC_STATE]: `Bank Deposit`,
        [SA_STATE]: `Internet Transfer`,
        [DEFAULT]: 'EFT',
    },
    other: {
        [VIC_STATE]: `Other electronic form of payment, including Centrepay`,
        [DEFAULT]: 'Other',
    },
    paymentMethods: {
        [VIC_STATE]: 'RENTAL PROVIDER’S PREFERRED METHODS OF PAYMENT',
        [DEFAULT]: 'PAYMENT METHODS',
    },
    rentAmount: {
        [VIC_STATE]: 'Rent amount ($) (payable in advance)',
        [DEFAULT]: 'Rent amount ($)',
    },
    rentCommencementDate: {
        [VIC_STATE]: 'Date first rent payment due',
        [DEFAULT]: 'Rent Commencement date',
    },
    emergencyContacts: {
        [VIC_STATE]: 'URGENT REPAIRS',
        [ACT_STATE]: 'Tradespersons',
        [NSW_STATE]: 'Urgent Repairs',
        [QLD_STATE]: 'Nominated Repairers',
        [SA_STATE]: 'Repairs And Maintenance',
        [DEFAULT]: 'Emergency Contacts',
    },
    bond: {
        [ACT_STATE]: 'SECURITY BOND',
        [DEFAULT]: 'BOND',
    },
    bondAmount: {
        [ACT_STATE]: 'Security Bond amount ($)',
        [SA_STATE]: 'The Bond for the premises is:',
        [DEFAULT]: 'Bond amount ($)',
    },
    specialConditionsMessage: {
        [ACT_STATE]:
            'You’re almost ready to FLK it back! Just a few special conditions to read and acknowledge and you’re good to go!',
        [VIC_STATE]: 'You’re almost ready to FLK it back! Just a few additional terms to read and you’re good to go!',
        [DEFAULT]:
            'You’re almost ready to FLK it back! Just a few additional terms and conditions to read and you’re good to go!',
    },
    greeting: {
        [DEFAULT]: 'Cheers',
    },
};

export const pmLabels = {
    licenseeDetails: {
        [VIC_STATE]: `AGENT DETAILS`,
        [DEFAULT]: 'LICENSEE DETAILS',
    },
    agencyName: {
        [VIC_STATE]: "Your agent's Business Trading Name",
        [DEFAULT]: "Your licensee's Business Trading Name",
    },
    companyName: {
        [VIC_STATE]: "Your agent's company name",
        [DEFAULT]: "Your licensee's company name",
    },
    disclosureOfRebatesTitle: {
        [VIC_STATE]: 'DISCLOSURE OF REBATES AND/OR COMMISSION',
        [DEFAULT]: 'DISCLOSURE OF REBATES, DISCOUNTS, COMMISSIONS OR OTHER BENEFITS [Clause 18]',
    },
    'agentConditions.footer.title': {
        [VIC_STATE]: 'Initial Additional Terms and Conditions',
        [DEFAULT]: 'Confirm agents additional terms',
    },
    Landlords: {
        [QLD_STATE]: `LESSORS`,
        [DEFAULT]: 'LANDLORDS',
    },
    Landlord: {
        [QLD_STATE]: `Lessor`,
        [DEFAULT]: 'Landlord',
    },
    landlord: {
        [QLD_STATE]: `lessor`,
        [DEFAULT]: 'landlord',
    },
    greeting: {
        [DEFAULT]: 'Regards',
    },
};

export const salesLabels = {
    greeting: {
        [QLD_STATE]: 'Cheers',
        [DEFAULT]: 'Regards',
    },
    addressLabel: {
        [QLD_STATE]: 'The address of the property you are selling is:',
        [DEFAULT]: 'The property you are selling is:',
    },
};

export const commercialLeaseLabels = {
    greeting: {
        [DEFAULT]: 'Cheers',
    },
};
