import React, { Component } from 'react';
import Agent from 'components/modal/Agent';
import { isLeaseTypeResidentialTenancy, isLeaseTypeSales } from 'utils';
import 'sass/header.scss';
import { isLeaseTypePm } from 'utils';
import { isLeaseLocationVIC, isLeaseLocationACT, isLeaseLocationSA, isLeaseLocationQLD } from '../utils';

const SALES_SPECIAL_CONDITIONS_STEP = 9;

const RES_TEN_SPECIAL_CONDITIONS_STEP = 12;
const RES_TEN_SUMMARY_STEP = 16;

function DefaultHeader({ leaseType, location, totalSteps, step, hideQuestion, showQuestion, question }) {

    function getStepCountDisplay(leaseType, location, totalSteps, step) {
        if (isLeaseTypeSales(leaseType) && isLeaseLocationQLD(location)) {
            return `Step ${step} of ${totalSteps}`;
        } else {
            return `Step ${step}`;
        }
    }

    return (
        <header>
            <Agent modalIsOpen={question} hideModal={() => hideQuestion()} />
            <nav className="header-navbar">
                <div className="nav-left">
                    <p className="nav-header">{getStepCountDisplay(leaseType, location, totalSteps, step)}</p>
                </div>
                <div className="nav-right">
                    <button className="question" onClick={() => showQuestion()} />
                </div>
            </nav>
            <div className="divider" />
        </header>
    );
}

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: false,
        };
    }

    showQuestion() {
        this.setState({ question: true });
    }

    hideQuestion() {
        this.setState({ question: false });
    }
    headerContent() {
        // TODO - This is a quick fix for RTA SA. We should refactor this component.
        // Looks like this will not reflect correct data.
        // https://flkitover.atlassian.net/browse/FLK-3570
        if (isLeaseTypeResidentialTenancy(this.props.lease.leaseType) && isLeaseLocationSA(this.props.lease.location)) {
            if ([0, 19].includes(this.props.step)) {
                return <header className="flat" />;
            }
            return (
                <DefaultHeader
                    leaseType={this.props.lease.leaseType}
                    location={this.props.lease.location}
                    totalSteps={this.props.totalSteps}
                    hideQuestion={this.hideQuestion}
                    showQuestion={this.showQuestion}
                    question={this.state.question}
                    step={this.props.step}
                />
            );
        }
        switch (this.props.step) {
            case 0: {
                return <header className="hide" />;
            }
            case 15: {
                return (
                    <DefaultHeader
                        leaseType={this.props.lease.leaseType}
                        location={this.props.lease.location}
                        totalSteps={this.props.totalSteps}
                        hideQuestion={this.hideQuestion}
                        showQuestion={this.showQuestion}
                        question={this.state.question}
                        step={
                            this.props.lease.location === 'QLD' && this.props.lease.leaseType === 'Residential Tenancy'
                                ? ' 14'
                                : ' 15'
                        }
                    />
                );
            }
            case 16:
            case 17:
            case 18: {
                if (
                    isLeaseTypeResidentialTenancy(this.props.lease.leaseType) &&
                    (isLeaseLocationVIC(this.props.lease.location) || isLeaseLocationACT(this.props.lease.location))
                ) {
                    let step = this.props.step;
                    if (this.props.clauseList && this.props.clauseList.length === 0) {
                        step--;
                    }
                    if (!this.props.lease.additionalTerms.isDefenceClauseEnabled) {
                        step--;
                    }
                    return (
                        <DefaultHeader
                            leaseType={this.props.lease.leaseType}
                            location={this.props.lease.location}
                            totalSteps={this.props.totalSteps}
                            hideQuestion={this.hideQuestion}
                            showQuestion={this.showQuestion}
                            question={this.state.question}
                            step={step}
                        />
                    );
                }
                if (
                    isLeaseTypePm(this.props.lease.leaseType) ||
                    (isLeaseTypeResidentialTenancy(this.props.lease.leaseType) &&
                        this.props.step === RES_TEN_SUMMARY_STEP)
                ) {
                    return (
                        <DefaultHeader
                            leaseType={this.props.lease.leaseType}
                            location={this.props.lease.location}
                            totalSteps={this.props.totalSteps}
                            hideQuestion={this.hideQuestion}
                            showQuestion={this.showQuestion}
                            question={this.state.question}
                            step={
                                isLeaseTypeResidentialTenancy(this.props.lease.leaseType)
                                    ? this.props.step - 1
                                    : this.props.step
                            }
                        />
                    );
                }
                if (isLeaseTypeSales(this.props.lease.leaseType) && isLeaseLocationQLD(this.props.lease.location)) {
                    return (
                        <DefaultHeader
                            leaseType={this.props.lease.leaseType}
                            location={this.props.lease.location}
                            totalSteps={this.props.totalSteps}
                            hideQuestion={this.hideQuestion}
                            showQuestion={this.showQuestion}
                            question={this.state.question}
                            step={this.props.step}
                        />
                    );
                }
                return <header className="flat" />;
            }
            default: {
                return (
                    <DefaultHeader
                        leaseType={this.props.lease.leaseType}
                        location={this.props.lease.location}
                        totalSteps={this.props.totalSteps}
                        hideQuestion={this.hideQuestion}
                        showQuestion={this.showQuestion}
                        question={this.state.question}
                        /***
                         * <button className="burger" type="button" onClick={() => this.props.showNavigation()}/>
                         * If there are no special conditions, remove one step from next steps,
                         * so we skip the special conditions step
                         ***/
                        step={
                            (this.props.clauseList &&
                                this.props.clauseList.length === 0 &&
                                this.props.step > SALES_SPECIAL_CONDITIONS_STEP &&
                                isLeaseTypeSales(this.props.lease.leaseType)) ||
                            (this.props.clauseList &&
                                this.props.clauseList.length === 0 &&
                                this.props.step > RES_TEN_SPECIAL_CONDITIONS_STEP &&
                                isLeaseTypeResidentialTenancy(this.props.lease.leaseType) &&
                                !isLeaseLocationVIC(this.props.lease.location))
                                ? ` ${this.props.step - 1}`
                                : ` ${this.props.step}`
                        }
                    />
                );
            }
        }
    }

    render() {
        return this.headerContent();
    }
}

export default Header;
