/* eslint-disable no-case-declarations */
import React from 'react';
import { get, has } from 'lodash';

export const STEP_CONFIRM_REQUEST = 'STEP_CONFIRM_REQUEST';
export const STEP_CONFIRM_SUCCESS = 'STEP_CONFIRM_SUCCESS';
export const STEP_CONFIRM_FAILURE = 'STEP_CONFIRM_FAILURE';

export const STEP_REJECT_REQUEST = 'STEP_REJECT_REQUEST';
export const STEP_REJECT_SUCCESS = 'STEP_REJECT_SUCCESS';
export const STEP_REJECT_FAILURE = 'STEP_REJECT_FAILURE';

export const SUBMIT_CONNECTIONS_REQUEST = 'SUBMIT_CONNECTIONS_REQUEST';
export const SUBMIT_CONNECTIONS_SUCCESS = 'SUBMIT_CONNECTIONS_SUCCESS';
export const SUBMIT_CONNECTIONS_FAIL = 'SUBMIT_CONNECTIONS_FAIL';

export const SUBMIT_CHARITY_REQUEST = 'SUBMIT_CHARITY_REQUEST';
export const SUBMIT_CHARITY_SUCCESS = 'SUBMIT_CHARITY_SUCCESS';
export const SUBMIT_CHARITY_FAIL = 'SUBMIT_CHARITY_FAIL';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_STEPS = 'SET_STEPS';

import * as Main from './main';
import { CLOSE_PAYLOAD_ALERT, HTTP_STATUS_UNPROCESSABLE_ENTITY } from './main';

/**
 * Available statuses of steps.
 * @type {string}
 */
export const STEP_STATE_STATUS_NONE = 'none';
export const STEP_STATE_STATUS_DECLINE = 'declined';
export const STEP_STATE_STATUS_CONFIRMED = 'confirmed';

const initState = {
    isConfirmingStep: false,
    isConfirmingRedirectStep: true,
    tenantSignature: '',
    changeRequestList: [],
    leaseStatus: null,
    leaseType: null,
    errorMessage: '',
    showErrorPage: false,
    isRedirect: false,
    redirectStep: 0,
    steps: []
};

export default function steps(state = initState, action) {
    switch (action.type) {
        case STEP_CONFIRM_REQUEST:
            return {
                ...state,
                isConfirmingStep: true,
                isConfirmingRedirectStep: true
            };

        case Main.TENANT_LOGIN_REQUEST:
            return {
                ...state,
                showErrorPage: false
            };

        case STEP_CONFIRM_SUCCESS:
        case STEP_REJECT_SUCCESS:
        case Main.TENANT_LOGIN_SUCCESS:
        case Main.TENANT_REFRESH_SUCCESS: {
            let steps = [...state.steps];
            if (!action.result.data.isRedirect) {
                steps = action.result.data.steps;
            }
            return {
                ...state,
                steps: steps,
                isRedirect: action.result.data.isRedirect,
                redirectStep: action.result.data.redirectStep,
                changeRequestList: action.result.data.changeRequestList,
                tenantSignature: action.result.data.user.signature,
                isConfirmingStep: false,
                isConfirmingRedirectStep: false,
                showErrorPage: false
            };
        }
        case SUBMIT_CONNECTIONS_SUCCESS: {
            return {
                ...state,
                isConfirmingStep: false,
                isConfirmingRedirectStep: false,
                isRedirect: false
            };
        }

        case Main.TENANT_LOGIN_FAILURE:
        case Main.TENANT_REFRESH_FAILURE:
        case STEP_CONFIRM_FAILURE:
        case STEP_REJECT_FAILURE:
            let leaseStatus = null;
            let errorMessage = '';
            let leaseType = null;

            let showErrorPage = false;
            let showErrorModal = false;
            if (
                action.error &&
                action.error.response &&
                action.error.response.status === HTTP_STATUS_UNPROCESSABLE_ENTITY
            ) {
                showErrorModal = true;
            } else {
                showErrorPage = true;
            }

            if (has(action, 'error.response.data.leaseStatus')) {
                leaseStatus = action.error.response.data.leaseStatus;
            }

            if (has(action, 'error.response.data.error')) {
                errorMessage = action.error.response.data.error;
            }
            if (has(action, 'error.response.data.leaseType')) {
                leaseType = action.error.response.data.leaseType;
            }

            return {
                ...state,
                leaseStatus,
                leaseType,
                errorMessage,
                showErrorPage,
                showErrorModal,
                isConfirmingStep: false,
                isConfirmingRedirectStep: true
            };

        case CLOSE_PAYLOAD_ALERT: {
            return {
                ...state,
                showErrorModal: false
            };
        }

        case SET_CURRENT_STEP: {
            const steps = state.steps;

            steps.map((step, index) => {
                steps[index].current = step.name === action.step.name;
                if (step.name === action.step.name) {
                    steps[index].visited = true;
                }
            });

            return Object.assign({}, state, {
                steps: steps,
                isConfirmingRedirectStep: true
            });
        }

        case SET_STEPS: {
            return Object.assign({}, state, {
                steps: action.steps
            });
        }

        default:
            return state;
    }
}
