import React from 'react';

export const LOCATION_REQUEST = 'LOCATION_REQUEST';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_FAILURE = 'LOCATION_FAILURE';

const initState = {
    isLoading: false,
    data: false
};

export default function googleMap(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case LOCATION_REQUEST: {
            newState.isLoading = true;
            newState.data = false;
            break;
        }
        case LOCATION_SUCCESS: {
            newState.isLoading = false;
            newState.data = action.result.data.results;
            break;
        }
        case LOCATION_FAILURE: {
            newState.isLoading = false;
            newState.data = false;
            break;
        }
    }

    return newState;
}
