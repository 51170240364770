import { useEffect } from 'react';
import { useTwilioSyncProvider } from '../components/providers/TwilioSyncProvider';
import { getDocumentProgressMapId, EVENT_ITEM_UPDATED, TWILIO_TIMED_OUT_ERROR_MESSAGE } from './helpers';

export function useDocumentProgressUpdate(agency, callback, dependencies = []) {
    const syncClient = useTwilioSyncProvider();

    useEffect(() => {
        const handleItemUpdated = (event) => {
            callback(event.item);
        };

        let eventMap;

        let cleanedUp = false;

        if (agency && agency.id && syncClient) {
            syncClient
                .map(getDocumentProgressMapId(agency))
                .then((map) => {
                    // Listen to updates on the Document
                    if (!cleanedUp) {
                        eventMap = map.on(EVENT_ITEM_UPDATED, handleItemUpdated);
                    }
                })
                .catch((error) => {
                    if (!error.message.includes(TWILIO_TIMED_OUT_ERROR_MESSAGE)) {
                        console.error(
                            'Something went wrong getting the updated document progress',
                            {
                                error_message: error.message,
                                status: 'error',
                                env: ENVIRONMENT,
                            },
                            error
                        );
                    }
                });
        }

        return () => {
            cleanedUp = true;
            if (eventMap) {
                eventMap.removeListener(event, handleItemUpdated);
            }
        };
    }, [syncClient, agency, ...dependencies]);
}
