import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import '../../sass/splashScreen.scss';
import cookie from 'react-cookie';
import hashHistory from './../../history';
import { mobileLogin, getFullPmAgreementInformation, setCurrentStep } from '../../actions/main';
import Preloader from '../../components/Preloader';
import { isLeaseTypePm } from '../../utils';
import { isLocationBaseURL } from '../../utils';
import { getErrorMessage, getIsLoggedIn, getLease, getUser } from '../../selectors/main';
import { getShowErrorPage, getSteps } from '../../selectors/steps';
import { LEASE_TYPE_COMMERCIAL_LEASE, LEASE_TYPE_PROPERTY_MANAGEMENT, LEASE_TYPE_SALES } from '../../config';
import { STEP_STATE_STATUS_NONE } from '../../reducers/steps';
import { get } from 'lodash';
import { getSalesSteps } from '../../selectors/sales';
import { getPmSteps } from '../../selectors/pm';
import { usePrevious } from '../../reactUtils';

function CreateAccount() {
    const dispatch = useDispatch();
    let params = useParams();

    const lease = useSelector(getLease);
    const isLoggedIn = useSelector(getIsLoggedIn);
    const errorMessage = useSelector(getErrorMessage);
    const showErrorPage = useSelector(getShowErrorPage);
    const steps = useSelector(getSteps);
    const salesSteps = useSelector(getSalesSteps);
    const pmSteps = useSelector(getPmSteps);
    const user = useSelector(getUser);
    const prevIsLoggedIn = usePrevious(isLoggedIn);

    useEffect(() => {
        if (params.token) {
            let token = {
                mobileToken: params.token,
            };
            cookie.save('mobileToken', token, {
                maxAge: 3600,
            });
            dispatch(mobileLogin(token));
        }
    }, []);

    useEffect(() => {
        if (lease && isLoggedIn && prevIsLoggedIn === false) {
            switch (lease.leaseType) {
                case LEASE_TYPE_SALES:
                    setSalesStep();
                    break;
                case LEASE_TYPE_PROPERTY_MANAGEMENT:
                    setPmStep();
                    break;
                case LEASE_TYPE_COMMERCIAL_LEASE:
                    setCommercialLeaseStep();
                    break;
                default:
                    setResidentialStep();
            }
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (lease) {
            if (isLeaseTypePm(lease.leaseType)) {
                dispatch(getFullPmAgreementInformation(lease.location));
            }
        }
    }, [lease]);

    useEffect(() => {
        if (showErrorPage) {
            // if we receive notice that something went wrong (link lease is invalid, for instance, when the agent has updated the lease agreement and we still
            // have the old link loaded and try to make a change), immediately redirect the user to an appropriate message.
            hashHistory.push(`/error`);
        }
    }, [showErrorPage]);

    function setResidentialStep() {
        for (let i = 0; i < steps.length; i++) {
            // this execute only STEP_STATE_STATUS_NONE and ignore connections step
            if (steps[i].status === STEP_STATE_STATUS_NONE) {
                if (
                    steps[i].name === 'connections' &&
                    (!get(lease, 'connections.connectionsEnabled', false) ||
                        !user.isPrimaryTenant ||
                        get(lease, 'connections.connectionsSubmitted', false))
                ) {
                    // If connections are not enabled and not a primary tenant then ignore the status of it
                    continue;
                }
                if (isLocationBaseURL(lease.leaseType, lease.location)) {
                    hashHistory.push(`/steps/${lease.location}/${steps[i].name}`);
                } else {
                    hashHistory.push(`/steps/${steps[i].name}`);
                }
                dispatch(setCurrentStep(steps[i], lease));
                break;
            }
        }
    }

    function setSalesStep() {
        for (let i = 0; i < salesSteps.length; i++) {
            if (salesSteps[i].status === STEP_STATE_STATUS_NONE) {
                if (isLocationBaseURL(lease.leaseType, lease.location)) {
                    hashHistory.push(`/sales-steps/${lease.location}/${salesSteps[i].name}`);
                } else {
                    hashHistory.push(`/sales-steps/${salesSteps[i].name}`);
                }
                dispatch(setCurrentStep(salesSteps[i], lease));
                break;
            }
        }
    }

    function setPmStep() {
        for (let i = 0; i < pmSteps.length; i++) {
            if (pmSteps[i].status === STEP_STATE_STATUS_NONE) {
                if (isLocationBaseURL(lease.leaseType, lease.location)) {
                    hashHistory.push(`/pm-steps/${lease.location}/${pmSteps[i].name}`);
                } else {
                    hashHistory.push(`/pm-steps/${pmSteps[i].name}`);
                }
                dispatch(setCurrentStep(pmSteps[i], lease));
                break;
            }
        }
    }

    function setCommercialLeaseStep() {
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].status === STEP_STATE_STATUS_NONE) {
                hashHistory.push(`/commercial-lease-steps/${lease.location}/${steps[i].name}`);
                dispatch(setCurrentStep(steps[i], lease));
                break;
            }
        }
    }

    let logo = 'logo';
    let splashScreen = 'splash-screen';
    let preloader = <Preloader />;

    if (errorMessage === 'invalid token' || !params.token) {
        logo = 'logo logo-index';
        splashScreen = 'splash-screen splash-screen-index';
        preloader = (
            <div className="splash-message">
                <p>Ooops... Sorry but you don't have access to this page/document/lease</p>
            </div>
        );
    }

    if (['Unauthorized', 'Link expired', 'Invalid token'].includes(errorMessage) || !params.token) {
        logo = 'logo logo-index';
        splashScreen = 'splash-screen splash-screen-index';
        preloader = (
            <div className="splash-message">
                <p>
                    Looks like the page you’re trying to view doesn’t exist or the link has expired. Please check the
                    URL and try again.
                </p>
            </div>
        );
    }

    return (
        <div className={splashScreen}>
            <div className={logo}>
                <img src="/images/main_logo.svg" alt="logo" />
            </div>
            {preloader}
        </div>
    );
}

export default CreateAccount;
