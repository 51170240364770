import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import useWindowHeight from '../../hooks/useWindowHeight.js';
import styles from './FullWidthModal.module.scss';

interface FullWidthModalProps {
    isOpen: boolean;
    children: ReactNode;
    className?: string;
    onRequestClose?: () => void;
    shouldBlockBodyScroll?: boolean;
}

const FullWidthModal: React.FC<FullWidthModalProps> = ({
    isOpen,
    children,
    className,
    shouldBlockBodyScroll,
    onRequestClose,
}) => {
    const windowHeight = useWindowHeight();

    return (
        <Modal
            style={{ content: { '--window-height': `${windowHeight}px` } as React.CSSProperties }}
            isOpen={isOpen}
            className={cx(styles.modal, className)}
            overlayClassName={styles.overlay}
            contentLabel="Modal"
            onRequestClose={onRequestClose}
            appElement={document.getElementById('root') || undefined}
            bodyOpenClassName={shouldBlockBodyScroll ? 'no-scroll' : undefined}
        >
            {children}
        </Modal>
    );
};

export default FullWidthModal;
